import { getMonthsBetween, addDays } from '@ha/date';

import { MaximumStayValue } from '../../../types/SearchFilters';
import { FilterBuilder } from '../Types';

const maximumStayBuilder: FilterBuilder = (
  {
    maximumStay,
    startDate: startDateFilterValue,
    endDate: endDateFilterValue,
    flexDays: flexDaysFilterValue,
  },
  context,
) => {
  const enumToNumber: { [key in MaximumStayValue]: number } = {
    [MaximumStayValue.ZERO]: 0,
    [MaximumStayValue.W1]: 0.25,
    [MaximumStayValue.W2]: 0.5,
    [MaximumStayValue.W3]: 0.75,
    [MaximumStayValue.M1]: 1,
    [MaximumStayValue.M2]: 2,
    [MaximumStayValue.M3]: 3,
    [MaximumStayValue.M4]: 4,
    [MaximumStayValue.M5]: 5,
    [MaximumStayValue.M6]: 6,
  };

  const flexDays = flexDaysFilterValue || 0;
  const startDate =
    context?.withSrpDates && startDateFilterValue
      ? addDays(startDateFilterValue, flexDays)
      : startDateFilterValue;
  const endDate =
    context?.withSrpDates && endDateFilterValue
      ? addDays(endDateFilterValue, -flexDays)
      : endDateFilterValue;

  const maximumStayNumber = Math.max(
    (maximumStay && enumToNumber[maximumStay]) || 0,
    (startDate && endDate && getMonthsBetween(startDate, endDate) - 1) || 0,
  );

  if (maximumStayNumber <= 0) {
    return '';
  }

  return `maximumStay>=${maximumStayNumber} OR maximumStay=0`;
};

export { maximumStayBuilder };
