import { defineKey as T } from '@ha/intl';

const ACTION_PREFIX = 'LIST_YOUR_PLACE';

export const LISTINGS_PER_PAGE = 20;
export const FIRST_PAGE_NUM = 1;
export const STATE_NAME = 'listYourPlace';
export const Actions = {
  CREATE_LISTING_START: `${ACTION_PREFIX}/CREATE_LISTING_START`,
  CREATE_LISTING_DONE: `${ACTION_PREFIX}/CREATE_LISTING_DONE`,
  CREATE_LISTING_FAIL: `${ACTION_PREFIX}/CREATE_LISTING_FAIL`,

  ADD_LISTING_EXCLUSIONS_START: `${ACTION_PREFIX}/ADD_LISTING_EXCLUSIONS_START`,
  ADD_LISTING_EXCLUSIONS_DONE: `${ACTION_PREFIX}/ADD_LISTING_EXCLUSIONS_DONE`,
  ADD_LISTING_EXCLUSIONS_FAIL: `${ACTION_PREFIX}/ADD_LISTING_EXCLUSIONS_FAIL`,

  SELECT_LISTING: `${ACTION_PREFIX}/SELECT_LISTING`,
};

export enum AccountType {
  business = 'business',
  person = 'person',
}

export const MESSAGES = {
  DUPLICATE_LISTING_ERROR: T(`We could't duplicate your listing.`),
};
