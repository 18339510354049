import { Language } from '../../../lib/types';

import { AllSupportedLanguages } from './languages';

export const homePages = AllSupportedLanguages.reduce(
  (acc, lang) => ({
    ...acc,
    [lang]: '/home',
  }),
  {} as Record<Language, '/home'>,
);
