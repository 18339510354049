interface CityData {
  city?: {
    name?: string;
  };
  country?: {
    name: string;
  };
}

export function normalizeCityData(data: CityData) {
  return {
    ...data,
    city: data.city && {
      ...data.city,
      title: data.city.name,
    },
    country: data.country && {
      ...data.country,
      title: data.country.name,
    },
  };
}
