export const AUTH_PREFIX = '/oauth';

/**
 * URL to sign in to the application.
 */
export const AUTH_SIGNIN_URL = '/oauth/signin';

/**
 * URL to sign up to the application.
 */
export const AUTH_SIGNUP_URL = '/oauth/signup';

/**
 * URL to sign out of the application.
 */
export const AUTH_SIGNOUT_URL = '/oauth/signout';

/**
 * URL to refresh access token.
 */
export const AUTH_REFRESH_URL = '/oauth/refresh';

/**
 * URL Identity Platform callback
 */
export const AUTH_CALLBACK_URL = '/oauth/callback';

/**
 * URL to sign in with Google One Tap
 */
export const AUTH_SIGNIN_WITH_GOOGLE_ONE_TAP_URL = '/oauth/signin/onetap';
