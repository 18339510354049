import { SuitableForValue } from '../../../types/SearchFilters';
import { FilterBuilder } from '../Types';

const suitableForBuilder: FilterBuilder = params => {
  const { suitableFor } = params;

  if (!suitableFor) {
    return '';
  }

  const queries: string[] = [];

  const students = suitableFor.includes(SuitableForValue.STUDENTS);
  const workingProfessionals = suitableFor.includes(
    SuitableForValue.WORKING_PROFESSIONALS,
  );
  const couples = suitableFor.includes(SuitableForValue.COUPLES);

  if (students && !workingProfessionals) {
    queries.push("NOT facility_tenant_status:'working professionals only'");
  } else if (!students && workingProfessionals) {
    queries.push("NOT facility_tenant_status:'students only'");
  }

  if (couples) {
    queries.push('couplesAllowed:yes');
  }

  return queries.join(' AND ');
};

export { suitableForBuilder };
