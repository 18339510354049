import { MakeCall } from '@ha/api/v2/createMakeCall';

import {
  CreatePaymentRequestFormData,
  CreatePaymentRequestResponse,
} from './types';

const createPaymentPlanV3 =
  (makeCall: MakeCall) => (data: CreatePaymentRequestFormData) =>
    makeCall<CreatePaymentRequestResponse>(
      'POST',
      '/api/payments/requests/v3',
      {
        data,
      },
    );
export { createPaymentPlanV3 };
