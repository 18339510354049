import type { MakeCall } from '../createMakeCall';

export type PayoutMethodRoutingData = {
  listingIds: number[];
};

const getPayoutMethodRouting =
  (makeCall: MakeCall) => (userId: number, payoutMethodId: string) =>
    makeCall<PayoutMethodRoutingData>(
      'GET',
      `/api/v2/user/${userId}/payout-routing/link/${payoutMethodId}`,
    );

export { getPayoutMethodRouting };
