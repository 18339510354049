import { MakeCall } from './createMakeCall';

import { PaymentIntent } from './types/Subscriptions';

export interface Payload {
  currency: string;
  priceCodes: string[];
  billingCountry?: string;
  customerName?: string;
}

const createPaymentIntent = (makeCall: MakeCall) => (data: Payload) =>
  makeCall<PaymentIntent>(
    'POST',
    `/api/v2/billing/subscriptions/create-pay-intent`,
    {
      data,
    },
  );
export { createPaymentIntent };
