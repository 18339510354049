import { MakeCall } from './createMakeCall';

export interface Subscriptions {
  'search emails group': boolean;
  'all emails group': boolean;
  'booking group': boolean;
  'conversation group': boolean;
  'marketing group': boolean;
}

const updateUserSubscriptions =
  (makeCall: MakeCall) => (userId: number, subscriptions: Subscriptions) =>
    makeCall<Subscriptions>('PUT', `/api/v2/user/${userId}/subscriptions`, {
      data: subscriptions,
    });
export { updateUserSubscriptions };
