import { handleActions } from '@housinganywhere/safe-redux';

import { InboxUnreadCountState } from '../types';
import { ActionTypes } from '../constants';
import { Actions } from '../actions';

export const initialState: InboxUnreadCountState = {
  isLoaded: false,
  counters: {
    all: 0,
    allUnread: 0,
    archived: 0,
    archivedUnread: 0,
    expired: 0,
    expiredUnread: 0,
    favorited: 0,
    favoritedUnread: 0,
    inbox: 0,
    inboxUnread: 0,
    paid: 0,
    paidUnread: 0,
    pending: 0,
    pendingUnread: 0,
  },
};

export const reducer = handleActions<
  InboxUnreadCountState,
  ActionTypes,
  Actions
>(
  {
    [ActionTypes.START]: state => ({
      ...state,
    }),
    [ActionTypes.DONE]: (state, { payload: counters }) => ({
      ...state,
      counters: {
        ...state.counters,
        ...counters,
      },
      isLoaded: true,
    }),
    [ActionTypes.FAIL]: state => ({
      ...state,
      error: true,
    }),
  },
  initialState,
);
