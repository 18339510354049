import { AuthCookies } from '@ha/auth/common/cookie';
import { page } from '@hbf/analytics';

import { Thunk } from 'ha/myredux/types';

import { setRemarketingTag } from './setRemarketingTag';

interface Params {
  category: string;
  name: string;
  traits: object;
  remarketingTag?: object;
}

export const FIRST_TIME_USERS = { 'First Time': 'FALSE' };

// @todo(IDP) remove after observing success with new access token refresh
function getAuthMode() {
  try {
    let authMode = 'none';
    // @ts-ignore
    if (reduxStore.getState().authLogic.user) {
      authMode = document.cookie.includes(AuthCookies.AccessToken)
        ? 'idp'
        : 'legacy';
    }
    return { authMode };
  } catch {
    return {};
  }
}

export const onPageMount =
  ({ category, name, traits, remarketingTag }: Params): Thunk =>
  (dispatch, getState, { analytics }) =>
    analytics.onUserIdentified(() => {
      page(category, name, {
        ...traits,
        ...getAuthMode(),
      });

      if (remarketingTag) {
        setRemarketingTag(remarketingTag);
      }
    });
