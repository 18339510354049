import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

/**
 * Convert date in any timezone into UTC equivalent
 */
const convertDateToUTC = (date: Date | string) => {
  return new Date(dayjs.utc(date).format());
};

export { convertDateToUTC };
