import { Language } from '@ha/intl';
import { MakeCall } from './createMakeCall';
import { Notifications } from './types/Notifications';

const getNotifications =
  (makeCall: MakeCall) =>
  (languages: Language = Language.en) =>
    makeCall<Notifications>('GET', `/api/v2/notifications`, {
      query: { locale: languages },
    });
export { getNotifications };
