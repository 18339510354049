import { Language } from '@ha/intl';

import { CurrentLanguage } from '../types';

const initialState: CurrentLanguage = {
  code: Language.en,
  localisedName: '',
  localisedLocation: '',
};

const language = (state: CurrentLanguage = initialState) => state;

export const getCode = (s: CurrentLanguage) => s.code;
export const getLocalisedName = (s: CurrentLanguage) => s.localisedName;
export const getLocalisedLocation = (s: CurrentLanguage) => s.localisedLocation;
export { language };
