import { createSelector } from 'reselect';
import { GlobalState } from 'ha/types/store';
import { ScreenSize } from './constants';

export const getIsMobile = (state: GlobalState) => state.ui.isMobile;
export const getIsTablet = (state: GlobalState) => state.ui.isTablet;
export const getIsIos = (state: GlobalState) => state.ui.isIos;
export const getIosVersion = (state: GlobalState) => state.ui.iosVersion;
export const getMediaQueryBreakpoints = (state: GlobalState) =>
  state.ui.mediaQueryBreakpoints;

export const getCurrentScreenSize = createSelector(
  [getMediaQueryBreakpoints],
  breakpoints =>
    Object.entries(breakpoints).reduce(
      (acc, [bp, val]) => (val ? (bp as ScreenSize) : acc),
      ScreenSize.XS,
    ),
);
