import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isEmpty from 'lodash-es/isEmpty';

import { UserSearch } from '@ha/api/v2/types/UserSearch';

import {
  setInStorage,
  getFromStorage,
  removeFromStorage,
} from 'ha/utils/storage';

import { SAVED_SEARCH_EXPIRY } from './constants';

export const SAVED_SEARCH_LOCAL_STORE_KEY = 'ha_saved_search';

dayjs.extend(utc);

const isUserSearchExpired = (userSearch: Partial<UserSearch>) =>
  userSearch?.updatedAt &&
  dayjs().diff(dayjs(userSearch.updatedAt), 'days') > SAVED_SEARCH_EXPIRY;

const isUserSearchStartDateExpired = (userSearch: Partial<UserSearch>) =>
  userSearch.filters?.startDate &&
  dayjs().diff(dayjs(userSearch.filters.startDate), 'days') > 0;

const normalizeUserSearch = (userSearch: Partial<UserSearch>) => {
  const isExpired = isUserSearchExpired(userSearch);

  if (isExpired) return null;

  const isStartDateExpired = isUserSearchStartDateExpired(userSearch);

  if (isStartDateExpired) {
    return {
      ...userSearch,
      filters: {
        ...userSearch.filters,
        startDate: '',
        endDate: '',
      },
    } as Partial<UserSearch>;
  }

  return userSearch;
};

export const getLatestSearch = (
  userSearchA?: Partial<UserSearch>,
  userSearchB?: Partial<UserSearch>,
) => {
  if (!userSearchA && !userSearchB) return null;

  if (!userSearchA && userSearchB) return userSearchB;

  if (userSearchA && !userSearchB) return userSearchA;

  const isUserSearchALatest = dayjs(userSearchA?.updatedAt).isAfter(
    dayjs(userSearchB?.updatedAt),
  );

  return isUserSearchALatest ? userSearchA : userSearchB;
};

export const savedSearchLocalStore = {
  set: async (userSearch: Partial<UserSearch>) => {
    const latestSearch = getLatestSearch(
      getFromStorage<Partial<UserSearch>>(SAVED_SEARCH_LOCAL_STORE_KEY),
      userSearch,
    );
    if (latestSearch) {
      const normalizedUserSearch = normalizeUserSearch(latestSearch);
      if (normalizedUserSearch) {
        setInStorage(SAVED_SEARCH_LOCAL_STORE_KEY, latestSearch);
      }
    }
    return Promise.resolve();
  },
  get: () => {
    const userSearch = getFromStorage<Partial<UserSearch>>(
      SAVED_SEARCH_LOCAL_STORE_KEY,
    );

    if (isEmpty(userSearch)) return null;

    return normalizeUserSearch(userSearch);
  },
  remove: () => {
    removeFromStorage(SAVED_SEARCH_LOCAL_STORE_KEY);
  },
};
