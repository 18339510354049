import { FormatDateFunc } from '@ha/intl';

import { DATE_RANGE_FORMAT } from './constants';

/**
 * @deprecated use `formatDateTimeRange` from `ha-intl`
 */
export function formatDateRange(
  formatDate: FormatDateFunc,
  startDate?: string,
  endDate?: string,
): string {
  return [startDate, endDate]
    .filter(Boolean)
    .map(date => formatDate(DATE_RANGE_FORMAT, date))
    .join(' - ');
}
