import { MakeCall } from './createMakeCall';

const uploadAvatar =
  (makeCall: MakeCall) =>
  ({ userId, avatar }: { userId: 'me' | number; avatar: File }) =>
    makeCall<{ avatarURL: string }>('PUT', `/api/v2/user/${userId}/avatar`, {
      data: { avatar },
      headers: {
        'Content-Type': 'multipart/form-data; boundary=%s',
      },
    });
export { uploadAvatar };
