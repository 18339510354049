import dayjs from 'dayjs';
import { getFromStorageNew, setInStorage } from 'ha/utils/storage';
import { SESSION_STORAGE_KEY, SESSION_TIMEOUT } from './constants';

export const session = {
  isEnded: (): boolean => {
    const endSession = getFromStorageNew<string>(SESSION_STORAGE_KEY);

    if (!endSession) {
      return true;
    }

    return dayjs().isAfter(dayjs(endSession));
  },

  updateTime: () =>
    setInStorage(SESSION_STORAGE_KEY, dayjs().add(SESSION_TIMEOUT, 'minute')),
};
