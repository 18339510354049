import { MakeCall } from '../createMakeCall';

import { CreateUnitTypePayload, UnitType } from '../types/inventory/UnitType';

const createUnitType =
  (makeCall: MakeCall) => (payload: CreateUnitTypePayload) =>
    makeCall<UnitType>('POST', `/api/v2/unit-type`, {
      data: payload,
    });
export { createUnitType };
