import { MakeCall } from './createMakeCall';
import { UserSearchList } from './types/UserSearchList';

export interface Query {
  limit?: number;
  offset?: number;
  city?: string;
  countryCode?: string;
  lang?: string;
}

export type GetSavedSearchesCall = (
  userId: number,
  query?: Query,
) => Promise<{ data: UserSearchList }>;

const getSavedSearches =
  (makeCall: MakeCall) => (userId: number | 'me', query?: Query) =>
    makeCall<UserSearchList>('GET', `/api/v2/user/${userId}/searches`, {
      query,
    });
export { getSavedSearches };
