import { LoaderFunction, redirect } from 'react-router-dom';

import { Store } from 'redux';

import { HttpStatus } from '@ha/api/v2/handleApiV2Response';

import { getIsAuthenticated } from 'ha/modules/AuthLogic/selectors';

export const authenticated =
  (store: Store): LoaderFunction =>
  () => {
    if (getIsAuthenticated(store.getState())) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw redirect('/my', HttpStatus.FOUND_REDIRECTION);
    }

    return null;
  };
