import { MakeCall } from '../createMakeCall';

import { UnitTypePhoto } from '../types/inventory/UnitType';

export interface UpdateUnitTypePhotoOptions {
  order: number[];
  rotate?: number;
}

const updateUnitTypePhotosOrder =
  (makeCall: MakeCall) =>
  (unitTypeId: number, { order, rotate }: UpdateUnitTypePhotoOptions) =>
    makeCall<UnitTypePhoto[]>('PUT', `/api/v2/unit-type/${unitTypeId}/photos`, {
      data: {
        order,
        ...(rotate ? { rotate } : {}),
      },
    });
export { updateUnitTypePhotosOrder };
