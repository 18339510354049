import type { MakeCall } from '../createMakeCall';
import { BillingInformation } from '../types/BillingInformation';

export interface APIUserMultiBillingData {
  billingInfos: BillingInformation[];
  count: number;
}

type Query = {
  offset?: number;
  limit?: number;
};

const getMultiBillingInformation = (makeCall: MakeCall) => (query?: Query) =>
  makeCall<APIUserMultiBillingData>('GET', `/api/v2/billing/billing-info`, {
    query,
  }).then(res => ({
    ...res,
    data: {
      billingInformation: res.data?.billingInfos ?? [],
      count: res.data.count,
    },
  }));

export { getMultiBillingInformation };
