import { MakeCall } from './createMakeCall';
import {
  AcceptOrDeclineChangeRequestPayload,
  AcceptOrDeclineChangeRequestResponse,
} from './types/ChangeRequest';

// respond with accept or decline for a booking modification request by landlord
export const acceptOrDeclineBookingModificationRequest =
  (makeCall: MakeCall) =>
  (
    conversationId: number,
    changeRequestID: number,
    payload?: AcceptOrDeclineChangeRequestPayload,
  ) =>
    makeCall<AcceptOrDeclineChangeRequestResponse>(
      'POST',
      `/api/v2/conversation/${conversationId}/change/${changeRequestID}`,
      { data: payload },
    );
