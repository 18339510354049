import { defineKey as T } from '@ha/intl';

export const ACTION_PREFIX = 'EDIT_LISTING';
export const STATE_NAME = 'editListing';

export const YOUTUBE_INPUT_NAME = 'youtubeUrl';

export const Actions = {
  INITIALIZE: `${ACTION_PREFIX}/INITIALIZE`,
  LOAD_LISTING_START: `${ACTION_PREFIX}/LOAD_LISTING_START`,
  LOAD_LISTING_DONE: `${ACTION_PREFIX}/LOAD_LISTING_DONE`,
  LOAD_LISTING_FAIL: `${ACTION_PREFIX}/LOAD_LISTING_FAIL`,
  LOAD_LISTING_RESET_STATE: `${ACTION_PREFIX}/LOAD_LISTING_RESET_STATE`,
};

export const Messages = {
  LISTING_DUPLICATION_ERROR: T(`We could't duplicate your listing.`),
  LISTING_UPDATE_ERROR: T(
    'We could not update your listing. Please check the form and try again.',
  ),
  LISTING_UPDATE_SUCCESS: T('ListingEdit.save.success'),
};

export const VideoMessages = {
  errorTitle: T('Invalid URL, please provide a valid Youtube URL.'),
  loadingTitle: T('Video is loading!'),
};
