import { createRequiredContext } from 'ha/core/RequiredContext';
import { CookieConsentClient } from '@hbf/cookie-consent';

export interface CookieConsentContextType {
  client?: CookieConsentClient;
}

const CookieConsentContext = createRequiredContext<CookieConsentContextType>(
  'CookieConsentContext',
);

export const CookieConsentProvider = CookieConsentContext.Provider;
export const useCookieConsentContext = CookieConsentContext.useContext;
