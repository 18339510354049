import { MakeCall } from './createMakeCall';
import { ListingVideo } from './types/Listing';

export type AddYoutubeVideoCall = (
  listingId: number,
  youtubeId: string,
) => Promise<{ data: ListingVideo[] }>;

const addYoutubeVideo =
  (makeCall: MakeCall) => (listingId: number, youtubeUrl: string) =>
    makeCall<ListingVideo[]>(
      'POST',
      `/api/v2/listing/${listingId}/youtube-video`,
      {
        data: { youtubeUrl },
      },
    );
export { addYoutubeVideo };
