import React from 'react';

export interface VariantProps {
  name: string;
  children?: React.ReactNode;
}

export const Variant: React.FC<VariantProps> = ({ children }) => (
  // TODO: remove once upgraded to typescript 5.1
  // https://github.com/DefinitelyTyped/DefinitelyTyped/pull/65135
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <React.Fragment>{children}</React.Fragment>
);
