import { Language } from '@ha/intl';
import { TypeValue } from 'ha/types/SearchFilters';

export type CanonicalCollectionTypes =
  | Exclude<TypeValue, TypeValue.STUDENT_HOUSING>
  | 'cheap-accommodation'
  | 'furnished-apartments'
  | 'student-accommodation'
  | 'long-term-rentals'
  | 'one-bedroom-apartments'
  | 'two-bedroom-apartments'
  | 'student-room';

// Collection and route constants
export const CanonicalCollections = {
  PrivateRoomForRent: TypeValue.PRIVATE_ROOM,
  SharedRoomForRent: TypeValue.SHARED_ROOM,
  ApartmentForRent: TypeValue.APARTMENT,
  StudioForRent: TypeValue.STUDIO,
  CheapAccommodation: 'cheap-accommodation',
  FurnishedApartments: 'furnished-apartments',
  StudentAccommodation: 'student-accommodation',
  LongTermRentals: 'long-term-rentals',
  OneBedroomApartments: 'one-bedroom-apartments',
  TwoBedroomApartments: 'two-bedroom-apartments',
  StudentRoom: 'student-room',
} as const;

type TranslationMap = Omit<
  { [key in CanonicalCollectionTypes]: string },
  'student-housing'
>;

// Used internally for english route map
const CanonicalCollectionMap = Object.values(CanonicalCollections).reduce(
  (acc, key) => ({ ...acc, [key]: key }),
  {},
) as TranslationMap;

export const LocaleToCanonicalCollections: {
  [key in Language]: TranslationMap;
} = {
  [Language.en]: CanonicalCollectionMap,
  [Language.zh]: CanonicalCollectionMap,
  [Language.uk]: CanonicalCollectionMap,
  [Language.ru]: CanonicalCollectionMap,
  [Language.pl]: CanonicalCollectionMap,
  [Language.sv]: CanonicalCollectionMap,
  [Language.ro]: CanonicalCollectionMap,
  [Language.de]: {
    [CanonicalCollections.ApartmentForRent]: 'wohnung-mieten',
    [CanonicalCollections.PrivateRoomForRent]: 'wg-zimmer',
    [CanonicalCollections.SharedRoomForRent]: 'mehrbettzimmer',
    [CanonicalCollections.StudioForRent]: 'studios',
    [CanonicalCollections.CheapAccommodation]: 'g%C3%BCnstige-unterkunft',
    [CanonicalCollections.FurnishedApartments]: 'moeblierte-apartments',
    [CanonicalCollections.LongTermRentals]: 'langzeitmiete',
    [CanonicalCollections.StudentAccommodation]: 'studentenunterkunft',
    [CanonicalCollections.OneBedroomApartments]: '1-zimmer-wohnungen',
    [CanonicalCollections.TwoBedroomApartments]: '2-zimmer-wohnungen',
    [CanonicalCollections.StudentRoom]: 'studentenzimmer',
  },
  [Language.fr]: {
    [CanonicalCollections.ApartmentForRent]: 'appartement-a-louer',
    [CanonicalCollections.PrivateRoomForRent]: 'chambres-priv%C3%A9es',
    [CanonicalCollections.SharedRoomForRent]: 'chambres-partag%C3%A9es',
    [CanonicalCollections.StudioForRent]: 'studios',
    [CanonicalCollections.CheapAccommodation]: 'logement-pas-cher',
    [CanonicalCollections.FurnishedApartments]: 'appartements-meubl%C3%A9s',
    [CanonicalCollections.LongTermRentals]: 'location-longue-dur%C3%A9e',
    [CanonicalCollections.StudentAccommodation]: 'logement-%C3%A9tudiant',
    [CanonicalCollections.OneBedroomApartments]: 'appartements-2-pi%C3%A8ces',
    [CanonicalCollections.TwoBedroomApartments]: 'appartements-3-pi%C3%A8ces',
    [CanonicalCollections.StudentRoom]: 'chambres-%C3%A9tudiantes',
  },
  [Language.es]: {
    [CanonicalCollections.ApartmentForRent]: 'apartamento-para-alquilar',
    [CanonicalCollections.PrivateRoomForRent]: 'habitaciones-privadas',
    [CanonicalCollections.SharedRoomForRent]: 'habitaciones-compartidas',
    [CanonicalCollections.StudioForRent]: 'estudios',
    [CanonicalCollections.CheapAccommodation]: 'alojamientos-baratos',
    [CanonicalCollections.FurnishedApartments]: 'apartamentos-amueblados',
    [CanonicalCollections.LongTermRentals]: 'alquiler-largo-plazo',
    [CanonicalCollections.StudentAccommodation]: 'alojamiento-para-estudiantes',
    [CanonicalCollections.OneBedroomApartments]:
      'apartamentos-1-habitaci%C3%B3n',
    [CanonicalCollections.TwoBedroomApartments]: 'apartamentos-2-habitaciones',
    [CanonicalCollections.StudentRoom]: 'habitaciones-estudiantiles',
  },
  [Language.it]: {
    [CanonicalCollections.ApartmentForRent]: 'appartamento-in-affitto',
    [CanonicalCollections.PrivateRoomForRent]: 'stanze-private',
    [CanonicalCollections.SharedRoomForRent]: 'camere-condivise',
    [CanonicalCollections.StudioForRent]: 'monolocali',
    [CanonicalCollections.CheapAccommodation]: 'alloggi-economici',
    [CanonicalCollections.FurnishedApartments]: 'appartamenti-ammobiliati',
    [CanonicalCollections.LongTermRentals]: 'affitti-lungo-termine',
    [CanonicalCollections.StudentAccommodation]: 'affitti-per-studenti',
    [CanonicalCollections.OneBedroomApartments]: 'bilocali',
    [CanonicalCollections.TwoBedroomApartments]: 'trilocali',
    [CanonicalCollections.StudentRoom]: 'stanze-per-studenti',
  },
  [Language.nl]: {
    [CanonicalCollections.ApartmentForRent]: 'appartement-te-huur',
    [CanonicalCollections.PrivateRoomForRent]: 'priv%C3%A9-kamers',
    [CanonicalCollections.SharedRoomForRent]: 'gedeelde-kamers',
    [CanonicalCollections.StudioForRent]: 'studios',
    [CanonicalCollections.CheapAccommodation]: 'goedkope-huurwoningen',
    [CanonicalCollections.FurnishedApartments]: 'gemeubileerde-appartementen',
    [CanonicalCollections.LongTermRentals]: 'lange-termijn-verhuur',
    [CanonicalCollections.StudentAccommodation]: 'studentenwoningen',
    [CanonicalCollections.OneBedroomApartments]: '1-slaapkamer-appartementen',
    [CanonicalCollections.TwoBedroomApartments]: '2-slaapkamer-appartementen',
    [CanonicalCollections.StudentRoom]: 'studentenkamers',
  },
  [Language.pt]: {
    [CanonicalCollections.ApartmentForRent]: 'apartamento-para-alugar',
    [CanonicalCollections.PrivateRoomForRent]: 'quartos-privados',
    [CanonicalCollections.SharedRoomForRent]: 'quartos-compartilhados',
    [CanonicalCollections.StudioForRent]: 'est%C3%BAdios',
    [CanonicalCollections.CheapAccommodation]: 'alojamento-barato',
    [CanonicalCollections.FurnishedApartments]: 'apartamentos-mobiliados',
    [CanonicalCollections.LongTermRentals]: 'aluguel-de-longo-prazo',
    [CanonicalCollections.StudentAccommodation]: 'casas-para-estudantes',
    [CanonicalCollections.OneBedroomApartments]: 'apartamentos-1-quarto',
    [CanonicalCollections.TwoBedroomApartments]: 'apartamentos-2-quartos',
    [CanonicalCollections.StudentRoom]: 'quartos-para-estudantes',
  },
};

export const TranslatedCollectionToCanonical = Object.values(
  LocaleToCanonicalCollections,
)
  .flatMap(Object.entries)
  .reduce((acc, [key, val]) => ({ ...acc, [val]: key }), {});
