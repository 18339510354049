interface Options {
  onError: (error: Error) => void;
}

export function registerSW(url: string, { onError }: Options) {
  global.navigator.serviceWorker
    .register(url)
    .then(registration => {
      // eslint-disable-next-line no-param-reassign
      registration.onupdatefound = function onUpdateFound() {
        const installingWorker = registration.installing;

        if (!installingWorker) {
          return;
        }

        installingWorker.onstatechange = function onStateChange() {
          if (installingWorker.state === 'installed') {
            if (global.navigator.serviceWorker.controller) {
              // eslint-disable-next-line no-console
              console.info('New or updated content is available.');
            } else {
              // eslint-disable-next-line no-console
              console.info('Content is now available offline!');
            }
          } else if (installingWorker.state === 'redundant') {
            // eslint-disable-next-line no-console
            console.error('The installing service worker became redundant.');
          }
        };
      };
    })
    .catch(onError);
}
