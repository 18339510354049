import { MakeCall } from './createMakeCall';
import { Listing } from './types/Listing';

export interface Query {
  /**
   * @description comma-separated string with possible values: `exclusions`, `exclusionsExtended`, `bookablePeriods`, `conversationCounters`, `lastMonthConversationCount`, `photos`, `videos`, `costs`
   */
  expand?: string;
  lang?: string;
  moveInDate?: string;
  moveOutDate?: string;
  bookinglink?: boolean;
}

const getListingById =
  (makeCall: MakeCall) => (listingId: number, query?: Query) =>
    makeCall<Listing>('GET', `/api/v2/listing/${listingId}`, {
      query,
    });

export { getListingById };
