import type { MakeCall } from '../createMakeCall';

import {
  GetPayoutTransactionsQuery,
  GetPayoutTransactionsResponse,
} from './getPayout';

const getPayoutTransactions =
  (makeCall: MakeCall) =>
  (
    userId: number,
    query: GetPayoutTransactionsQuery,
  ): Promise<{ data: GetPayoutTransactionsResponse; status: number }> =>
    makeCall<GetPayoutTransactionsResponse>(
      'GET',
      `/api/v2/user/${userId}/payout-transactions`,
      {
        query,
      },
    );

export { getPayoutTransactions };

export type GetPayoutTransactions = ReturnType<typeof getPayoutTransactions>;
