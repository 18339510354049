import { useSelector } from 'react-redux';

import { getRouteData, getRoute, getLinkData, getInfo } from './selectors';

/**
 * For the pages that won't be rendered without all the data
 * Generally pages from a dynamic routing, such as Blog pages, etc.
 */
export const useRequiredHermes = <DataShape extends {}>() => {
  const data = useSelector(getRouteData) as DataShape | undefined;
  const route = useSelector(getRoute);
  const info = useSelector(getInfo);
  const linkData = useSelector(getLinkData);

  if (!route || !data || !info || !linkData) {
    throw new Error('hermes route or data are missing');
  }

  return { data, route, info, linkData };
};

/**
 * For the pages where hermes data is optional, and they can be rendered without it
 * For example, Home and Cities pages
 */
export const useOptionalHermes = <DataShape extends {}>() => {
  const data = useSelector(getRouteData) as DataShape | undefined;
  const route = useSelector(getRoute);
  const info = useSelector(getInfo);
  const linkData = useSelector(getLinkData);

  return { data, route, info, linkData };
};
