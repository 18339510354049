import { json } from 'react-router-dom';

import { PageLoaderArgs } from 'ha/types/routing';

// load the chunk and preload the data needed for NotFound page
// before throwing an error
export const throwNotFoundError = async (args: PageLoaderArgs) => {
  const notFoundModule = await import('ha/pages/NotFound/loader');
  await notFoundModule.loader(args);

  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  throw json({}, { status: 404 });
};
