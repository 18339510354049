import type { MakeCall } from '../createMakeCall';
import {
  LinkListingToBilling,
  PriorityRules,
} from '../types/BillingInformation';

const linkListingToDefaultBillingInformation =
  (makeCall: MakeCall) => (data: LinkListingToBilling) =>
    makeCall<PriorityRules>(
      'DELETE',
      `/api/v2/billing/billing-info/${data.billingUUID}/priority`,
      {
        data: { rules: [data.rules] },
      },
    );

export { linkListingToDefaultBillingInformation };
