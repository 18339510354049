import { composeWithDevTools } from '@redux-devtools/extension';
import { createStore, applyMiddleware } from 'redux';

import { createAppMiddlewares } from './createAppMiddlewares';
import { createAppReducer } from './createAppReducer';

/**
 * @returns {import('ha/myredux/types').MyReduxStore}
 */
export function createAppStore({ config, initialState, services }) {
  const middlewares = createAppMiddlewares({
    debug: config.debug,
    services,
  });
  const enhancer = applyMiddleware(...middlewares);
  const store = createStore(
    createAppReducer(),
    initialState,
    process.browser ? composeWithDevTools(enhancer) : enhancer,
  );

  store.asyncReducers = {};
  store.injectAsyncReducer = (name, asyncReducer) => {
    if (store.asyncReducers[name]) {
      return;
    }

    store.asyncReducers[name] = asyncReducer; // eslint-disable-line no-param-reassign
    store.replaceReducer(createAppReducer(store.asyncReducers));
  };

  return store;
}
