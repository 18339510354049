import { MakeCall } from './createMakeCall';

import { UserDocument } from './types/UserDocument';

const confirmUserDocumentUpload =
  (makeCall: MakeCall) =>
  (
    userId: 'me' | number,
    docUUID: string,
    data: {
      title?: string;
      filename: string;
    },
  ) =>
    makeCall<UserDocument>(
      'PUT',
      `/api/v2/user/${userId}/document/${docUUID}/confirm`,
      {
        data,
      },
    );
export { confirmUserDocumentUpload };
