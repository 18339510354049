import { MakeCall } from './createMakeCall';

export interface Payload {
  unitTypeId: number;
  targetLang: string;
  translator?: 'google' | 'deepl';
}

export interface UnitTypeTranslationResponse {
  description: string;
}

const translateUnitTypeDescription =
  (makeCall: MakeCall) =>
  ({ unitTypeId, targetLang, translator }: Payload) =>
    makeCall<UnitTypeTranslationResponse>(
      'GET',
      `/api/v2/unit-type/${unitTypeId}/translate/description/${targetLang}`,
      {
        query: {
          translator,
        },
      },
    );
export { translateUnitTypeDescription };
