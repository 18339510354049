import { MakeCall } from './createMakeCall';

import { SubscriptionProduct } from './types/Subscriptions';

export type SubscriptionUserType = 'advertiser' | 'tenant' | 'payment-request';

const getSubscriptionProductsByHandle =
  (makeCall: MakeCall) => (productType: SubscriptionUserType) =>
    makeCall<SubscriptionProduct>(
      'GET',
      `/api/v2/billing/subscriptions/products/${productType}`,
    );

export { getSubscriptionProductsByHandle };
