import type { MakeCall } from '../createMakeCall';
import { Currency } from '../types/Currencies';

import { PayInMethod, PaymentMethod, PaymentMethodDetail } from './types';

type BasePayload = {
  methodDetail?: PaymentMethodDetail;
  currency: Currency;
  token1?: string;
  pspName?: string;
};

export type PayInMethodData = {
  methodType: PayInMethod;
  currency: Currency;
};

type SingleMethod = { methodType: PayInMethod };
type MultiMethods = { payinMethodTypes: PayInMethodData[] };
export type Payload = BasePayload & (SingleMethod | MultiMethods);

export type Response = PaymentMethod;

const createPaymentMethod = (makeCall: MakeCall) => (data: Payload) =>
  makeCall<Response>('POST', `/api/v2/payment/method/pay-in`, { data });
export { createPaymentMethod };
