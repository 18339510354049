import { handleActions } from '@housinganywhere/safe-redux';
import { FetchStatus } from '@ha/api/v2/errors/FetchStatus';

import {
  ActionsUnionType as FetchSupportedCountriesActions,
  ActionTypes as FetchSupportedCountriesActionTypes,
} from '../actions/fetchSupportedCountries';
import {
  ActionsUnionType as FetchSupportedCurrenciesActions,
  ActionTypes as FetchSupportedCurrenciesActionTypes,
} from '../actions/fetchSupportedCurrencies';

import {
  ActionsUnionType as FetchSupportedResidenceCountriesActions,
  ActionTypes as FetchSupportedResidenceCountriesActionTypes,
} from '../actions/fetchSupportedResidenceCountries';

export interface AppSettingsState {
  supportedCountriesFetchStatus: FetchStatus;
  supportedCurrenciesFetchStatus: FetchStatus;
  supportedCountries: string[];
  supportedCurrencies: string[];
  supportedResidenceCountries: string[];
  supportedResidenceCountriesFetchStatus: FetchStatus;
}

export const initialState: AppSettingsState = {
  supportedCountriesFetchStatus: 'loadingFirstTime',
  supportedCurrenciesFetchStatus: 'loadingFirstTime',
  supportedResidenceCountriesFetchStatus: 'loadingFirstTime',
  supportedCountries: [],
  supportedCurrencies: [],
  supportedResidenceCountries: [],
};

type ActionTypes =
  | FetchSupportedCountriesActionTypes
  | FetchSupportedCurrenciesActionTypes
  | FetchSupportedResidenceCountriesActionTypes;
type Actions =
  | FetchSupportedCountriesActions
  | FetchSupportedCurrenciesActions
  | FetchSupportedResidenceCountriesActions;

export const reducer = handleActions<AppSettingsState, ActionTypes, Actions>(
  {
    [FetchSupportedCountriesActionTypes.START]: state => ({
      ...state,
      supportedCountriesFetchStatus: 'loading',
    }),
    [FetchSupportedCountriesActionTypes.DONE]: (
      state,
      { payload: countries },
    ) => ({
      ...state,
      supportedCountriesFetchStatus: 'success',
      supportedCountries: countries,
    }),
    [FetchSupportedCountriesActionTypes.FAIL]: state => ({
      ...state,
      supportedCountriesFetchStatus: 'failure',
    }),

    [FetchSupportedCurrenciesActionTypes.START]: state => ({
      ...state,
      supportedCurrenciesFetchStatus: 'loading',
    }),
    [FetchSupportedCurrenciesActionTypes.DONE]: (
      state,
      { payload: currencies },
    ) => ({
      ...state,
      supportedCurrenciesFetchStatus: 'success',
      supportedCurrencies: currencies,
    }),
    [FetchSupportedCurrenciesActionTypes.FAIL]: state => ({
      ...state,
      supportedCurrenciesFetchStatus: 'failure',
    }),

    [FetchSupportedResidenceCountriesActionTypes.START]: state => ({
      ...state,
      supportedResidenceCountriesFetchStatus: 'loading',
    }),
    [FetchSupportedResidenceCountriesActionTypes.DONE]: (
      state,
      { payload: countries },
    ) => ({
      ...state,
      supportedResidenceCountriesFetchStatus: 'success',
      supportedResidenceCountries: countries,
    }),
    [FetchSupportedResidenceCountriesActionTypes.FAIL]: state => ({
      ...state,
      supportedResidenceCountriesFetchStatus: 'failure',
    }),
  },
  initialState,
);
