import isNil from 'lodash-es/isNil';

enum RoomsValue {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR_AND_MORE = '4+',
}

interface RoomsFilterValue {
  bedroomCount: RoomsValue[];
}

const buildParams = (params: Record<string, unknown>): string => {
  const { rooms, ...otherParams } = params || {};

  const roomsFilterValue = rooms as RoomsFilterValue;
  if (roomsFilterValue?.bedroomCount?.length) {
    otherParams.bedroomCount = roomsFilterValue.bedroomCount;
  }

  const query = Object.entries(otherParams)
    .filter(pair => !isNil(pair[1]))
    .map(pair => pair.map(v => encodeURIComponent(String(v))).join('='))
    .join('&');

  return query ? `?${query}` : query;
};

export { buildParams };
