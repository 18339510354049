import React from 'react';
import { redirect, json } from 'react-router-dom';
import { useStore } from 'react-redux';

import { InjectAsyncReducer, PageLoaderFunction } from 'ha/types/routing';
import {
  loadHermesData,
  getInfo,
  getError,
  useOptionalHermes,
} from 'ha/modules/Hermes';
import { MyReduxStore } from 'ha/myredux/types';
import { removeTrailingSlash } from 'ha/utils/urls/removeTrailingSlash';
import { getCityCountry } from 'ha/utils/urls/getCityCountry';

import { LazyPageComponent } from '../LazyPageComponent';
import { throwNotFoundError } from '../throwNotFoundError';

export const contentfulAndCitiesPages: PageLoaderFunction = async args => {
  const {
    context: { store, intl },
    request,
  } = args;
  const url = new URL(request.url);

  const cityCountry = getCityCountry({ url: request.url, lang: intl.lang });

  if (cityCountry) {
    const citiesModule = await import('ha/pages/Cities');
    citiesModule.injectAsyncReducer(store);
    const result = await citiesModule.loader(args);
    return result;
  }

  // use decodeURI instead of decodeURIComponent
  // to prevent slashes written as "%2F" being considered as a separator
  let decodedPathname = decodeURI(url.pathname);

  if (
    // All languages except en should be redirected to closing slash
    ['/de', '/nl', '/es', '/it', '/fr', '/pt', '/zh'].includes(decodedPathname)
  ) {
    return redirect(`${decodedPathname}/${url.search}`);
  }

  if (decodedPathname.length > 1 && decodedPathname.endsWith('/')) {
    if (decodedPathname.includes('//')) {
      decodedPathname = '';
    }

    return redirect(`${removeTrailingSlash(decodedPathname)}${url.search}`);
  }

  await store.dispatch(loadHermesData(url.pathname));

  const info = getInfo(store.getState());

  if (info) {
    let newPathname = decodedPathname;

    if (
      info.path.toLowerCase() === newPathname.toLowerCase() &&
      info.path !== newPathname
    ) {
      newPathname = info.path;
    }

    if (newPathname !== decodedPathname) {
      return redirect(`${newPathname}${url.search}`);
    }

    if (info.redirect) {
      return redirect(`${info.redirect}${url.search}`);
    }
  }

  const state = store.getState();

  if (getError(state)) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw json({ isHermes: true }, 500);
  }

  if (!info) {
    return throwNotFoundError(args);
  }

  if (!info.hasData) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw json({ isHermes: true }, 404);
  }

  const module = await import(`ha/pages/${info.type}/index.ts`);

  if (module.injectAsyncReducer) {
    (module.injectAsyncReducer as InjectAsyncReducer)(store);
  }

  if (module.loader) {
    await (module.loader as PageLoaderFunction)(args);
  }

  // return null instead of undefined in order to execute loader only once when ssr is enabled
  return null;
};

export const ContentfulAndCitiesPagesComponent: React.FC = () => {
  const { route } = useOptionalHermes();
  const store = useStore() as MyReduxStore;

  return (
    <LazyPageComponent pageName={route?.pageName ?? 'Cities'} store={store} />
  );
};
