import { MakeCall } from '../createMakeCall';

export interface UnitTypePhotoUploadRecord {
  url: string;
  contentType: string;
  expires: string;
  photoId: number;
  photoUuid: string;
}

const requestUnitTypePhotoUpload =
  (makeCall: MakeCall) => (unitTypeId: number) =>
    makeCall<UnitTypePhotoUploadRecord>(
      'POST',
      `/api/v2/unit-type/${unitTypeId}/photos/request`,
    );

export { requestUnitTypePhotoUpload };
