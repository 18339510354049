import { MakeCall } from './createMakeCall';

export type ConfirmPayoutMethodCall = (
  userID: number,
  token: string,
) => Promise<{ data: unknown }>;

const confirmPayoutMethod =
  (makeCall: MakeCall) => (userID: number, token: string) =>
    makeCall<undefined>(
      'PUT',
      `/api/v2/user/${userID}/payout-methods/confirm`,
      {
        data: { token },
      },
    );
export { confirmPayoutMethod };
