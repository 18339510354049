import { MakeCall } from './createMakeCall';

import { KycDocumentsResponse } from './types/Kyc';

const linkKycDocument =
  (makeCall: MakeCall) =>
  (
    userId: 'me' | number,
    kycUuid: string,
    data: {
      documentUuid: string;
      documentType: string;
    },
  ) =>
    makeCall<KycDocumentsResponse>(
      'POST',
      `/api/v2/user/${userId}/kyc/${kycUuid}/documents`,
      {
        data,
      },
    );
export { linkKycDocument };
