/**
 * Borrowed from https://github.com/agraboso/redux-api-middleware/blob/master/src/errors.js
 */

/**
 * Error class that works as a base for errors that contain stack traces.
 * It allows us to wrap an error, and keep the original error in the
 * cause property for debug,
 *
 * @class BaseError
 * @access public
 */
export class BaseError extends Error {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - overwrites cause from standard library https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/cause
  /** the original error */
  override cause: Error;

  /** the error response */
  response: any;

  constructor(message: string, cause: Error, response: any) {
    super();
    this.message = message;
    this.cause = cause;
    this.response = response;
  }
}
