import type { MakeCall } from '../createMakeCall';
import { QualificationRequirement } from '../types/qualifications/QualificationRequirement';
import { RequiredDocuments } from '../types/RequiredDocuments';

interface ListingUuid {
  listingUuid: string;
  unitTypeUuid?: never;
}

interface UnitTypeUuid {
  listingUuid?: never;
  unitTypeUuid: string;
}

export interface Query {
  isUnitType: boolean;
}

const addQualificationRequirements =
  (makeCall: MakeCall) =>
  (
    uuid: ListingUuid | UnitTypeUuid,
    data: Array<RequiredDocuments>,
    query?: Query,
  ) =>
    makeCall<QualificationRequirement>(
      'POST',
      `/api/v2/qualification/requirement/`,
      {
        data: {
          documents: data,
          ...uuid,
        },
        query,
      },
    );
export { addQualificationRequirements };
