export const enum HttpHeaders {
  /** Sent to other services (bell) to distinguish unauthenticated requests in analytics */
  AnonymousId = 'X-HA-AnonID',
  /** Can be sent to platform-api to use as a language for the request */
  Language = 'X-HA-Lang',
  /** Only for request from platform-api. Used to determine locale of a requested page */
  Locale = 'X-HA-Locale',
  /** Only for request from platform-api. Used to set timeout for fetch clients */
  Timeout = 'X-HA-Req-Timeout',
  /** Used to disable SSR for a request */
  NoSSR = 'X-HA-NoSSR',
  /** Used by platform-api to enforce consistency on requests */
  ConsistencyToken = 'X-Ha-Cnst',
  /** Used by platform-api to define its own feature flags (not flagsmith) */
  Features = 'X-HA-Features',
  /** Returned in user information, looks up the country by ip */
  Country = 'X-Ha-Country',
  /** Used by different services for tracing */
  RequestId = 'X-Request-ID',
  /** Used by flagsmith to measure the load per application */
  ApplicationName = 'X-Application-Name',
  /** Used by flagsmith to measure the load per environment */
  Environment = 'X-Environment',
}
