import { MakeCall } from './createMakeCall';

export type UniversitiesResponse = Array<{
  code: string;
  name: string;
  universities: Array<{
    id: number;
    name: string;
    slug: string;
  }>;
}>;

const getUniversities = (makeCall: MakeCall) => () =>
  makeCall<UniversitiesResponse>('GET', '/sitemaps/universities.json');
export { getUniversities };
