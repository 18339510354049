import { MakeCall } from './createMakeCall';
import { Conversation } from './types/Conversation';

export interface CreateConversationPayload {
  // UnitType id or listing id must be included on the conversation creation
  listingId?: number;
  unitTypeId?: number;
  startDate: string;
  endDate: string;
  messageText: string;
  instantBooking?: boolean;
  bookingLink?: boolean;
  strictRentalPeriodId?: number;
}

export interface ConversationFeatureFlags {
  rentalConditions?: boolean;
  bookingLink?: boolean;
  tenantSubscriptions?: boolean;
}

type FeatureFlagHeaders = 'bookingLink' | 'tenantSubscriptions';

export const createConversation =
  (makeCall: MakeCall) =>
  (
    data: CreateConversationPayload,
    featureFlags?: ConversationFeatureFlags,
  ) => {
    const features: FeatureFlagHeaders[] = [];
    if (featureFlags?.bookingLink) {
      features.push('bookingLink');
    }
    if (featureFlags?.tenantSubscriptions) {
      features.push('tenantSubscriptions');
    }

    const headers =
      features.length > 0 ? { 'X-HA-Features': features.join(';') } : {};

    return makeCall<Conversation>('POST', `/api/v2/conversation`, {
      data,
      headers,
    });
  };
