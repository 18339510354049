import { normalizeCityData } from './city';
import { normalizeCountryData } from './country';

export function transformHermesData(data: {}, pageName: string) {
  switch (pageName) {
    case 'Cities':
      return normalizeCityData(data);
    case 'CountryGuide':
      return normalizeCountryData(data);
    default:
      return data;
  }
}
