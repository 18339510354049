import { defineKey as T } from '@ha/intl';

export const STATE_NAME = 'payoutMethods';

export const Actions = {
  FETCH_PAYOUT_METHODS: `${STATE_NAME}/FETCH_PAYOUT_METHODS`,
  FETCH_PAYOUT_METHODS_SUCCESS: `${STATE_NAME}/FETCH_PAYOUT_METHODS_SUCCESS`,
  FETCH_PAYOUT_METHODS_FAIL: `${STATE_NAME}/FETCH_PAYOUT_METHODS_FAIL`,
  SET_AS_DEFAULT: `${STATE_NAME}/SET_AS_DEFAULT`,
  SET_AS_DEFAULT_DONE: `${STATE_NAME}/SET_AS_DEFAULT_DONE`,
  SET_AS_DEFAULT_FAIL: `${STATE_NAME}/SET_AS_DEFAULT_FAIL`,
  REMOVE_METHOD: `${STATE_NAME}/REMOVE_METHOD`,
  REMOVE_METHOD_DONE: `${STATE_NAME}/REMOVE_METHOD_DONE`,
  REMOVE_METHOD_FAIL: `${STATE_NAME}/REMOVE_METHOD_FAIL`,
  SAVE_METHOD: `${STATE_NAME}/SAVE_METHOD`,
  SAVE_METHOD_DONE: `${STATE_NAME}/SAVE_METHOD_DONE`,
  SAVE_METHOD_FAIL: `${STATE_NAME}/SAVE_METHOD_FAIL`,
};

export const Messages = {
  methodRemoved: T('Payout method removed.'),
  failedToRemove: T('Failed to remove payout method.'),
  invalidArgument: T('payments.add_payout_method.wrong_info_failure'),
  changesSaved: T(
    'Changes saved. A payout method change requires your confirmation, please check your email for instructions.',
  ),
  failedToSave: T('Failed to save payout method.'),
  setIbanAsDefaultSuccess: T(
    'Your payout method "Bank transfer: IBAN" has been set as default',
  ),
  failedToSetDefault: T('Failed to set payout method as default.'),
};

export const buttonNames = {
  continue: T('Continue'),
  submit: T('Submit'),
};

export const ICON_BUTTON_WIDTH = 44;
