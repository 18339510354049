import { MakeCall } from './createMakeCall';

import { User } from './types/User';

export interface UserApiV2Response {
  users: User[];
}

const getUsers = (makeCall: MakeCall) => (uuids: string[]) =>
  makeCall<UserApiV2Response>('POST', `/api/v2/users`, {
    data: { uuids },
  });

export { getUsers };
