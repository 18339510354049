import { track } from '@hbf/analytics';

// The nonInteraction parameter in the events lets you specify
// the bounce rate for pages in your website that also include event measurement.
// https://www.rudderstack.com/docs/destinations/streaming-destinations/google-analytics-ga/#non-interaction-events
// https://support.google.com/analytics/answer/1033068#NonInteractionEvents&zippy=%2Cin-this-article

export const DEFAULT_NON_INTERACTION = 1;
export const USER_TAGGED_FOR_REMARKETING_EVENT = 'User tagged for remarketing';

export const setRemarketingTag = (
  tags: object,
  { nonInteraction } = { nonInteraction: DEFAULT_NON_INTERACTION },
) => {
  track(USER_TAGGED_FOR_REMARKETING_EVENT, { ...tags, nonInteraction });
};
