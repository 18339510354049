import { MakeCall } from '../createMakeCall';
import { UnitTypeVideo } from '../types/inventory/UnitType';

const addYoutubeVideoUnitType =
  (makeCall: MakeCall) => (unitTypeId: number, youtubeUrl: string) =>
    makeCall<UnitTypeVideo[]>(
      'POST',
      `/api/v2/unit-type/${unitTypeId}/youtube-video`,
      {
        data: { youtubeUrl },
      },
    );
export { addYoutubeVideoUnitType };
