import { FilterBuilder } from '../Types';

const propertySizeBuilder: FilterBuilder = (params, context) => {
  if (context?.withoutPropertySize) {
    return '';
  }

  const sizeMinFilter = params.sizeMin ? `propertySize>=${params.sizeMin}` : '';
  const sizeMaxFilter = params.sizeMax ? `propertySize<=${params.sizeMax}` : '';

  return [sizeMinFilter, sizeMaxFilter].filter(Boolean).join(' AND ');
};

export { propertySizeBuilder };
