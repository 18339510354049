// Message parsed from realtime service
// Our backend will define this structure
export interface RealtimeMessage {
  authorId: number;
  recipientId: number;
  text: string;
  type: string;
  isRead: boolean;
  createdAt: string;
  isFromEmail: boolean;
  isSystem: boolean;
  conversationId: number;
  conversationState: number;
  conversationStateChangedAt: string;
  conversationEndDate: string;
  conversationStartDate: string;
}

export class RealtimeNetworkError extends Error {
  status: number;

  constructor(message: string, options: ErrorOptions & { status: number }) {
    super(message, options);
    this.status = options.status;
  }
}
