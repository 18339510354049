import { combineReducers } from 'redux';

import { Language } from '@ha/intl';
import * as availableLanguagesSelectors from './availableLanguages';
import { availableLanguages } from './availableLanguages';
import * as currentLanguageSelectors from './language';
import { language as currentLanguage } from './language';
import * as localisedLocationsSelectors from './localisedLocations';

import { LanguageSwitcherState } from '../types';

const reducer = combineReducers({
  availableLanguages,
  currentLanguage,
  localisedLocations: localisedLocationsSelectors.localisedLocations,
});

export const getCurrentLanguageCode = (state: LanguageSwitcherState) =>
  currentLanguageSelectors.getCode(state.currentLanguage);

export const getAvailableLanguages = (state: LanguageSwitcherState) =>
  availableLanguagesSelectors.getAvailableLanguages(state.availableLanguages);

export const getLocalisedLocation = (
  state: LanguageSwitcherState,
  lang: Language,
) =>
  localisedLocationsSelectors.getLocalisedLocation(
    state.localisedLocations,
    lang,
  );

export const isLanguageAvailable = (
  state: LanguageSwitcherState,
  lang: Language,
) =>
  availableLanguagesSelectors.isLanguageAvailable(
    state.availableLanguages,
    lang,
  );
export { reducer };
