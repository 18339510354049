import PropTypes from 'prop-types';

export const propTypes = PropTypes.shape({
  T: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  formatNumber: PropTypes.func.isRequired,
  formatCurrency: PropTypes.func.isRequired,
  formatUnit: PropTypes.func.isRequired,
  formatDateTime: PropTypes.func.isRequired,
  formatRelativeTime: PropTypes.func.isRequired,
  formatDate: PropTypes.func.isRequired,
  urlResolver: PropTypes.shape({
    absolutizePath: PropTypes.func.isRequired,
    formatHrefURL: PropTypes.func.isRequired,
    getAnswersUrl: PropTypes.func.isRequired,
    getBaseUrl: PropTypes.func.isRequired,
    getContactUrl: PropTypes.func.isRequired,
    getFbAuthUrl: PropTypes.func.isRequired,
    getGoogleAuthUrl: PropTypes.func.isRequired,
    getRecoverAccountUrl: PropTypes.func.isRequired,
    getSearchUrl: PropTypes.func.isRequired,
    getConversationUrl: PropTypes.func.isRequired,
    getConversationBookingUrl: PropTypes.func.isRequired,
    getPaymentUrl: PropTypes.func.isRequired,
  }).isRequired,
});
