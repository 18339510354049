import { MakeCall } from './createMakeCall';

import { QuickReply } from './types/QuickReplies';

export interface Payload {
  name: string;
  body: string;
  quickReplyChannel?: 'talkPageModal' | 'settingsPage' | string;
}

export type UserIdType = number | 'me';

const createQuickReply =
  (makeCall: MakeCall) =>
  (userId: UserIdType = 'me', data: Payload) =>
    makeCall<QuickReply>(
      'POST',
      `/api/v2/conversations/user/${userId}/quick-reply-template`,
      {
        data,
      },
    );
export { createQuickReply };
