import { registerSW } from './registerSW';

export function initServiceWorker(
  url: string,
  options: {
    /** current location origin */
    origin: string;
    onError: (error: Error) => void;
  },
) {
  const swUrl = new URL(url);

  if (swUrl.origin === options.origin) {
    registerSW(swUrl.href, { onError: options.onError });
  } else {
    // service worker won't work if swUrl.origin is on a different origin
  }
}
