import { stringify } from 'query-string';

import {
  UserSearchFilters as Filters,
  AvailabilityFilter,
} from '@ha/api/v2/types/UserSearchFilters';

import { FetchClient } from 'ha/helpers/FetchClient';
import { preprocessResponse } from 'ha/utils/preprocessResponse';

type SearchAlertStatus = 'enabled' | 'disabled' | 'expired' | 'unsubscribed';

export type SearchAlertChannel = 'email' | 'whatsapp';

export type SearchAlertFrequency = 'daily' | 'instant';

export type { Filters, AvailabilityFilter };

export interface SearchAlert {
  alertID: string;
  userUUID: string;
  alias: string;
  city: string;
  countryCode: string;
  filters: Filters;
  frequency: SearchAlertFrequency;
  channel: SearchAlertChannel;
  status: SearchAlertStatus;
  timezone: string;
  updatedAt: string;
  email?: string;
}

interface BoundingBox {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

interface GetSearchAlertsParams {
  status?: SearchAlertStatus;
  countryCode?: string;
  city?: string;
}

type GetSearchAlertsResponse = SearchAlert[];

export interface CreateSearchAlertRequest
  extends Pick<
    SearchAlert,
    | 'city'
    | 'countryCode'
    | 'alias'
    | 'filters'
    | 'channel'
    | 'frequency'
    | 'timezone'
    | 'email'
  > {
  boundingBox: BoundingBox;
}

export type EditSearchAlertRequest = Pick<
  SearchAlert,
  'alertID' | 'alias' | 'filters' | 'channel' | 'frequency' | 'timezone'
>;

type CreateSearchAlertResponse = SearchAlert;
type EditSearchAlertResponse = SearchAlert;
type EnableSearchAlertResponse = SearchAlert;
type DisableSearchAlertResponse = SearchAlert;

type UnsubscribeSearchAlertParams = Pick<SearchAlert, 'alertID' | 'userUUID'>;
type DeleteSearchAlertParams = Pick<SearchAlert, 'alertID' | 'userUUID'>;
type EnableSearchAlertParams = Pick<SearchAlert, 'alertID' | 'userUUID'>;
type DisableSearchAlertParams = Pick<SearchAlert, 'alertID' | 'userUUID'>;

export function makeBellService(fetchClient: FetchClient) {
  return {
    getSearchAlerts: (
      params: GetSearchAlertsParams = {},
    ): Promise<GetSearchAlertsResponse> => {
      const queryParams = stringify(params);

      return fetchClient
        .fetch(`/api/v1/alert/user${queryParams ? `?${queryParams}` : ''}`, {
          keepalive: true,
        })
        .then(preprocessResponse);
    },
    createSearchAlert: (
      body: CreateSearchAlertRequest,
    ): Promise<CreateSearchAlertResponse> =>
      fetchClient
        .fetch('/api/v1/alert', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
        .then(preprocessResponse),
    editSearchAlert: (
      body: EditSearchAlertRequest,
    ): Promise<EditSearchAlertResponse> =>
      fetchClient
        .fetch('/api/v1/alert', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
        .then(preprocessResponse),
    unsubscribeSearchAlert: (
      params: UnsubscribeSearchAlertParams,
    ): Promise<void> =>
      fetchClient
        .fetch(
          `/api/v1/alert/${params.alertID}/${params.userUUID}/unsubscribe`,
          {
            method: 'POST',
          },
        )
        .then(preprocessResponse),
    enableSearchAlert: (
      params: EnableSearchAlertParams,
    ): Promise<EnableSearchAlertResponse> =>
      fetchClient
        .fetch(`/api/v1/alert/${params.alertID}/${params.userUUID}/enable`, {
          method: 'POST',
        })
        .then(preprocessResponse),
    disableSearchAlert: (
      params: DisableSearchAlertParams,
    ): Promise<DisableSearchAlertResponse> =>
      fetchClient
        .fetch(`/api/v1/alert/${params.alertID}/${params.userUUID}/disable`, {
          method: 'POST',
        })
        .then(preprocessResponse),
    deleteSearchAlert: (params: DeleteSearchAlertParams): Promise<void> =>
      fetchClient
        .fetch(`/api/v1/alert/${params.alertID}/${params.userUUID}`, {
          method: 'DELETE',
        })
        .then(preprocessResponse),
  };
}

export function makeBellServiceV2(fetchClient: FetchClient) {
  return {
    getSearchAlert: (params: GetSearchAlertsParams = {}) => {
      const queryParams = stringify(params);

      return fetchClient
        .fetch(`/api/v2/alert${queryParams ? `?${queryParams}` : ''}`, {
          keepalive: true,
        })
        .then(preprocessResponse) as Promise<GetSearchAlertsResponse>;
    },
    createSearchAlert: (body: CreateSearchAlertRequest) =>
      fetchClient
        .fetch('/api/v2/alert', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
        .then(preprocessResponse) as Promise<CreateSearchAlertResponse>,
    editSearchAlert: (body: EditSearchAlertRequest) =>
      fetchClient
        .fetch('/api/v2/alert', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
        .then(preprocessResponse) as Promise<EditSearchAlertResponse>,
    unsubscribeSearchAlert: (
      params: Pick<UnsubscribeSearchAlertParams, 'alertID'>,
    ) =>
      fetchClient
        .fetch(`/api/v2/alert/${params.alertID}/unsubscribe`, {
          method: 'POST',
        })
        .then(preprocessResponse) as Promise<void>,
    enableSearchAlert: (params: Pick<EnableSearchAlertParams, 'alertID'>) =>
      fetchClient
        .fetch(`/api/v2/alert/${params.alertID}/enable`, {
          method: 'PUT',
        })
        .then(preprocessResponse) as Promise<EnableSearchAlertResponse>,
    disableSearchAlert: (params: Pick<DisableSearchAlertParams, 'alertID'>) =>
      fetchClient
        .fetch(`/api/v2/alert/${params.alertID}/disable`, {
          method: 'PUT',
        })
        .then(preprocessResponse) as Promise<DisableSearchAlertResponse>,
    deleteSearchAlert: (params: Pick<DeleteSearchAlertParams, 'alertID'>) =>
      fetchClient
        .fetch(`/api/v2/alert/${params.alertID}`, {
          method: 'DELETE',
        })
        .then(preprocessResponse) as Promise<void>,
  };
}
