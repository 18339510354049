import { MakeCall } from '../createMakeCall';
import { FavoriteUnitType } from '../types/inventory/FavoriteUnitType';

export type RemoveFavoriteUnitTypeCall = (
  unitTypeId: number,
) => Promise<{ data: FavoriteUnitType }>;

const removeFavoriteUnitType = (makeCall: MakeCall) => (unitTypeId: number) =>
  makeCall<undefined>('DELETE', `/api/v2/unit-type/${unitTypeId}/favorite`);
export { removeFavoriteUnitType };
