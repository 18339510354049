import { MakeCall } from '@ha/api/v2/createMakeCall';

interface Response {
  reply: string;
}

export const editTenantReviewReply =
  (makeCall: MakeCall) =>
  ({ tenancyUUID, message }: { tenancyUUID: string; message: string }) =>
    makeCall<Response>(
      'PUT',
      `/api/v2/review/reply?tenancyUUID=${tenancyUUID}`,
      {
        data: {
          reply: message,
        },
      },
    );
