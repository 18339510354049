import { TypeValue } from 'ha/types/SearchFilters';

export const STATE_NAME = 'search';
export const STORAGE_KEY = 'visited_markers';
export const ACTION_PREFIX = 'Search';

export const Actions = {
  UPDATE_MAP: `${ACTION_PREFIX}.UPDATE_MAP`,
  UPDATE_BOUNDS: `${ACTION_PREFIX}.UPDATE_BOUNDS`,
  UPDATE_MAP_VIEW: `${ACTION_PREFIX}.UPDATE_MAP_VIEW`,
  RESET_MAP: `${ACTION_PREFIX}.RESET_MAP`,
  SET_VISITED_ROOMS: `${ACTION_PREFIX}.SET_VISITED_ROOMS`,
  UPDATE_SEARCH_PARAMS: `${ACTION_PREFIX}.UPDATE_SEARCH_PARAMS`,
  SET_PREVIOUS_PARAMS: `${ACTION_PREFIX}.SET_PREVIOUS_PARAMS`,

  DISABLE_RESULTS_WITH_RELEVANT_AVAILABILITY: `${ACTION_PREFIX}.DISABLE_RESULTS_WITH_RELEVANT_AVAILABILITY`,

  CHANGE_PAGE: `${ACTION_PREFIX}.CHANGE_PAGE`,
  CHANGE_FILTERS: `${ACTION_PREFIX}.CHANGE_FILTERS`,

  LOAD_START: `${ACTION_PREFIX}.LOAD_START`,
  LOAD_DONE: `${ACTION_PREFIX}.LOAD_DONE`,
  LOAD_ERROR: `${ACTION_PREFIX}.LOAD_ERROR`,
  LOAD_NOT_FOUND: `${ACTION_PREFIX}.LOAD_NOT_FOUND`,
  LOAD_UNSUPPORTED_COUNTRY: `${ACTION_PREFIX}.LOAD_UNSUPPORTED_COUNTRY`,

  LOAD_UNIVERSITIES_START: `${ACTION_PREFIX}.LOAD_UNIVERSITIES_START`,
  LOAD_UNIVERSITIES_DONE: `${ACTION_PREFIX}.LOAD_UNIVERSITIES_DONE`,

  LOAD_CITIES_NEARBY_START: `${ACTION_PREFIX}.LOAD_CITIES_NEARBY_START`,
  LOAD_CITIES_NEARBY_DONE: `${ACTION_PREFIX}.LOAD_CITIES_NEARBY_DONE`,

  LOAD_CURRENCY_RATES_START: `${ACTION_PREFIX}.LOAD_CURRENCY_RATES_START`,
  LOAD_CURRENCY_RATES_DONE: `${ACTION_PREFIX}.LOAD_CURRENCY_RATES_DONE`,
  LOAD_CURRENCY_RATES_ERROR: `${ACTION_PREFIX}.LOAD_CURRENCY_RATES_ERROR`,

  UPDATE_SEARCH_START: `${ACTION_PREFIX}.UPDATE_SEARCH_START`,
  UPDATE_SEARCH_DONE: `${ACTION_PREFIX}.UPDATE_SEARCH_DONE`,
  UPDATE_SEARCH_ERROR: `${ACTION_PREFIX}.UPDATE_SEARCH_ERROR`,

  TOGGLE_MOBILE_MAP: `${ACTION_PREFIX}.TOGGLE_MOBILE_MAP`,
  TOGGLE_MOBILE_DATE_PICKER: `${ACTION_PREFIX}.TOGGLE_MOBILE_DATE_PICKER`,
  CREATE_SEARCH_ALERT_START: `${ACTION_PREFIX}.CREATE_SEARCH_ALERT_START`,
  CREATE_SEARCH_ALERT_DONE: `${ACTION_PREFIX}.CREATE_SEARCH_ALERT_DONE`,
  CREATE_SEARCH_ALERT_ERROR: `${ACTION_PREFIX}.CREATE_SEARCH_ALERT_ERROR`,

  GET_SEARCH_ALERT_START: `${ACTION_PREFIX}.GET_SEARCH_ALERT_START`,
  GET_SEARCH_ALERT_DONE: `${ACTION_PREFIX}.GET_SEARCH_ALERT_DONE`,

  LOAD_ADVERTISER_START: `${ACTION_PREFIX}.LOAD_ADVERTISER_START`,
  LOAD_ADVERTISER_DONE: `${ACTION_PREFIX}.LOAD_ADVERTISER_DONE`,
  LOAD_ADVERTISER_ERROR: `${ACTION_PREFIX}.LOAD_ADVERTISER_ERROR`,

  LOAD_CITY_START: `${ACTION_PREFIX}.LOAD_CITY_START`,
  LOAD_CITY_DONE: `${ACTION_PREFIX}.LOAD_CITY_DONE`,
  LOAD_CITY_ERROR: `${ACTION_PREFIX}.LOAD_CITY_ERROR`,
};

/*
 *The number of banners that we show between search results
 */
export const NUMBER_OF_BANNERS = 1;

export const DefaultDistributionSteps = 50;
export const DefaultDistributionTrimPercentage = 5;
export const PageSize = 28 - NUMBER_OF_BANNERS;
export const DefaultRadius = 200000;
export const DefaultZoomLevelDesktop = 14;
export const DefaultZoomLevelMobile = 12;
export const DefaultMinZoomLevelDesktop = 10;
export const DefaultMinZoomLevelMobile = 10;
export const MaxMapZoomLevel = 18;
export const PreviewCardWidth = 300;
export const sidebarWidth = 285;

export const KindsForSavedSearch = {
  [TypeValue.APARTMENT]: ['entire place'],
  [TypeValue.PRIVATE_ROOM]: ['private room'],
  [TypeValue.SHARED_ROOM]: ['shared room'],
  [TypeValue.STUDIO]: ['entire place'],
  [TypeValue.STUDENT_HOUSING]: ['student housing'],
};

export const PageType = 'searchresults';

export const FacetQueryAttributes = [
  'facility_bedroom_furnished',
  'utilities',
  'contractType',
  'couplesAllowed',
  'facility_tenant_status',
  'preferredGender',
  'facility_registration_possible',
  'facility_student_housing',
  'facility_bathroom',
  'facility_balcony_terrace',
  'facility_garden',
  'facility_kitchen',
  'facility_animal_allowed',
  'facility_parking',
  'facility_wheelchair_accessible',
  'facility_basement',
  'facility_tv',
  'facility_desk',
  'facility_dishwasher',
  'facility_washing_machine',
  'facility_dryer',
  'facility_ac',
  'facility_heating',
  'advertiserMoveInOverallRating',
];

export const NEW_ABOUT_SEO_CONFIG = {
  countryCodes: ['es'],
};

export const CITY_STUDENT_ACCOMMODATION_SEO = ['Berlin', 'Munich'];

export const SEARCH_MAP_PIN_OPTIONS = [
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.school',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi.school',
    elementType: 'labels.icon',
    stylers: [
      {
        color: '#d01616',
      },
    ],
  },
];

export const SEARCH_LAYOUT_LISTINGS_CONTAINER =
  'SearchLayout-listingsContainer';
