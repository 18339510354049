import { MakeCall } from './createMakeCall';

import { RequestUserDocumentViewUrl } from './types/UserDocument';

const getUserDocumentViewUrl =
  (makeCall: MakeCall) => (userId: 'me' | number, docUUID: string) =>
    makeCall<RequestUserDocumentViewUrl>(
      'GET',
      `/api/v2/user/${userId}/document/${docUUID}/url`,
    );
export { getUserDocumentViewUrl };
