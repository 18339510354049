import { MakeCall } from './createMakeCall';

import { PostUserKYCPayload, PostUserKYCResponse } from './types/Kyc';

const putUserKyc =
  (makeCall: MakeCall) =>
  (userId: 'me' | number, kycUUID: string, data: Partial<PostUserKYCPayload>) =>
    makeCall<PostUserKYCResponse>(
      'PUT',
      `/api/v2/user/${userId}/kyc/${kycUUID}`,
      {
        data,
      },
    );
export { putUserKyc };
