import { MakeCall } from './createMakeCall';

export interface Query {
  city: string;
  countryCode: string;
  propertyType: number;
}

export interface GetAverageRentResponse {
  amount: number;
  currencyCode: string;
}
const getAverageRent =
  (makeCall: MakeCall) => (query: Query, signal?: AbortSignal) =>
    makeCall<GetAverageRentResponse>('GET', `/api/v2/unit-types/average-rent`, {
      query,
      signal,
    });

export { getAverageRent };
