import { MakeCall } from '@ha/api/v2/createMakeCall';
import { UnitTypeQualificationRequirement } from '../types/qualifications/QualificationRequirement';

const getQualificationRequirementsByUnitType =
  (makeCall: MakeCall) => (unitTypeUuid: string) =>
    makeCall<UnitTypeQualificationRequirement>(
      'GET',
      `/api/v2/qualification/requirements/by-unit-type/${unitTypeUuid}`,
    );
export { getQualificationRequirementsByUnitType };
