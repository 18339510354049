interface RemoveURLSearchParams {
  /**
   * The URLSearchParams object to sanitize.
   */
  urlSearchParams: URLSearchParams;
  /**
   * An array of parameter names to remove.
   */
  itemsToRemove: string[];
}

/**
 * Sanitizes a URLSearchParams object by removing specified parameters.
 *
 * @returns A new URLSearchParams object with the specified parameters removed.
 */
export const removeURLSearchParams = ({
  urlSearchParams,
  itemsToRemove = [],
}: RemoveURLSearchParams): URLSearchParams => {
  const sanitizedURLSearchParams = new URLSearchParams(urlSearchParams);

  itemsToRemove.forEach(item => {
    sanitizedURLSearchParams.delete(item);
  });

  return sanitizedURLSearchParams;
};
