export const FeaturedCountries = [
  { name: 'Austria', code: 'at' },
  { name: 'Belgium', code: 'be' },
  { name: 'Canada', code: 'ca' },
  { name: 'Czech-Republic', code: 'cz' },
  { name: 'France', code: 'fr' },
  { name: 'Germany', code: 'de' },
  { name: 'Greece', code: 'gr' },
  { name: 'Hungary', code: 'hu' },
  { name: 'Iceland', code: 'is' },
  { name: 'Ireland', code: 'ie' },
  { name: 'Italy', code: 'it' },
  { name: 'Netherlands', code: 'nl' },
  { name: 'Poland', code: 'pl' },
  { name: 'Portugal', code: 'pt' },
  { name: 'Slovenia', code: 'si' },
  { name: 'Spain', code: 'es' },
  { name: 'Switzerland', code: 'ch' },
  { name: 'United-Kingdom', code: 'gb' },
  { name: 'United-States', code: 'us' },
];
