import { TypeValue } from '../../../types/SearchFilters';
import { FilterBuilder } from '../Types';

const propertyTypeBuilder: FilterBuilder = ({ categories = [] }, context) => {
  if (
    !categories.length ||
    categories.length === Object.keys(TypeValue).length ||
    context?.withoutPropertyType
  ) {
    return '';
  }

  const categoriesSet = new Set(categories);
  if (context?.expanded) {
    if (categoriesSet.has(TypeValue.STUDIO)) {
      categoriesSet.add(TypeValue.APARTMENT);
    }
    if (categoriesSet.has(TypeValue.PRIVATE_ROOM)) {
      categoriesSet.add(TypeValue.STUDIO);
    }
    if (categoriesSet.has(TypeValue.SHARED_ROOM)) {
      categoriesSet.add(TypeValue.PRIVATE_ROOM);
    }
  }

  const propertyArray = Array.from(categoriesSet).reduce(
    (array: string[], category) => {
      switch (category) {
        case TypeValue.PRIVATE_ROOM:
          return [...array, 'propertyType:PRIVATE_ROOM'];
        case TypeValue.SHARED_ROOM:
          return [...array, 'propertyType:SHARED_ROOM'];
        case TypeValue.APARTMENT:
          return [...array, 'propertyType:APARTMENT', 'propertyType:HOUSE'];
        case TypeValue.STUDIO:
          return [...array, 'propertyType:STUDIO'];
        case TypeValue.STUDENT_HOUSING:
          return [...array, 'facility_student_housing:yes'];
        default:
          return array;
      }
    },
    [],
  );

  if (propertyArray.length === 1) {
    return propertyArray[0];
  }
  if (propertyArray.length > 1) {
    return `(${propertyArray.join(' OR ')})`;
  }

  return '';
};

export { propertyTypeBuilder };
