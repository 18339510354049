import type { MakeCall } from '../createMakeCall';
import { BillingInformation } from '../types/BillingInformation';

export interface APICreateMultiBillingData {
  billingInfo: BillingInformation;
}

const deleteMultiBillingInformation =
  (makeCall: MakeCall) => (billingInfoUUID: string) =>
    makeCall<APICreateMultiBillingData>(
      'DELETE',
      `/api/v2/billing/billing-info/${billingInfoUUID}`,
    );

export { deleteMultiBillingInformation };
