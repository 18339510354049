import { MakeCall } from './createMakeCall';

export interface Options {
  rangeFrom?: string;
  rangeTo?: string;
  adjustDates?: boolean;
  exclusionId?: number;
}

const deleteListingExclusions =
  (makeCall: MakeCall) => (listingId: number, options?: Options) =>
    makeCall<undefined>('DELETE', `/api/v2/listing/${listingId}`, {
      query: { ...options },
    });
export { deleteListingExclusions };
