import { defineKey as T } from '@ha/intl';

export const STATE_NAME = 'updatePaymentRequest';

const PREFIX = 'PAY_PAYMENT_REQ_PAGE';

export const Actions = {
  LOAD_PAYMENT_REQ_START: `${PREFIX}/LOAD_PAYMENT_REQ_START`,
  LOAD_PAYMENT_REQ_FAIL: `${PREFIX}/LOAD_PAYMENT_REQ_FAIL`,
  LOAD_PAYMENT_REQ_SUCCESS: `${PREFIX}/LOAD_PAYMENT_REQ_SUCCESS`,
  PAY_PAYMENT_REQ_START: `${PREFIX}/PAY_PAYMENT_REQ_START`,
  PAY_PAYMENT_REQ_FAIL: `${PREFIX}/PAY_PAYMENT_REQ_FAIL`,
  PAY_PAYMENT_REQ_SUCCESS: `${PREFIX}/PAY_PAYMENT_REQ_SUCCESS`,
};

export const ERROR_MESSAGES = {
  card_declined: T('pr_card_declined'),
  expired_card: T('pr_expired_card'),
  generic_decline: T('stripe_decline_code_generic_decline'),
  incorrect_cvc: T('pr_incorrect_cvc'),
  incorrect_zip: T('pr_incorrect_zip'),
  incorrect_number: T('pr_incorrect_number'),
  insufficient_funds: T('pr_insufficient_funds'),
  invalid_cvc: T('pr_invalid_cvc'),
  invalid_expiry_month: T('pr_invalid_expiry_month'),
  invalid_expiry_year: T('pr_invalid_expiry_year'),
  invalid_number: T('pr_invalid_number'),
  payment_intent_authentication_failure: T(
    'stripe_code_payment_intent_authentication_failure',
  ),
  processing_error: T('pr_processing_error'),
  internal: T('pr_internal'),
  concurrent_payment: T('pr_concurrent_payment'),
  transaction_not_allowed: T('pr_transaction_not_allowed'),
  do_not_honor: T('pr_do_not_honor'),
  'source.failed': T('pr_ideal_rejected_by_user'),
  'source.canceled': T('pr_ideal_rejected_by_bank'),
};

export const getErrorMessage = (key: string): string =>
  ERROR_MESSAGES[key] || ERROR_MESSAGES.internal;

export const commonErrors = {
  1: T('payments.payment_request_load_error.generic'),
  2: T('payments.payment_request_load_error.plan_not_accepted'),
  3: T('payments.payment_request_load_error.listing_not_available'),
};

export const payInFormStates = [
  'created',
  'paid-out',
  'underpaid',
  'refunded',
  'dispute-lost',
];
