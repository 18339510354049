import { ApiObject, identify } from '@hbf/analytics';

import {
  EMPTY_USER_ID,
  INTEGRATIONS_WITHOUT_ROCKERBOX,
  ONLY_ROCKERBOX_INTEGRATION,
  PII_PROPS,
} from './constants';

export const identifyRockerbox = (
  userUUID: string,
  identifyPayload: ApiObject,
) => {
  // Due to an issue in the Rockerbox integration, we need to send the UUID value for external_id & email attribute separately
  // this will change the cookies to the userUUID which will change the value of external_id attribute
  identify(userUUID, identifyPayload, {
    integrations: ONLY_ROCKERBOX_INTEGRATION,
    context: {
      traits: {
        email: userUUID,
      },
    },
  });
};

export const identifyWithoutRockerbox = (
  userId: string,
  identifyPayload: ApiObject,
) => {
  // resets the cookies back to, userid based cookies
  identify(userId, identifyPayload, {
    integrations: INTEGRATIONS_WITHOUT_ROCKERBOX,
  });
};

export const identifyWithRockerbox = (
  userUUID: string,
  identifyPayload: ApiObject,
  aliasId: string,
) => {
  identifyRockerbox(userUUID, identifyPayload);

  identifyWithoutRockerbox(aliasId, identifyPayload);
};

export const identifyAnonymously = (identifyPayload: ApiObject) => {
  const anonymousPayload = { ...identifyPayload };

  PII_PROPS.forEach(prop => {
    // fields should be presented with "undefined" value to re-write existing traits values
    anonymousPayload[prop] = undefined;
  });

  // To set a blank user ID, you can pass an empty string or " " to userId
  // Do not pass an identify call with null as RudderStack will not allow
  // you to pass a traits object and retain the current userId.
  // https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/supported-api/#setting-a-blank-user-id
  identifyWithoutRockerbox(EMPTY_USER_ID, anonymousPayload);
};
