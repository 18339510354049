import { MakeCall } from './createMakeCall';
import { Listing, ListingExclusion } from './types/Listing';

export const deleteListingExclusionReminder =
  (makeCall: MakeCall) =>
  (listingId: Listing['id'], data: Pick<ListingExclusion, 'from' | 'to'>) =>
    makeCall<undefined>(
      'DELETE',
      `/api/v2/listing/${listingId}/exclusions/reminders`,
      { data },
    );
