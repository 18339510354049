import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export function getMonthsBetween(startDate: string, endDate: string): number {
  const dStartDate = dayjs(startDate);
  const dEndDate = dayjs(endDate);

  // handle 0 month in the same month in case whole month has not been selected
  if (
    dStartDate.format('YYMM') === dEndDate.format('YYMM') &&
    dEndDate.date() < dEndDate.daysInMonth()
  ) {
    return 0;
  }

  // Adding 2 days to include the start and end dates in the calculation
  return dEndDate.add(2, 'day').diff(dStartDate, 'month');
}
