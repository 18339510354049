import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const isDayBetween = (
  startBoundary: Date | string,
  endBoundary: Date | string,
  day: Dayjs | Date | string,
): boolean =>
  dayjs(day)
    .startOf('day')
    .isBetween(
      dayjs(startBoundary).startOf('day'),
      dayjs(endBoundary).startOf('day'),
      undefined,
      '[]',
    );

export { isDayBetween };
