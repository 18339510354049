import { MakeCall } from './createMakeCall';

export interface InvoiceParams {
  offset: number;
  limit: number;
}

export interface UserInvoice {
  externalConversationID: number;
  invoiceID: number;
  invoiceNumber: string;
  invoiceURL: string;
  type: number;
  date: {
    day: number;
    month: number;
    year: number;
  };
  totalAmount: {
    cents: number;
    currencyCode: string;
  };
}

export interface UserInvoices {
  invoices: UserInvoice[];
  totalCount: number;
}

const getInvoices =
  (makeCall: MakeCall) => (userId: number, query?: InvoiceParams) =>
    makeCall<UserInvoices>('GET', `/api/v2/user/${userId}/invoices`, {
      query,
    });
export { getInvoices };
