import type { MakeCall } from '../createMakeCall';

import { PayInTypeResource } from './types';

type Query = {
  pspName?: string;
};

export const getPayInTypes = (makeCall: MakeCall) => (query: Query) =>
  makeCall<PayInTypeResource>('GET', `/api/v2/payment/method/pay-in-types`, {
    query,
  });
