import { getToday, addDays } from '@ha/date';

import { FilterBuilder } from '../Types';

const creationDateTSBuilder: FilterBuilder = params => {
  const { recentlyAdded } = params;

  if (!recentlyAdded || recentlyAdded === 'all') {
    return '';
  }

  const daysBack = {
    '1-day': 1,
    '3-days': 3,
    '7-days': 7,
    '14-days': 14,
  }[recentlyAdded];

  const creationDate = addDays(getToday(), -daysBack);
  const creationDateTS = new Date(creationDate).getTime() / 1000;

  return `creationDateTS>=${creationDateTS}`;
};

export { creationDateTSBuilder };
