import { FilterBuilder } from '../Types';

const getExpandMultiplier = (priceMin?: number, priceMax?: number) => {
  if (!priceMin && !priceMax) {
    return 0;
  }
  if (priceMin && priceMax) {
    return 0.15;
  }
  return 0.2;
};

const priceBuilder: FilterBuilder = (params, context) => {
  if (context?.withoutPrice) {
    return '';
  }

  const queryKey = context?.withDynamicMinimumPrice ? 'minPrice' : 'priceEUR';
  let priceMinFilter = '';
  let priceMaxFilter = '';

  const expandMultiplier = context?.expanded
    ? getExpandMultiplier(params.priceMin, params.priceMax)
    : 0;

  if (params.priceMin) {
    priceMinFilter = `${queryKey}>=${Math.floor(
      (params.priceMin - params.priceMin * expandMultiplier) / 100,
    )}`;
  }

  if (params.priceMax || (context?.allowZeroPrice && params.priceMax === 0)) {
    priceMaxFilter = `${queryKey}<=${Math.ceil(
      (params.priceMax + params.priceMax * expandMultiplier) / 100,
    )}`;
  }

  return [priceMinFilter, priceMaxFilter].filter(Boolean).join(' AND ');
};

export { priceBuilder };
