/**
 * https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
 */
export enum Countries {
  // European Union

  Austria = 'AT',
  Belgium = 'BE',
  Bulgaria = 'BG',
  Croatia = 'HR',
  Cyprus = 'CY',
  Czechia = 'CZ',
  Denmark = 'DK',
  Estonia = 'EE',
  Finland = 'FI',
  France = 'FR',
  Germany = 'DE',
  Greece = 'GR',
  Hungary = 'HU',
  Ireland = 'IE',
  Italy = 'IT',
  Latvia = 'LV',
  Lithuania = 'LT',
  Luxembourg = 'LU',
  Malta = 'MT',
  Netherlands = 'NL',
  Poland = 'PL',
  Portugal = 'PT',
  Romania = 'RO',
  Slovakia = 'SK',
  Slovenia = 'SI',
  Spain = 'ES',
  Sweden = 'SE',

  // Other european countries

  Iceland = 'IS',
  Norway = 'NO',
  Liechtenstein = 'LI',

  Switzerland = 'CH',

  UnitedKingdom = 'UK',

  // North America
  UnitedStates = 'US',
  Canada = 'CA',
}

export const SUPPORTED_COUNTRIES = Object.values(Countries);

/**
 * https://www.government.nl/topics/european-union/eu-eea-efta-and-schengen-area-countries
 */
export const EUROPEAN_UNION_COUNTRIES = [
  Countries.Austria,
  Countries.Belgium,
  Countries.Bulgaria,
  Countries.Croatia,
  Countries.Cyprus,
  Countries.Czechia,
  Countries.Denmark,
  Countries.Estonia,
  Countries.Finland,
  Countries.France,
  Countries.Germany,
  Countries.Greece,
  Countries.Hungary,
  Countries.Ireland,
  Countries.Italy,
  Countries.Latvia,
  Countries.Lithuania,
  Countries.Luxembourg,
  Countries.Malta,
  Countries.Netherlands,
  Countries.Poland,
  Countries.Portugal,
  Countries.Romania,
  Countries.Slovakia,
  Countries.Slovenia,
  Countries.Spain,
  Countries.Sweden,
];

export const NORTH_AMERICA_COUNTRIES = [
  Countries.UnitedStates,
  Countries.Canada,
];

export const COUNTRIES_WITH_REQUIRED_REGISTRATION = [
  Countries.Austria,
  Countries.Belgium,
  Countries.Switzerland,
  Countries.Germany,
  Countries.Iceland,
  Countries.Italy,
  Countries.Netherlands,
  Countries.Portugal,
  Countries.Spain,
] as const;

export type CountriesWithRequiredRegistration =
  (typeof COUNTRIES_WITH_REQUIRED_REGISTRATION)[number];

export function isSupportedCountry(countryCode: string | null | undefined) {
  const value = (countryCode?.toUpperCase() ?? '') as Countries;

  return SUPPORTED_COUNTRIES.includes(value);
}

export function isNorthAmericanCountry(countryCode: string | null | undefined) {
  const value = (countryCode?.toUpperCase() ?? '') as Countries;

  return NORTH_AMERICA_COUNTRIES.includes(value);
}

export function isRegistrationRequiredCountry(countryCode: string) {
  const value = countryCode.toUpperCase() as CountriesWithRequiredRegistration;

  return COUNTRIES_WITH_REQUIRED_REGISTRATION.includes(value);
}

const DAC7_COUNTRIES = [...EUROPEAN_UNION_COUNTRIES];

/**
 * @param countryCode country code for listing if possible otherwise landlord country of residence
 */
export function isDAC7Country(countryCode: string | null | undefined) {
  const value = (countryCode?.toUpperCase() ?? '') as Countries;

  return DAC7_COUNTRIES.includes(value);
}
