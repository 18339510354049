import { Conversation } from './types/Conversation';
import { MakeCall } from './createMakeCall';
import { ConversationSpecialOfferPayload } from './types/ConversationSpecialOfferPayload';

/**
 * @deprecated
 */
export interface Payload {
  price?: number;
  startDate: string;
  endDate: string;
}

const makeSpecialOffer =
  (makeCall: MakeCall) =>
  (conversationId: number, data: ConversationSpecialOfferPayload | Payload) =>
    makeCall<Conversation>(
      'PUT',
      `/api/v2/conversation/${conversationId}/special-offer`,
      { data },
    );
export { makeSpecialOffer };
