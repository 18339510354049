import { MakeCall } from './createMakeCall';

export type UserIdType = number | 'me';

export interface Payload {
  templateUuid: string;
  quickReplyChannel?: 'talkPageModal' | 'settingsPage' | string;
}

const deleteQuickReply =
  (makeCall: MakeCall) =>
  (userId: UserIdType = 'me', data: Payload) =>
    makeCall(
      'DELETE',
      `/api/v2/conversations/user/${userId}/quick-reply-template`,
      {
        data,
      },
    );
export { deleteQuickReply };
