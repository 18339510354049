import { MakeCall } from './createMakeCall';

import {
  GetEngagementsQuery,
  GetEngagementsResponse,
} from './types/Engagement';

type UserId = number | 'me';

const getEngagements =
  (makeCall: MakeCall) =>
  (userId: UserId = 'me', query?: GetEngagementsQuery) =>
    makeCall<GetEngagementsResponse>('GET', `/api/v2/user/${userId}/tenants`, {
      query,
    });
export { getEngagements };
