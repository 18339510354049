import { affiliatePartners } from '../affiliatePartners';
import { AffiliatePartner, AffiliationSource } from '../types';

export const trackAffiliate = (url: URL) => {
  let affiliate: Required<AffiliationSource> | undefined;

  if (!url.search) {
    return affiliate;
  }

  affiliatePartners.some((affiliatePartner: AffiliatePartner) => {
    if (!affiliatePartner.shouldBeTracked(url)) {
      return false;
    }

    const { provider = affiliatePartner.key, clickId } =
      affiliatePartner.getSource(url);

    affiliate = {
      provider,
      clickId,
    };
    return true;
  });

  return affiliate;
};
