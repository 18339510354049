import { MakeCall, MakeCallPromise } from './createMakeCall';
import { MyUserInfoApiV2, UserInformation } from './types/UserInformation';

export interface Query {
  // This will force the request to reach the write replica. In case of doubt, don't use it.
  initial?: boolean;
  expand?: string;
  userIP?: string;
}

const getUserInformation = (makeCall: MakeCall) => {
  function getUserInformationCall(
    id: 'me',
    query?: Query,
  ): MakeCallPromise<MyUserInfoApiV2>;
  function getUserInformationCall(
    id: number | 'me',
    query?: Query,
  ): MakeCallPromise<UserInformation>;
  function getUserInformationCall(
    id: number | 'me' = 'me',
    { userIP, ...rest }: Query = {},
  ) {
    return makeCall('GET', `/api/v2/user/${id}/information`, {
      query: { ...rest },
      ...(userIP && {
        headers: {
          'X-Forwarded-For': userIP,
        },
      }),
    });
  }

  return getUserInformationCall;
};

export { getUserInformation };
