import { MakeCall } from '../createMakeCall';

import { UnitType } from '../types/inventory/UnitType';

export interface Query {
  minimumStayMonths?: number;
}

export type UnitTypeBookablePeriodsResponse = Required<
  Pick<UnitType, 'bookablePeriods' | 'minimumStayMonths' | 'maxBookableDays'>
> & {
  count: number;
};

const getUnitTypeBookablePeriods =
  (makeCall: MakeCall) => (unitTypeId: number, query?: Query) =>
    makeCall<UnitTypeBookablePeriodsResponse>(
      'GET',
      `/api/v2/unit-type/${unitTypeId}/bookable-periods`,
      {
        query,
      },
    );
export { getUnitTypeBookablePeriods };
