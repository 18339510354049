import { defineKey as T } from '@ha/intl';

export const TABLE_COLUMN_WIDTHS = {
  tcActions: {
    minWidth: 120,
  },
  tcTitle: {
    minWidth: 120,
    whiteSpace: 'pre' as const,
    width: '20%',
    wordBreak: 'break-word' as const,
  },
  tcFrequency: {
    minWidth: 130,
  },
  tcPayoutRoute: {
    maxWidth: 120,
    minWidth: 130,
  },
  tcCategory: {
    minWidth: 100,
  },
  tcDate: {
    width: '10%',
    minWidth: 120,
  },
  tcAmount: {
    width: '10%',
    minWidth: 100,
  },
};

export const STATE_NAME = 'paymentPlan';
export const ALERT_INTERVAL = 3000;
export const AMOUNTS = { initial: 100, maximum: 10000, minimum: 1 };
export const FORM_NAME = 'paymentPlanPaymentRequestForm';

export const paymentPlanRequestErrors = {
  1: T('payments.payment_request_load_error.generic'),
  2: T('payments.payment_request_load_error.plan_not_accepted'),
  3: T('payments.payment_request_load_error.listing_not_available'),
};
