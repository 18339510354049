import { MakeCall } from './createMakeCall';

type PoiDraftResponse = {
  id: string;
};

const addPoiDraft =
  (makeCall: MakeCall) =>
  (data: {
    city: string;
    countryCode: string;
    description: string;
    name: string;
  }) =>
    makeCall<PoiDraftResponse>('POST', `/api/v2/poi`, {
      data,
    });
export { addPoiDraft };
