import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getListingsSupportedCountries } from '../selectors';
import { fetchListingsSupportedCountries } from '../actions';

export const useListingsSupportedCountries = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchListingsSupportedCountries());
  }, [dispatch]);

  return useSelector(getListingsSupportedCountries);
};
