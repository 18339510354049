import { MakeCall } from '@ha/api/v2/createMakeCall';
import { User } from '@ha/api/v2/types/User';
import { Currency } from 'ha/constants/Currencies';

import {
  PaymentRequestCategory,
  PaymentRequestState,
  PaymentRequestStatus,
  PaymentRequestUserType,
} from './types';

export interface PaymentRequest {
  amount: string;
  cancelledAt?: string;
  category: PaymentRequestCategory;
  conversation_id?: number;
  createdAt: string;
  createdBy?: string;
  currency: Currency;
  description?: string;
  dueDate?: string;
  engagement?: PaymentRequestEngagement;
  engagement_uuid?: string;
  handle: string;
  id: string;
  landlord?: User;
  listing?: PaymentRequestListing;
  paidAt?: string;
  payment_plan_id?: string;
  payout_date: string;
  platformUserID: number;
  receiptURL?: string;
  sender?: string;
  senderAvatarURL?: string;
  state: PaymentRequestState;
  status:
    | 'capture_pending'
    | 'capture_validating'
    | 'capture_in_progress'
    | 'captured'
    | 'exported'
    | 'released'
    | 'refunded'
    | 'charged_back'
    | 'export_failed'
    | 'canceled';
  tenant_id?: number;
  tenant_uuid?: string;
  title: string;
  paymentPlan?: {
    accepted: boolean;
    id: string;
    tenantDisplayName: string;
  };
  settlement_ref?: string;
  serviceFeeAmount: string;
  totalAmount: string /** Sum of amount and service fee */;
  invoiceLookupURL?: string;
}

export type PaymentRequestListing = {
  address: string;
  alias?: string;
  city: string;
  country: string;
  id: number;
  imageURL?: string;
  latitude: number;
  longitude: number;
  street: string;
  uuid: string;
  unitTypeID: number;
};

export type PaymentRequestEngagement = {
  moveIn: string;
  moveOut: string;
  uuid: string;
};

export interface CreatePaymentRequestFormData {
  amount: string;
  conversation_id?: number;
  currency: Currency;
  description?: string;
  due_date?: string;
  tenant_id?: number;
  tenant_uuid?: string;
  title: string;
  category?: PaymentRequestCategory;
}

export interface CreatePaymentRequestResponse
  extends Omit<PaymentRequest, 'sender' | 'senderAvatarURL'> {
  tenant_id: number; // 0 if no tenant
  tenant_uuid: string; // empty string if no tenant
  conversation_id: number; // 0 if no conversation
}

export interface PayReqConversationParams {
  conversation_id: number;
  tenant_id: number;
  tenant_uuid: string;
}

export interface PaymentRequestsResponse {
  data: PaymentRequest[];
  hasMore: boolean;
  total_count: number;
}

export interface StripeToken {
  card?: {
    brand: string;
    country: string;
    cvc_check?: string;
    exp_month: number;
    exp_year: number;
    funding: string;
    id: string;
    last4: string;
  };
  client_ip: string;
  created: number;
  id: string;
  livemode: boolean;
  object: string;
  type: string;
  used: boolean;
}

export type IdealSourceStatus =
  | 'source.chargeable'
  | 'source.failed'
  | 'source.canceled'
  | 'custom.created'
  | 'custom.succeeded'
  | 'custom.failed'
  | 'charge.succeeded';

export const getPaymentRequests =
  (makeCall: MakeCall) =>
  (filters?: {
    limit?: number;
    offset?: number;
    state?: PaymentRequestStatus;
    user_type?: PaymentRequestUserType;
  }) =>
    makeCall<PaymentRequestsResponse>('GET', '/api/payments/requests/me/v1', {
      query: {
        limit: 20,
        user_type: 'author',
        ...filters,
      },
    });

export const getPaymentRequestByHandle =
  (makeCall: MakeCall) => (handle: string) =>
    makeCall<PaymentRequest>('GET', `/api/payments/requests/v1/${handle}`);

export const cancelPaymentRequest = (makeCall: MakeCall) => (handle: string) =>
  makeCall<undefined>('DELETE', `/api/payments/requests/v1/${handle}`);

export type GetPaymentRequests = ReturnType<typeof getPaymentRequests>;
export type GetPaymentRequestByHandle = ReturnType<
  typeof getPaymentRequestByHandle
>;

export type CancelPaymentRequest = ReturnType<typeof cancelPaymentRequest>;
