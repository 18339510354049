import { MakeCall } from './createMakeCall';

export interface SupportedCurrencies {
  transactionCurrencies: string[];
}

export interface SupportedPayoutCurrencies {
  payoutCurrencies: string[];
  supportedCurrencies: Record<string, string>;
}

const getSupportedMultiPayoutCurrencies =
  (makeCall: MakeCall) =>
  (countryCode: string | undefined, payoutMethod: boolean) =>
    makeCall<SupportedPayoutCurrencies>(
      'GET',
      `/api/v2/payment/configuration/payout-currencies?payoutMethod=${payoutMethod}&countryCode=${countryCode}`,
    );
export { getSupportedMultiPayoutCurrencies };
