import { MakeCall } from './createMakeCall';
import { UserSearch } from './types/UserSearch';

export type UserSearchPayload = Partial<UserSearch>;

export type SaveSearchCall = (
  id: number,
  data: UserSearchPayload,
) => Promise<{ data: UserSearch }>;

const saveSearch =
  (makeCall: MakeCall) => (id: number | 'me', data: UserSearchPayload) =>
    makeCall<UserSearch>('POST', `/api/v2/user/${id}/search`, {
      data,
    });
export { saveSearch };
