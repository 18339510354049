import { UVIVERSITIES } from '../constants';

// eslint-disable-next-line complexity
export function getUtmSource(url: URL, ref: string) {
  let re;
  let result;
  let source;
  let medium;

  // TODO: refactor
  // eslint-disable-next-line prefer-regex-literals
  re = new RegExp('[?&]utm_source=([^&#]*)(&|#|$)', 'i');
  result = re.exec(url.search);

  if (result !== null && result[1] !== '') {
    source = result[1]; // eslint-disable-line prefer-destructuring
  }

  // TODO: refactor
  // eslint-disable-next-line prefer-regex-literals
  re = new RegExp('[?&]utm_medium=([^&#]*)(&|#|$)', 'i');
  result = re.exec(url.search);

  if (result !== null && result[1] !== '') {
    medium = result[1]; // eslint-disable-line prefer-destructuring
  }

  if (source !== undefined || medium !== undefined) {
    return `${!source ? '' : source}${source && medium ? ' / ' : ''}${
      !medium ? '' : medium
    }`;
  }

  if (ref === '' || ref.search(/https?:\/\/(.*)housinganywhere.com/) === 0) {
    return 'Direct';
  }

  if (
    ref.search(/https?:\/\/(.*)google.([^/?]*)/) === 0 ||
    ref.search(/(.*)com.google.android.googlequicksearchbox(.*)/) === 0 ||
    ref.search(/(.*)com.google.android.gm(.*)/) === 0
  ) {
    return 'Google';
  }

  if (
    ref.search(/https?:\/\/(.*)yahoo.([^/?]*)/) === 0 ||
    ref.search(/https?:\/\/(.*)yandex.([^/?]*)/) === 0 ||
    ref.search(/https?:\/\/(.*)ecosia.org/) === 0 ||
    ref.search(/https?:\/\/(.*)duckduckgo.([^/?]*)/) === 0
  ) {
    return 'Other search';
  }

  if (
    ref.search(/https?:\/\/(.*)studyinsweden.se/) === 0 ||
    ref.search(/https?:\/\/(.*)studyindenmark.dk/) === 0 ||
    ref.search(/https?:\/\/(.*)studyintorino.it/) === 0 ||
    ref.search(/https?:\/\/(.*)iamsterdam.com/) === 0 ||
    ref.search(/https?:\/\/(.*)educationinireland.com/) === 0 ||
    ref.search(/https?:\/\/(.*)studyinfinland.fi/) === 0 ||
    ref.search(/https?:\/\/(.*)fyidenmark.com/) === 0 ||
    ref.search(/https?:\/\/(.*)studyinaustria.at/) === 0
  ) {
    return 'Portals';
  }

  if (ref.search(/https?:\/\/(.*)bing\.([^/?]*)/) === 0) {
    return 'Bing';
  }

  if (ref.search(/https?:\/\/(.*)baidu.([^/?]*)/) === 0) {
    return 'Baidu';
  }

  if (ref.search(/https?:\/\/(.*)youtube.com/) === 0) {
    return 'Youtube';
  }

  if (ref.search(/https?:\/\/(.*)live.com/) === 0) {
    return 'Email';
  }

  if (
    ref.search(/https?:\/\/(.*)justlanded.([^/?]*)/) === 0 ||
    ref.search(/https?:\/\/(.*)trovit.([^/?]*)/) === 0
  ) {
    return 'Feeds';
  }

  if (ref.search(/https?:\/\/(.*)nestpick.([^/?]*)/) === 0) {
    return 'Nestpick';
  }

  if (ref.search(/([a-z-]+):\/\/(.*).facebook.(.*)/) === 0) {
    return 'Facebook';
  }

  if (ref.search(/https?:\/\/(.*)twitter.([^/?]*)/) === 0) {
    return 'Twitter';
  }

  if (ref.search(/https?:\/\/(.*)kamernet.([^/?]*)/) === 0) {
    return 'Kamernet';
  }

  if (ref.includes('.edu')) return 'University domains';

  for (let i = 0; i < UVIVERSITIES.length; i += 1) {
    if (ref.search(`https?://(.*)${UVIVERSITIES[i]}/`) === 0) {
      return 'University domains';
    }
  }

  return 'Other';
}
