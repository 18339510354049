import type { MakeCall } from '../createMakeCall';
import { Money } from '../types/common';

import { PayInMethod, PayInResource } from './types';

export type Payload = {
  /** Use only when you don't have uuid  */
  payinMethodType?: PayInMethod;
  /** Use only when you don't have uuid  */
  payinMethodTypes?: PayInMethod[];
  payinMethodUuid?: string; // use when you have uuid
  settlementReference: string;
  value: Money;
};

export type Response = PayInResource;

export const createPayIn = (makeCall: MakeCall) => (data: Payload) =>
  makeCall<Response>('POST', `/api/v2/payment/pay-in`, { data });
