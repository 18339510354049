interface State {
  form: {};
  authLogic: {};
}

const sanitizeState = ({ form, ...rest }: State) => ({
  ...rest,
  authLogic: {
    ...rest.authLogic,
    user: null,
  },
});
export { sanitizeState };
