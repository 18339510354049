import { MakeCall } from './createMakeCall';

const cancelSubscription =
  (makeCall: MakeCall) => (subscriptionId: number | string) =>
    makeCall<undefined>(
      'DELETE',
      `/api/v2/billing/subscription/${subscriptionId}`,
    );

export { cancelSubscription };
