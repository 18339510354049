import { UnitType } from '../types/inventory/UnitType';
import { MakeCall } from '../createMakeCall';

export interface DuplicateUnitTypePayload {
  asCharitable?: boolean;
}

const duplicateUnitType =
  (makeCall: MakeCall) =>
  (unitTypeId: number, data: DuplicateUnitTypePayload = {}) => {
    return makeCall<UnitType>('POST', `/api/v2/unit-type/${unitTypeId}/copy`, {
      data,
    });
  };

export { duplicateUnitType };
