import { MakeCall } from './createMakeCall';
import {
  PropertyRegistrationInfoPayload,
  PropertyRegistrationInfo,
} from './types/PropertyRegistrationInfo';

/**
 * Handles updating Land  registration details   (compliance)
 * @returns land registration updated details response
 */

export const updatePropertyRegistrationInfo =
  (makeCall: MakeCall) =>
  (
    payload: Pick<PropertyRegistrationInfoPayload, 'landRegistrationNumber'>,
    query: Partial<PropertyRegistrationInfoPayload>,
  ) =>
    makeCall<PropertyRegistrationInfo>('PUT', `/api/v2/property-info`, {
      query,
      data: payload,
    });
