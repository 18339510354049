import type { MakeCall } from '../createMakeCall';

export interface UserBillingData {
  vat: string;
  isVatVerified: boolean;
  company: string;
  countryCode: string;
  city: string;
  state: string;
  zip: string;
  address: string;
  address2: string;
  firstName: string;
  lastName: string;
  type: string;
}

export type GetBillingInformation = (
  userId: number,
) => Promise<{ data: UserBillingData }>;

const getBillingInformation = (makeCall: MakeCall) => (userId: number) =>
  makeCall<UserBillingData>(
    'GET',
    `/api/v2/user/${userId}/billing-details`,
  ).then(res => ({
    ...res,
    data: {
      ...res.data,
      countryCode: (res.data.countryCode || '').toUpperCase(),
    },
  }));
export { getBillingInformation };
