import React from 'react';

import { ErrorBoundary } from './ErrorBoundary';

const AppRoot: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = global.document.getElementById('jss-server-side');

    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  return (
    <ErrorBoundary>
      <React.StrictMode>{children}</React.StrictMode>
    </ErrorBoundary>
  );
};

export { AppRoot };
