import { MakeCall } from './createMakeCall';
import {
  PropertyRegistrationInfoPayload,
  PropertyRegistrationInfo,
} from './types/PropertyRegistrationInfo';

/**
 * Handles creating Land  registration details   (compliance)
 * @returns land registration details response
 */

export const createPropertyRegistrationInfo =
  (makeCall: MakeCall) => (payload: PropertyRegistrationInfoPayload) =>
    makeCall<PropertyRegistrationInfo>('POST', `/api/v2/property-info`, {
      data: payload,
    });
