import { FilterBuilder } from '../Types';

const partnerBuilder: FilterBuilder = (_params, context) => {
  // use filter scoring to show housinganywhere listings first
  return context?.withPartnerSorting
    ? '(isPartner:false<score=2> OR isPartner:true<score=1>)'
    : '';
};

export { partnerBuilder };
