import { GlobalState } from 'ha/types/store';
import { FetchStatus } from '@ha/api/v2/errors/FetchStatus';

import { STATE_NAME } from '../constants';

export const getListingsSupportedCountriesFetchStatus = (
  state: GlobalState,
): FetchStatus => state[STATE_NAME].supportedCountriesFetchStatus;

export const getListingsSupportedCurrenciesFetchStatus = (
  state: GlobalState,
): FetchStatus => state[STATE_NAME].supportedCurrenciesFetchStatus;

export const getListingsSupportedCountries = (state: GlobalState): string[] =>
  state[STATE_NAME].supportedCountries;

export const getListingsSupportedCurrencies = (state: GlobalState): string[] =>
  state[STATE_NAME].supportedCurrencies;

export const getSupportedResidenceCountries = (state: GlobalState): string[] =>
  state[STATE_NAME].supportedResidenceCountries;

export const getSupportedResidenceCountriesFetchStatus = (
  state: GlobalState,
): FetchStatus => state[STATE_NAME].supportedResidenceCountriesFetchStatus;
