import { Language } from '../../../lib/types';

// paths for chinese pages are equal. It is a content bug

export const renting = {
  [Language.en]: '/renting',
  [Language.fr]: '/trouver-logement',
  [Language.de]: '/mieten',
  [Language.es]: '/buscar-alojamiento',
  [Language.it]: '/trova-casa',
  [Language.nl]: '/huren',
  [Language.pt]: '/encontre-um-imovel',
  // [Language.zh]: '/租用',
  [Language.zh]: '/renting',
  [Language.uk]: '/оренда',
  [Language.ru]: '/аренда',
  [Language.pl]: '/znajdz-nieruchomosc',
  [Language.sv]: '/hitta-en-fastighet',
  [Language.ro]: '/gasiti-o-proprietate',
};

export const rentingOut = {
  [Language.en]: '/renting-out',
  [Language.fr]: '/trouver-locataire',
  [Language.de]: '/vermieten',
  [Language.es]: '/buscar-inquilino',
  [Language.it]: '/trova-inquilino',
  [Language.nl]: '/verhuren',
  [Language.pt]: '/alugue-seu-imovel',
  // [Language.zh]: '/租用',
  [Language.zh]: '/renting-out',
  [Language.uk]: '/здача-в-оренду',
  [Language.ru]: '/сдача-в-аренду',
  [Language.pl]: '/wynajmij-swoja-nieruchomosc',
  [Language.sv]: '/hyra-din-egen-fastighet',
  [Language.ro]: '/inchiriati-va-propria-proprietate',
};
