import { MakeCall } from '../createMakeCall';

const deleteUnitTypePhoto =
  (makeCall: MakeCall) => (unitTypeId: number, photoId: number) =>
    makeCall<undefined>(
      'DELETE',
      `/api/v2/unit-type/${unitTypeId}/photo/${photoId}`,
    );

export { deleteUnitTypePhoto };
