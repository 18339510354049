import { FilterBuilder } from '../Types';

const cityBuilder: FilterBuilder = ({ city }) => {
  if (!city) {
    return '';
  }

  return `city:"${city}"`;
};

export { cityBuilder };
