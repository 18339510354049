import { CampaignKeywords } from 'ha/modules/Analytics/constants';

import { ProviderNames, ProviderParams } from './constants';
import { AffiliatePartner } from './types';

// List of trackers. New trackers can be added in this list.
// Trackers are ordered based on priority:
// tracker with lower index has higher priority
export const affiliatePartners: AffiliatePartner[] = [
  {
    key: ProviderNames.Google,
    shouldBeTracked: (url: URL) =>
      url.searchParams.has(ProviderParams.Google) &&
      url.searchParams.get(CampaignKeywords.UtmSource) === ProviderNames.Google,
    getSource: (url: URL) => ({
      clickId: url.searchParams.get(ProviderParams.Google) ?? '',
    }),
  },
  {
    key: ProviderNames.Display,
    shouldBeTracked: (url: URL) =>
      url.searchParams.has(ProviderParams.Google) &&
      url.searchParams.get(CampaignKeywords.UtmSource) ===
        ProviderNames.Display,
    getSource: (url: URL) => ({
      clickId: url.searchParams.get(ProviderParams.Google) ?? '',
    }),
  },
  {
    key: ProviderNames.YouTube,
    shouldBeTracked: (url: URL) =>
      url.searchParams.has(ProviderParams.Google) &&
      url.searchParams.get(CampaignKeywords.UtmSource) ===
        ProviderNames.YouTube,
    getSource: (url: URL) => ({
      clickId: url.searchParams.get(ProviderParams.Google) ?? '',
    }),
  },
  {
    key: ProviderNames.PerformanceMax,
    shouldBeTracked: (url: URL) =>
      url.searchParams.has(ProviderParams.Google) &&
      url.searchParams.get(CampaignKeywords.UtmSource) ===
        ProviderNames.PerformanceMax,
    getSource: (url: URL) => ({
      clickId: url.searchParams.get(ProviderParams.Google) ?? '',
    }),
  },
  {
    key: ProviderNames.Facebook,
    shouldBeTracked: (url: URL) =>
      url.searchParams.has(ProviderParams.Facebook),
    getSource: (url: URL) => ({
      clickId: url.searchParams.get(ProviderParams.Facebook) ?? '',
    }),
  },
  {
    key: ProviderNames.Nestpick,
    shouldBeTracked: (url: URL) =>
      url.searchParams.has(ProviderParams.NestpickTransactionId),
    getSource: (url: URL) => ({
      clickId: url.searchParams.get(ProviderParams.NestpickTransactionId) ?? '',
    }),
  },
  {
    key: ProviderNames.HomeToGo,
    shouldBeTracked: (url: URL) =>
      url.searchParams.has(CampaignKeywords.UtmTerm) &&
      url.searchParams.get(CampaignKeywords.UtmSource) ===
        ProviderNames.HomeToGo,
    getSource: (url: URL) => ({
      clickId: url.searchParams.get(CampaignKeywords.UtmTerm) ?? '',
    }),
  },
  {
    key: ProviderNames.Affiliate,
    shouldBeTracked: (url: URL) =>
      url.searchParams.has(ProviderParams.AffiliatePartnerId) &&
      url.searchParams.get(CampaignKeywords.UtmMedium) ===
        ProviderNames.Affiliate,
    getSource: (url: URL) => ({
      provider: url.searchParams.get(CampaignKeywords.UtmSource) ?? '',
      clickId: url.searchParams.get(ProviderParams.AffiliatePartnerId) ?? '',
    }),
  },
];
