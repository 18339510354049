import { ContentBlock, ReadingTime } from './types';

const WORDS_PER_MIN_READING = 250;

function getTimeLength(texts: string[], blocks: ContentBlock[] = []): number {
  const textsLength = texts.reduce(
    (length, text) => length + text.split(' ').length,
    0,
  );

  const blocksLength = blocks
    .map(block => `${block.text} ${block.title}`)
    .reduce((memo, block) => `${memo} ${block}`, '')
    .split(' ').length;

  return Math.floor((textsLength + blocksLength) / WORDS_PER_MIN_READING);
}

export function getReadingTime(
  hideReadingTime: boolean,
  texts: string[],
  blocks: ContentBlock[] = [],
): ReadingTime {
  const timeLength = getTimeLength(texts, blocks);
  const hide = hideReadingTime || timeLength < 1;

  return {
    timeLength,
    hide,
  };
}
