import { CampaignKeywords } from '../constants';

interface KeyWord {
  key: CampaignKeywords;
  value: string;
}

const appendPostfix =
  (postfix: string) => (acc: Record<string, string>, el: KeyWord) => ({
    ...acc,
    [`${el.key}${postfix}`]: el.value,
  });

// https://blog.mixpanel.com/2015/05/11/community-tip-last-touch-utm-tags/
export const getCampaignParams = (searchParams: URLSearchParams) => {
  const keywords = Object.values(CampaignKeywords).flatMap(kw => {
    const value = searchParams.get(kw);
    return value ? [{ key: kw, value }] : [];
  });

  const firstParams = keywords.reduce(appendPostfix(' [first touch]'), {});
  const params = keywords.reduce(appendPostfix(' [last touch]'), {});

  return { firstParams, params };
};
