import { MakeCall } from './createMakeCall';

const toggleArchiveConversation =
  (makeCall: MakeCall) => (conversationID: number, toArchive: boolean) =>
    makeCall<undefined>(
      'PUT',
      `/api/v2/conversation/${conversationID}/${
        toArchive ? 'archive' : 'unarchive'
      }`,
    );
export { toggleArchiveConversation };
