import dayjs from 'dayjs';

/**
 * validates and normalizes date input.
 * @returns string date in YYYY-MM-DD format if input is valid, otherwise empty string.
 */
export function normalizeDate(date: Date | string | null | undefined): string {
  if (!date || !dayjs(date).isValid()) {
    return '';
  }

  return dayjs(date).format('YYYY-MM-DD');
}
