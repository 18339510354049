import { MakeCall } from './createMakeCall';
import { Conversation } from './types/Conversation';

type UserId = number | 'me';
export type ConversationOrder =
  | 'last-message'
  | 'unread-last-message'
  | 'messages-desc'
  | 'created'
  | 'created-desc'
  | 'start-date'
  | 'start-date-desc'
  | 'move-in-date'
  | 'move-in-date-desc'
  | 'move-out-date'
  | 'move-out-date-desc';
export type ConversationStateFilter =
  | 'all'
  | 'archived'
  | 'expired'
  | 'favorited'
  | 'inbox'
  | 'paid'
  | 'paidAndCancelled'
  | 'pending'
  | 'unread';
export type ConversationFilterDate = 'future' | 'current' | 'past';

export interface GetConversationsQuery {
  /**
   * @description comma-separated string with possible values: `stateHistory`, `messages`, `listing`, `advertiser`, `tenant`, `tenancy`, `rentalConditions`,
   */
  expand?: string;
  excludeMoveOutOlderThan?: number;
  asAdvertiser?: boolean;
  state?: ConversationStateFilter;
  limit?: number;
  listingId?: string | number;
  unitTypeId?: string | number;
  asTenant?: boolean;
  offset?: number;
  order?: ConversationOrder;
  text?: string;
  filterDate?: ConversationFilterDate;
  uuids?: string;
  /** enable controlled release starting with Managed Accounts  */
  stateFF?: boolean;
}

export interface ConversationsResponse {
  conversations: Conversation[];
  count: number;
}

const getConversations =
  (makeCall: MakeCall) => (userId: UserId, query?: GetConversationsQuery) =>
    makeCall<ConversationsResponse>(
      'GET',
      `/api/v2/conversations/user/${userId}`,
      {
        query,
      },
    );
export { getConversations };
