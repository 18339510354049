import dayjs, { Dayjs } from 'dayjs';
import { addMonths } from './addMonths';
import { NO_END_DATE } from './constants';

const roundDate = (date: Dayjs) => {
  const day = date.date();

  // If the selected date is between 1-15 days over a month, then we should round down to the nearest month.
  if (day <= 15) {
    return date.set('date', 1);
  }
  // If the selected date is between 16-30 days over a month, the we should round up to the nearest month.
  // if we change it to the last day of the month in duration calculation it returns 1 month less (e.g Jan 1st until March 31 would be 2 months)
  // instead I will use first day of the next month in calculations without changing the endDate
  return date.set('month', date.month() + 1).startOf('month');
};

interface DateRange {
  startDate: string | Date;
  endDate?: string | Date;
}

export interface AvailabilityFilterValue {
  moveIn: {
    type: 'date' | 'month';
    value: string;
  };
  duration?: number;
}

export const dateRangeToAvailability: (
  dateRange: DateRange,
) => AvailabilityFilterValue | undefined = dates => {
  if (!dates) return;
  const { startDate, endDate } = dates;
  const startDateObj = dayjs(startDate);
  const endDateObj = roundDate(dayjs(endDate || NO_END_DATE));

  const duration = endDate
    ? Math.min(endDateObj.diff(roundDate(startDateObj), 'month'), 12) // If the selected dates are more than 12 months apart, we should show the duration as 12 months
    : undefined;

  return {
    moveIn: {
      type:
        startDateObj.daysInMonth() === startDateObj.date() ? 'month' : 'date',
      value: startDateObj.format('YYYY-MM-DD'),
    },
    duration,
  };
};

export const availabilityToDateRage = (
  availability: AvailabilityFilterValue,
) => {
  const startDate = dayjs(availability.moveIn.value).toISOString();
  const endDate = availability.duration
    ? dayjs(addMonths(startDate, availability.duration)).toISOString()
    : undefined;

  return { startDate, endDate };
};
