import {
  Listing,
  ListingCancellationPolicy,
  ListingContractType,
  ListingCostsCosts,
  ListingFacilities,
  ListingPricingType,
  ListingPricingValues,
} from './types/Listing';
import { MakeCall } from './createMakeCall';
import { RequiredDocumentsFormValues } from './types/RequiredDocuments';

export interface UpdateListingPayload {
  address?: string;
  structuredAddress?: {
    countryCode: string;
    city: string;
    postalCode: string;
    street: string;
    houseNumber?: string;
    aptNumber?: string;
  };
  kind?: number;
  type?: number;
  description?: string;
  alias?: string;
  currency?: string;
  price?: number | null;
  maximumStay?: number;
  minimumStayMonths?: number;
  maxBookableDays?: number;
  currentOccupancy?: number;
  freePlaces?: number | null;
  minAge?: number;
  maxAge?: number;
  preferredGender?: string;
  isInstantAllowed?: boolean;
  facilities?: ListingFacilities;
  how?: string;
  icsUrl?: string;
  costs?: ListingCostsCosts;
  contractType?: ListingContractType;
  cancellationPolicy?: ListingCancellationPolicy;
  pricingType?: ListingPricingType;
  pricingValues?: ListingPricingValues;
  isCharitable?: boolean;
}

export interface UpdateListingPayloadWithRequirements
  extends UpdateListingPayload {
  requiredDocuments?: RequiredDocumentsFormValues;
}

export interface Query {
  /**
   * @description comma-separated string with possible values: `exclusions`, `exclusionsExtended`, `bookablePeriods`, `conversationCounters`, `lastMonthConversationCount`, `photos`, `videos`, `costs`
   */
  expand?: string;
  lang?: string;
}

const updateListing =
  (makeCall: MakeCall) =>
  (listingId: number, data: UpdateListingPayload, query?: Query) =>
    makeCall<Listing>('PUT', `/api/v2/listing/${listingId}`, {
      data,
      query,
    });

export { updateListing };
