import React from 'react';
import { reportError } from 'ha/helpers/bugReporter/reportError';
import { LoadableSomethingWentWrong } from './SomethingWentWrong/SomethingWentWrong.lazy';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  state = {
    hasError: false,
  };

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({ hasError: true });
    reportError(error, { metaData: info });
  }

  render() {
    if (this.state.hasError) {
      return <LoadableSomethingWentWrong />;
    }

    return this.props.children;
  }
}
