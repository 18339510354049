import type { MakeCall } from '../createMakeCall';
import { QualificationRequirement } from '../types/qualifications/QualificationRequirement';

const getQualificationRequirements =
  (makeCall: MakeCall) => (requirementId: number | string) =>
    makeCall<QualificationRequirement>(
      'GET',
      `/api/v2/qualification/requirements/${requirementId}`,
    );
export { getQualificationRequirements };
