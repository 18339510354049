import type { MakeCall } from '../createMakeCall';
import { Money } from '../types/common';
import { Settlement, SettlementExternalRefType } from '../types/Settlement';

export type Payload = {
  buyerAccountUuid: string;
  sellerAccountUuid: string;
  externalReferenceType: SettlementExternalRefType;
  externalReference: string;
  purchaseValue: Money;
};

const createSettlement = (makeCall: MakeCall) => (data: Payload) =>
  makeCall<Settlement>('POST', `/api/v2/payment/settlement`, { data });
export { createSettlement };
