import { normalizeDate } from '@ha/date';

import { buildParams } from './buildParams';
import { Kinds, KindsEnum } from './constants/RoomKind';
import { isDefined } from './utils/isDefined';

const getEnumsByValues = (
  values: Array<number>,
  types: typeof Kinds,
  typesEnum: typeof KindsEnum,
) =>
  Object.entries(types)
    .filter(pair => values.includes(pair[1]))
    .map(([type]) => typesEnum[type as keyof typeof Kinds])
    .join(',');

interface ComplexSearchFilters {
  startDate?: Date | string | null;
  endDate?: Date | string | null;
  listingKinds?: Array<number>;
  listingCategories?: Array<string>;
  [key: string]: unknown;
}

export function buildSearchQuery(filters: ComplexSearchFilters = {}): string {
  const {
    listingKinds = [],
    listingCategories = [],
    startDate,
    endDate,
  } = filters;
  const complexFilterKeys = [
    'listingKinds',
    'listingCategories',
    'startDate',
    'endDate',
  ];

  const query: Record<string, string> = Object.keys(filters)
    .filter(key => !complexFilterKeys.includes(key))
    .filter(key => isDefined(filters[key]))
    .reduce((acc, key) => ({ ...acc, [key]: filters[key] }), {});

  if (startDate) {
    query.startDate = normalizeDate(startDate);
  }

  if (endDate) {
    query.endDate = normalizeDate(endDate);
  }

  if (listingKinds.length > 0) {
    query.kinds = getEnumsByValues(listingKinds, Kinds, KindsEnum);
  }

  const categories = listingCategories.reduce(
    (acc, category) => `${acc}&categories=${category}`,
    '',
  );

  const params = buildParams(query);

  return categories ? `${params}${categories}` : params;
}
