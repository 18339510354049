import { ApiError, RequestError } from './errors';
import { handleApiV2Response } from './handleApiV2Response';

export interface FetchClient {
  fetch: (url: string, options: RequestInit) => Promise<Response>;
}

interface Options {
  data?: any;
  query?: any;
  headers?: any;
  signal?: AbortSignal | null;
  keepalive?: boolean;
}

export type MakeCallPromise<T> = Promise<{
  status: number;
  data: T;
  headers?: Headers;
}>;

export type MakeCall = <T>(
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  pathname: string,
  options?: Options,
) => MakeCallPromise<T>;

type CreateMakeCall = (fetchClient: FetchClient) => MakeCall;

export const createMakeCall: CreateMakeCall =
  fetchClient =>
  (method, pathname, options = {}) => {
    const { data = {}, query = {}, headers = {}, signal, keepalive } = options;

    let body;

    const hasData = ['POST', 'PUT', 'PATCH', 'DELETE'].includes(method) && data;

    if (
      hasData &&
      headers['Content-Type'] &&
      headers['Content-Type'].includes('multipart/form-data')
    ) {
      const formData = new FormData();

      for (const key of Object.keys(data)) {
        const value = data[key];
        if (typeof value !== 'undefined') {
          if (value instanceof Blob) {
            formData.append(key, value);
          } else {
            formData.append(key, JSON.stringify(value));
          }
        }
      }

      body = formData;

      // This is a work-around for more details please check
      // - https://stackoverflow.com/q/39280438/1132405#comment73780091_39281156
      // - https://stackoverflow.com/a/33809474/1132405
      delete headers['Content-Type']; // eslint-disable-line no-param-reassign
    } else if (hasData) {
      body = JSON.stringify(data);
      headers['Content-Type'] = 'application/json'; // eslint-disable-line no-param-reassign
    } else {
      headers['Content-Type'] = 'application/json'; // eslint-disable-line no-param-reassign
    }

    const searchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(query)) {
      if (typeof value !== 'undefined') {
        searchParams.append(key, value as string);
      }
    }

    const searchQuery =
      searchParams.toString() === '' ? '' : `?${searchParams.toString()}`;

    return fetchClient
      .fetch(`${pathname}${searchQuery}`, {
        method,
        body,
        headers: {
          Accept: 'application/json',
          ...headers,
        },
        signal,
        keepalive,
      })
      .then(handleApiV2Response)
      .catch((error: ApiError) => {
        throw new RequestError(
          error.status,
          `${error.message}: ${pathname}`,
          error,
          { response: error.response, headers: error.headers },
        );
      });
  };
