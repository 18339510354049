import {
  AdvertiserOverallRatingResponse,
  GetAdvertiserOverallRatingQuery,
} from '../types/surveyReviews/AdvertiserOverallRating';
import type { MakeCall } from '../createMakeCall';

const getReviewAdvertiserOverallRating =
  (makeCall: MakeCall) => (query: GetAdvertiserOverallRatingQuery) =>
    makeCall<AdvertiserOverallRatingResponse>(
      'GET',
      '/api/v2/review/landlord/overall-rating',
      { query },
    );

export { getReviewAdvertiserOverallRating };
