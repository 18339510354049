import type { MakeCall } from '../createMakeCall';
import { Currency } from '../types/Currencies';
import { CommaSeparatedString } from '../types/helpers';
import { PayoutTypes } from '../types/PayoutMethods';

export enum PayoutTransactionStatus {
  ALL = 'all',
  UPCOMING = 'upcoming',
  ON_HOLD = 'on-hold',
  PAID = 'paid',
  BOUNCED = 'bounced',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
}

export interface Transaction {
  id: number;
  amount: number;
  currency: Currency;
  status: PayoutTransactionStatus;
  settlementReference: string;
  type: number;
  actualPayoutDate?: string;
  additionalInformation?: string;
  expectedPayoutDate: string;
  // TODO: change the optional type to required after remove the getPayoutTransactions endpoint
  isPayoutMethodChangeable?: boolean;
  engagement?: {
    uuid: string;
    startDate: string;
    endDate: string;
    type: string;
    conversationId: number;
  };
  invoice?: {
    number: string;
    url: string;
  };
  payoutMethod?: {
    guid: string;
    accountDetails: string;
    accountName: string;
    accountType: PayoutTypes;
    // TODO: change the optional type to required after remove the getPayoutTransactions endpoint
    payengPayoutMethodUuid?: string;
  };
  tenant?: {
    id: number;
    uuid: string;
    firstName: string;
    lastName: string;
  };
}

export type GetPayoutTransactionsResponse = {
  payouts: Transaction[];
  totalCount: number;
};

export type GetPayoutTransactionsQuery = {
  fromDate?: string;
  limit?: number;
  offset?: number;
  status?: CommaSeparatedString<PayoutTransactionStatus>;
  excludeStatus?: CommaSeparatedString<PayoutTransactionStatus>;
  tenancy?: string;
  toDate?: string;
  type?: number;
  settlementReference?: string;
  orderBy: string;
};

const getPayout = (makeCall: MakeCall) => (query: GetPayoutTransactionsQuery) =>
  makeCall<GetPayoutTransactionsResponse>('GET', `/api/v2/payout`, {
    query,
  });

export { getPayout };
