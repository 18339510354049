export enum Language {
  en = 'en',
  fr = 'fr',
  de = 'de',
  es = 'es',
  it = 'it',
  nl = 'nl',
  pt = 'pt',
  zh = 'zh',
  uk = 'uk',
  ru = 'ru',
  pl = 'pl',
  sv = 'sv',
  ro = 'ro',
}
