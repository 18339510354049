import type { MakeCall } from '../createMakeCall';

export interface CommissionInvoice {
  number: string;
  url: string;
}

const getConversationCommissionInvoice =
  (makeCall: MakeCall) => (userId: number, conversationId: number) =>
    makeCall<CommissionInvoice>(
      'GET',
      `/api/v2/user/${userId}/conversation/${conversationId}/commission-invoice`,
    );

export { getConversationCommissionInvoice };
