import { MakeCall } from './createMakeCall';
import { ListingExclusion } from './types/Listing';

export interface Query {
  rangeFrom?: string;
  rangeTo?: string;
}

export interface ListingExclusionsResponse {
  rangeFrom: string;
  rangeTo: string;
  dates: ListingExclusion[];
}

const getListingExclusions =
  (makeCall: MakeCall) =>
  (listingId: number, { rangeFrom, rangeTo }: Query = {}) =>
    makeCall<ListingExclusionsResponse>(
      'GET',
      `/api/v2/listing/${listingId}/exclusions`,
      {
        query: { rangeFrom, rangeTo },
      },
    );
export { getListingExclusions };
