import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';

export enum ActionTypes {
  NEW_TAB = 'NEW_TAB_HANDLER/OPEN_IN_NEW_TAB',
}

export const Actions = {
  open: (url: string) => createAction(ActionTypes.NEW_TAB, { url }),
};

export type ActionsType = ActionsUnion<typeof Actions>;
