/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';

import {
  filterBuilder,
  getBoundaryCoordinates,
  normalizeListing,
  NormalizedListing,
  AlgoliaListing,
} from '@ha/algolia';
import { City } from '@ha/api/v2/types/City';
import { Language } from '@ha/intl';

import { CanonicalCollections } from 'ha/constants/SearchPageCollections';

import { Thunk } from 'ha/myredux/types';
import { collectionToFilters } from 'ha/utils/filters/collectionToFilters';

import { RootState } from './types';

export const ACTION_PREFIX = 'CITIES';
export enum ActionTypes {
  START = 'CITIES/LOAD_CITIES_START',
  SUCCESS = 'CITIES/LOAD_CITIES_SUCCESS',
  FAIL = 'CITIES/LOAD_CITIES_FAIL',
}

type SuccessPayload = {
  pathname: string;

  langURLList: { [k: string]: string };

  cityLocalized: string;
  cityCanonical: string;
  countryLocalized: string;
  countryCanonical: string;
  countryCode: string;

  citiesFeatured: City[];

  listingsCount: number;
  listings: NormalizedListing<AlgoliaListing>[];
  apartments: NormalizedListing<AlgoliaListing>[];
  studios: NormalizedListing<AlgoliaListing>[];
  privateRooms: NormalizedListing<AlgoliaListing>[];
  sharedRooms: NormalizedListing<AlgoliaListing>[];
};

export const Actions = {
  start: () => createAction(ActionTypes.START),
  success: (data: SuccessPayload) => createAction(ActionTypes.SUCCESS, data),
  fail: () => createAction(ActionTypes.FAIL),
};

export type ActionsType = ActionsUnion<typeof Actions>;

function getAlgoliaQuery(filters: string, boundaryCoordinates: number[]) {
  return {
    query: '',
    params: {
      distinct: true,
      attributesToHighlight: [],
      page: 0,
      hitsPerPage: 4,
      filters,
      insideBoundingBox: [boundaryCoordinates],
    },
  };
}

const algoliaFilterBuilder = filterBuilder();

const loadCities: ({
  baseDomain,
  lang,
  params,
}: {
  baseDomain: string;
  lang: string;
  params: { city: string; country: string };
}) => Thunk<RootState, void> =
  ({ baseDomain, lang, params: { city, country } }) =>
  async (dispatch, _, services) => {
    try {
      dispatch(Actions.start());

      const { algolia, apiV2 } = services;
      const pathname = `${city}--${country}`;

      const [cityData, euroRatesResponse, featuredCitiesResponse] =
        await Promise.all([
          services.apiV2.getCities({
            list: [{ query: `${city}, ${country}` }],
            languages: Object.values(Language),
          }),
          apiV2.getCurrencyEuroRates(),
          apiV2.getFeaturedCities({
            exclude: pathname,
          }),
        ]);

      const currentCity = cityData.data[lang][0];
      const fullInfo = {
        city: currentCity.cityCanonical,
        cityLocalized: currentCity.cityName,
        country: currentCity.countryCanonical,
        countryCode: currentCity.countryCode.toLowerCase(),
        countryLocalized: currentCity.countryName,
        currency: currentCity.currency,
        langURLList: Object.keys(cityData.data).reduce(
          (accumulator, languageCode) => ({
            ...accumulator,
            [languageCode]: `${baseDomain}${cityData.data[languageCode][0].searchUrl}`,
          }),
          {},
        ),
        latitude: currentCity.latitude,
        longitude: currentCity.longitude,
        radius: currentCity.mapRadius,
      };

      const boundaryCoordinates = getBoundaryCoordinates(
        fullInfo.latitude,
        fullInfo.longitude,
        fullInfo.radius / 1000,
      );

      const stats = {
        averagePrice: 50000,
      };

      const apartmentsFilters = algoliaFilterBuilder(
        collectionToFilters(CanonicalCollections.ApartmentForRent, stats),
      );
      const studiosFilters = algoliaFilterBuilder(
        collectionToFilters(CanonicalCollections.StudioForRent, stats),
      );
      const privateRoomsFilters = algoliaFilterBuilder(
        collectionToFilters(CanonicalCollections.PrivateRoomForRent, stats),
      );
      const sharedRoomsFilters = algoliaFilterBuilder(
        collectionToFilters(CanonicalCollections.SharedRoomForRent, stats),
      );

      const searchResponse = fullInfo;

      const listingsResponse = await algolia.searchListings([
        getAlgoliaQuery('isSearchable:true', boundaryCoordinates),
        getAlgoliaQuery(apartmentsFilters, boundaryCoordinates),
        getAlgoliaQuery(studiosFilters, boundaryCoordinates),
        getAlgoliaQuery(privateRoomsFilters, boundaryCoordinates),
        getAlgoliaQuery(sharedRoomsFilters, boundaryCoordinates),
      ]);

      const currencyEuroRates = euroRatesResponse.data.rates;
      const citiesFeatured = featuredCitiesResponse.data.cities;
      const [
        listingsWithoutFilters,
        apartments,
        studios,
        privateRooms,
        sharedRooms,
      ] = listingsResponse.results;

      const currencyRate = currencyEuroRates[searchResponse.currency];

      const normalizeListingCb = normalizeListing({
        currency: searchResponse.currency,
        currencyRate,
      });

      dispatch(
        Actions.success({
          pathname,

          langURLList: searchResponse.langURLList,
          cityLocalized: searchResponse.cityLocalized,
          cityCanonical: searchResponse.city,
          countryLocalized: searchResponse.countryLocalized,
          countryCanonical: searchResponse.country,
          countryCode: searchResponse.countryCode,

          citiesFeatured,

          listingsCount: listingsWithoutFilters.nbHits,
          listings: listingsWithoutFilters.hits.map(normalizeListingCb),
          apartments: apartments.hits.map(normalizeListingCb),
          studios: studios.hits.map(normalizeListingCb),
          privateRooms: privateRooms.hits.map(normalizeListingCb),
          sharedRooms: sharedRooms.hits.map(normalizeListingCb),
        }),
      );
    } catch (e) {
      dispatch(Actions.fail());
    }
  };

export { loadCities };
