import { MakeCall } from './createMakeCall';
import { QuickReplies } from './types/QuickReplies';

const getQuickReplies =
  (makeCall: MakeCall) =>
  (userId = 'me') =>
    makeCall<QuickReplies>(
      'GET',
      `/api/v2/conversations/user/${userId}/quick-reply-template`,
    );
export { getQuickReplies };
