import { MakeCall } from './createMakeCall';

import { Listing } from './types/Listing';

export interface Query {
  minimumStayMonths?: number;
}

export type ListingBookablePeriodsResponse = Required<
  Pick<Listing, 'bookablePeriods' | 'minimumStayMonths' | 'maxBookableDays'>
> & {
  count: number;
};

const getListingBookablePeriods =
  (makeCall: MakeCall) => (listingId: number, query?: Query) =>
    makeCall<ListingBookablePeriodsResponse>(
      'GET',
      `/api/v2/listing/${listingId}/bookable-periods`,
      {
        query,
      },
    );
export { getListingBookablePeriods };
