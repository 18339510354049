import { combineReducers } from 'redux';

import { GlobalState } from 'ha/types/store';

import { product } from './product';

export const STATE_NAME = 'subscriptionLanding';

const reducer = combineReducers({
  product,
});

export interface RootState extends GlobalState {
  [STATE_NAME]: ReturnType<typeof reducer>;
}

export { reducer };
