import type { MakeCall } from '../createMakeCall';

import { PayInTypeResource } from './types';

export const cancelPayIn =
  (makeCall: MakeCall) => (UUID: string, settlementReference: string) =>
    makeCall<PayInTypeResource>(
      'PUT',
      `/api/v2/payment/pay-in/${UUID}/cancel`,
      { data: { settlementReference } },
    );
