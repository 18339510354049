export const numberOfRooms = [
  '',
  '1',
  '2-5',
  '6-10',
  '11-30',
  '31-100',
  '101-500',
  '>500',
];
