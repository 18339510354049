export enum PagesType {
  ABOUT = 'About',
  BLOG = 'Blog',
  CITIES = 'Cities',
  COMMUNITY = 'Community',
  COUNTRY = 'Country',
  FEATURE = 'Feature',
  GUIDE = 'Guide',
  HELP_CENTER = 'HelpCenter',
  HOME = 'Home',
  HOW_TO = 'HowTo',
  LANDLORD_BLOG = 'LandlordBlog',
  PRIVATE_LANDLORD_LANDING = 'PrivateLandlordLanding',
  PAYMENTS = 'Payments',
  PRESS = 'Press',
  SEARCH = 'Search',
  SUPPLY_LANDING = 'SupplyLanding',
  TENANT_BLOG = 'TenantBlog',
  UNIVERSITY = 'University',
  UNIVERSITY_SIGNUP = 'UniversitySignUp',
}

export enum TenantBlogPages {
  GLOBAL = 'Global',
  COUNTRY = 'Country',
  CITY = 'City',
}

export enum BlogSlugs {
  Homepage = 'blog',
  Tenants = 'tenants',
  Landlords = 'landlords',
}
