export function getFromStorage<U>(key: string): U | undefined {
  try {
    const serialized = global.sessionStorage.getItem(key);

    return serialized === null ? undefined : (JSON.parse(serialized) as U);
  } catch (e) {
    // parsing error - ignored
    return undefined;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setInStorage(key: string, value: any) {
  try {
    const serialized = JSON.stringify(value);

    global.sessionStorage.setItem(key, serialized);
  } catch (error) {
    // ignore error - either storage is full or disabled
  }
}

export function removeFromStorage(key: string) {
  global.sessionStorage.removeItem(key);
}
