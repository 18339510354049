import { MakeCall } from './createMakeCall';

export interface VerificationRequest {
  code: string;
}

const verifyCode =
  (makeCall: MakeCall) =>
  (userId: number, { code }: VerificationRequest) =>
    makeCall<{ valid: boolean }>('POST', `/api/v2/user/${userId}/verify-code`, {
      data: {
        code,
      },
    });
export { verifyCode };
