import { MakeCall } from './createMakeCall';

export interface RedirectUrl {
  redirectUrl: string;
}

export type GetFullUrlCall = (token: string) => Promise<{ data: RedirectUrl }>;

const getFullUrl = (makeCall: MakeCall) => (token: string) =>
  makeCall<RedirectUrl>('GET', `/api/v2/shortlink/${token}`);
export { getFullUrl };
