import { normalizeCoordinate } from './normalizeCoordinate';

const EARTH_RADIUS = 6371.01;
const MAX_LAT = Math.PI / 2;
const MIN_LAT = -MAX_LAT;
const MAX_LNG = Math.PI;
const MIN_LNG = -MAX_LNG;
const FULL_CIRCLE_RAD = Math.PI * 2;

const toRadian = (x: number) => x * (Math.PI / 180);
const toDegrees = (x: number) => x * (180 / Math.PI);

export const getBoundaryCoordinates = (
  lat: number,
  lng: number,
  radius: number,
) => {
  const latR = toRadian(lat);
  const lngR = toRadian(lng);
  const radRadius = radius / EARTH_RADIUS;

  let minLng;
  let maxLng;
  let minLat = latR - radRadius;
  let maxLat = latR + radRadius;

  if (minLat > MIN_LAT && maxLat < MAX_LAT) {
    const deltaLng = Math.asin(Math.sin(radRadius) / Math.cos(latR));

    minLng = lngR - deltaLng;
    if (minLng < MIN_LNG) {
      minLng += FULL_CIRCLE_RAD;
    }
    maxLng = lngR + deltaLng;
    if (maxLng > MAX_LNG) {
      maxLng -= FULL_CIRCLE_RAD;
    }
  } else {
    minLat = Math.max(minLat, MIN_LAT);
    maxLat = Math.min(maxLat, MAX_LAT);
    minLng = MIN_LNG;
    maxLng = MAX_LNG;
  }

  return [maxLat, maxLng, minLat, minLng]
    .map(toDegrees)
    .map(normalizeCoordinate);
};
