import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const doRangesOverlap = (
  from1: Date | string,
  to1: Date | string,
  from2: Date | string,
  to2: Date | string,
): boolean =>
  dayjs(from1).isBetween(from2, to2, 'day', '[]') ||
  dayjs(to1).isBetween(from2, to2, 'day', '[]') ||
  dayjs(from2).isBetween(from1, to1, 'day', '[]') ||
  dayjs(to2).isBetween(from1, to1, 'day', '[]');

export { doRangesOverlap };
