/**
 * Error class for an API response outside the 200 range
 *
 * @class ApiError
 * @access public
 */
export class ApiError extends Error {
  /** the status code of the API response */
  status: number;

  /** the status text of the API response */
  statusText: string;

  /** the parsed JSON response of the API server if the 'Content-Type' header signals a JSON response */
  response: object;

  headers: Headers;

  constructor(
    status: number,
    statusText: string,
    response: object,
    headers: Headers,
  ) {
    super(`${status} - ${statusText}`);
    this.name = 'ApiError';
    this.status = status;
    this.statusText = statusText;
    this.response = response;
    this.headers = headers;
  }
}
