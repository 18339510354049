import { normalizeDate } from '@ha/date';
import {
  TypeValue,
  SuitableForValue,
  RoomsValue,
} from 'ha/types/SearchFilters';
import { CanonicalFurniture, CanonicalBills } from 'ha/constants/Filters';
import { ParsedQuery } from 'query-string';
import {
  CanonicalCollections,
  TranslatedCollectionToCanonical,
} from 'ha/constants/SearchPageCollections';

const CanonicalCollectionToFilter = (stats: CollectionStats) => ({
  [TypeValue.PRIVATE_ROOM]: { categories: [TypeValue.PRIVATE_ROOM] },
  [TypeValue.SHARED_ROOM]: { categories: [TypeValue.SHARED_ROOM] },
  [TypeValue.APARTMENT]: {
    categories: [TypeValue.APARTMENT],
  },
  [TypeValue.STUDIO]: {
    categories: [TypeValue.STUDIO],
  },
  [CanonicalCollections.CheapAccommodation]: {
    priceMin: 0,
    priceMax: stats.averagePrice,
    currency: 'EUR',
  },
  [CanonicalCollections.FurnishedApartments]: {
    furniture: CanonicalFurniture.Furnished,
    categories: [TypeValue.APARTMENT],
  },
  [CanonicalCollections.StudentAccommodation]: {
    furniture: CanonicalFurniture.Furnished,
    suitableFor: SuitableForValue.STUDENTS,
  },
  [CanonicalCollections.LongTermRentals]: {
    startDate: normalizeDate(new Date()),
  },
  [CanonicalCollections.OneBedroomApartments]: {
    categories: [TypeValue.APARTMENT],
    rooms: {
      bedroomCount: [RoomsValue.ONE],
    },
    bedroomCount: [RoomsValue.ONE],
  },
  [CanonicalCollections.TwoBedroomApartments]: {
    categories: [TypeValue.APARTMENT],
    rooms: {
      bedroomCount: [RoomsValue.TWO],
    },
    bedroomCount: [RoomsValue.TWO],
  },
  [CanonicalCollections.StudentRoom]: {
    categories: [TypeValue.PRIVATE_ROOM, TypeValue.SHARED_ROOM],
    furniture: CanonicalFurniture.Furnished,
    bills: CanonicalBills.Included,
    suitableFor: SuitableForValue.STUDENTS,
  },
});

export interface CollectionStats {
  averagePrice: number;
}

const collectionToFilters = (
  translatedCollection: string | undefined,
  stats: CollectionStats,
): ParsedQuery => {
  if (!translatedCollection) {
    return {};
  }

  const canonicalKey = TranslatedCollectionToCanonical[translatedCollection];

  return canonicalKey ? CanonicalCollectionToFilter(stats)[canonicalKey] : {};
};

export { collectionToFilters };
