import { FurnitureValue } from '../../../types/SearchFilters';
import { FilterBuilder } from '../Types';

const furnitureBuilder: FilterBuilder = ({ furniture = [] }) => {
  if (
    furniture.length === 0 ||
    (furniture.includes(FurnitureValue.FURNISHED) &&
      furniture.includes(FurnitureValue.UNFURNISHED))
  ) {
    return '';
  }

  return {
    [FurnitureValue.FURNISHED]: 'facility_bedroom_furnished:yes',
    [FurnitureValue.UNFURNISHED]: 'facility_bedroom_furnished:no',
  }[furniture[0]];
};

export { furnitureBuilder };
