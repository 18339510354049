import { SearchOptions } from '@algolia/client-search';
import { SearchClient } from 'algoliasearch/lite';

import { AlgoliaListing } from '@ha/algolia';

export interface AlgoliaQuery {
  indexName?: string;
  query: string;
  params: SearchOptions;
}

type AlgoliaInitializer = () => Promise<SearchClient>;

interface Options {
  initializer: AlgoliaInitializer;
  index: string;
}

export function createAlgoliaService({ initializer, index }: Options) {
  let initializedP: Promise<SearchClient> | null;

  const init = () => {
    if (initializedP) {
      return initializedP;
    }

    initializedP = initializer();

    return initializedP;
  };

  return {
    searchListings: (params: AlgoliaQuery[], customIndex?: string) =>
      init().then(client => {
        return client.search<AlgoliaListing>(
          params.map(param => ({
            ...param,
            indexName: customIndex || index,
          })),
        );
      }),
  };
}
