import { FilterBuilder } from '../Types';

const propertyBuilder: FilterBuilder = ({ propertyIds = [] }) => {
  if (!propertyIds || !propertyIds.length) {
    return '';
  }

  const idsFilter = propertyIds
    .filter(id => id === 0 || Boolean(id))
    .map(id => {
      return `propertyId=${id}`;
    });

  return idsFilter.join(' OR ');
};

export { propertyBuilder };
