import { useCallback, useEffect, MouseEvent } from 'react';

import { useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import { getIsAuthenticated } from 'ha/modules/AuthLogic/selectors';
import { GlobalState } from 'ha/types/store';

import {
  addFavoriteListing,
  removeFavoriteListing,
  syncPendingFavoriteListing,
} from '../actions';
import {
  isLoading as isLoadingSelector,
  isFavorite as isFavoriteSelector,
} from '../selectors';

export const useFavoriteListingActions = (listingId: number) => {
  const isLoadingSelectorWithId = useCallback(
    (state: GlobalState) => isLoadingSelector(state, listingId),
    [listingId],
  );
  const isFavoriteSelectorWithId = useCallback(
    (state: GlobalState) => isFavoriteSelector(state, listingId),
    [listingId],
  );

  const isLoading = useSelector(isLoadingSelectorWithId);
  const isFavorite = useSelector(isFavoriteSelectorWithId);
  const isAuthenticated = useSelector(getIsAuthenticated);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    dispatch(syncPendingFavoriteListing());
  }, [dispatch, isAuthenticated]);

  const onClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();

      if (isLoading) {
        return;
      }

      if (isFavorite) {
        dispatch(removeFavoriteListing(listingId));
      } else {
        dispatch(
          addFavoriteListing(
            listingId,
            `${location.pathname}${location.search}`,
          ),
        );
      }
    },
    [dispatch, listingId, isLoading, isFavorite, location],
  );

  return { onClick, isLoading, isFavorite };
};
