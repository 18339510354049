import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getSupportedResidenceCountries } from '../selectors';
import { fetchSupportedResidenceCountries } from '../actions';

export const useSupportedResidenceCountries = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSupportedResidenceCountries());
  }, [dispatch]);

  return useSelector(getSupportedResidenceCountries);
};
