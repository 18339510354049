const storage: Storage = process.browser
  ? global.localStorage
  : {
      getItem: () => null,
      setItem: () => {},
      removeItem: () => {},
      clear: () => {},
      length: 0,
      key: () => null,
    };

export function getFromStorage<U>(key: string): U | undefined {
  try {
    const serialized = storage.getItem(key);

    return serialized === null ? undefined : (JSON.parse(serialized) as U);
  } catch (e) {
    // parsing error - ignored
    return undefined;
  }
}

/**
 * Retrieves a variable from localStorage by key
 * Throws an error if localStorage is disabled
 */
export function getFromStorageNew<U>(key: string): U | undefined {
  try {
    const serialized = storage.getItem(key);

    return serialized === null ? undefined : (JSON.parse(serialized) as U);
  } catch (e) {
    throw new Error('localStorage error');
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setInStorage(key: string, value: any) {
  try {
    const serialized = JSON.stringify(value);

    storage.setItem(key, serialized);
  } catch (error) {
    // ignore error - either storage is full or disabled
  }
}

export function removeFromStorage(key: string) {
  storage.removeItem(key);
}

export type Read = typeof getFromStorage;
export type Write = typeof setInStorage;
export type Remove = typeof removeFromStorage;
