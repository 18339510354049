import { Thunk } from 'ha/myredux/types';

import { AuthLogicActions } from './actions';

export const fetchImpersonationInfo: () => Thunk =
  () =>
  (dispatch, _, { apiV2 }) =>
    apiV2
      .getUserInformation('me', {
        expand: 'impersonation',
      })
      .then(({ data: { impersonation } }) => {
        if (impersonation) {
          dispatch(AuthLogicActions.fulfillImpersonation(impersonation));
        }
      })
      .catch(() => {
        // do nothing
      });
