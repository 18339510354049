import { CategoryHelpCenter, Language } from '@ha/contentful';
import { defineKey } from '@ha/intl';

export const CATEGORIES_TITLE = {
  [CategoryHelpCenter.Onboarding]: defineKey('help_center.onboarding_title'),
  [CategoryHelpCenter.Optimization]: defineKey(
    'help_center.optimization_title',
  ),
  [CategoryHelpCenter.Growth]: defineKey('help_center.growth_title'),
  [CategoryHelpCenter.Payments]: defineKey('help_center.payments_title'),
  [CategoryHelpCenter.ReviewsRatings]: defineKey('blog.topic.reviews_ratings'),
};

export const CATEGORIES_ORDER = [
  CategoryHelpCenter.Onboarding,
  CategoryHelpCenter.Optimization,
  CategoryHelpCenter.Growth,
  CategoryHelpCenter.Payments,
  CategoryHelpCenter.ReviewsRatings,
];

export const PREV_TO_NEXT_HELP_CENTER_PATHS = {
  [Language.en]: {
    prev: '/help-center-old',
    next: '/help-center',
  },
  [Language.nl]: {
    prev: '/helpcenter-old',
    next: '/helpcenter',
  },
  [Language.fr]: {
    prev: '/centre-d-assistance-old',
    next: '/centre-d-assistance',
  },
  [Language.de]: {
    prev: '/hilfe-center-old',
    next: '/hilfe-center',
  },
  [Language.it]: {
    prev: '/centro-di-assistenza-old',
    next: '/centro-di-assistenza',
  },
  [Language.es]: {
    prev: '/centro-de-ayuda-old',
    next: '/centro-de-ayuda',
  },
};
