import { MakeCall } from './createMakeCall';

export interface Subscriptions {
  'search emails group': boolean;
  'all emails group': boolean;
  'booking group': boolean;
  'conversation group': boolean;
  'marketing group': boolean;
}

const getUserSubscriptions = (makeCall: MakeCall) => (userId: number) =>
  makeCall<Subscriptions>('GET', `/api/v2/user/${userId}/subscriptions`);
export { getUserSubscriptions };
