import type { MakeCall } from '../createMakeCall';

export interface UpdateUserBillingDataPayload {
  firstName?: string;
  lastName?: string;
  company?: string;
  vat?: string;
  countryCode?: string;
  city?: string;
  state?: string;
  zip?: string;
  address?: string;
  address2?: string;
  type?: string;
}

export interface UserBillingDataResource {
  firstName: string;
  lastName: string;
  vat: string;
  isVatVerified: boolean;
  company: string;
  countryCode: string;
  city: string;
  state: string;
  zip: string;
  address: string;
  address2: string;
  type: string;
}

const updateBillingInformation =
  (makeCall: MakeCall) =>
  (userId: number, data: UpdateUserBillingDataPayload) =>
    makeCall<UserBillingDataResource>(
      'PUT',
      `/api/v2/user/${userId}/billing-details`,
      {
        data,
      },
    );

export { updateBillingInformation };

export type UpdateBillingInformation = ReturnType<
  typeof updateBillingInformation
>;
