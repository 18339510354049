import type { MakeCall } from '../createMakeCall';
import { QualificationRequirement } from '../types/qualifications/QualificationRequirement';
import { RequiredDocuments } from '../types/RequiredDocuments';

const addQualificationRequirementsDefaults =
  (makeCall: MakeCall) =>
  (applyToExistingListing: boolean, data: Array<RequiredDocuments>) =>
    makeCall<QualificationRequirement>(
      'POST',
      `/api/v2/qualification/requirements/defaults`,
      {
        data: {
          applyToExistingListing,
          documents: data,
        },
      },
    );
export { addQualificationRequirementsDefaults };
