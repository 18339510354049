import type { MakeCall } from '../createMakeCall';
import {
  AdvertiserReportsResponse,
  GetAdvertiserReportsQuery,
} from '../types/surveyReviews/AdvertiserReports';

const getReviewAdvertiserReports =
  (makeCall: MakeCall) => (query: GetAdvertiserReportsQuery) =>
    makeCall<AdvertiserReportsResponse>(
      'GET',
      '/api/v2/review/landlord/reports',
      {
        query,
      },
    );

export { getReviewAdvertiserReports };
