import { numberOfRooms } from 'ha/constants/numberOfRooms';

export const STATE_NAME = 'verification';
export const ACTION_PREFIX = 'VERIFICATION';

export const Actions = {
  UDPATE: `${ACTION_PREFIX}/UDPATE`,
  UPDATE_PHONE_DATA: `${ACTION_PREFIX}/UPDATE_PHONE_DATA`,

  SEND_CODE_START: `${ACTION_PREFIX}/SEND_CODE_START`,
  SEND_CODE_DONE: `${ACTION_PREFIX}/SEND_CODE_DONE`,
  SEND_CODE_ERROR: `${ACTION_PREFIX}/SEND_CODE_ERROR`,

  VERIFY_START: `${ACTION_PREFIX}/VERIFY_START`,
  VERIFY_DONE: `${ACTION_PREFIX}/VERIFY_DONE`,
  VERIFY_ERROR: `${ACTION_PREFIX}/VERIFY_ERROR`,

  VERIFY_CODE_START: `${ACTION_PREFIX}/VERIFY_CODE_START`,
  VERIFY_CODE_DONE: `${ACTION_PREFIX}/VERIFY_CODE_DONE`,
  VERIFY_CODE_ERROR: `${ACTION_PREFIX}/VERIFY_CODE_ERROR`,

  VERIFY_BY_PHONE_START: `${ACTION_PREFIX}/VERIFY_BY_PHONE_START`,
  VERIFY_BY_PHONE_DONE: `${ACTION_PREFIX}/VERIFY_BY_PHONE_DONE`,
  VERIFY_BY_PHONE_ERROR: `${ACTION_PREFIX}/VERIFY_BY_PHONE_ERROR`,
};

export const max = numberOfRooms.length - 1;
export const placesAmountMarks = numberOfRooms.map((option, index) => ({
  value: index,
  label: option || '',
}));
