import { MakeCall } from '../createMakeCall';
import { UnitType } from '../types/inventory/UnitType';

export interface Query {
  /**
   * @description comma-separated string with possible values: `units`, `exclusions`, `exclusionsExtended`, `bookablePeriods`, `conversationCounters`, `lastMonthConversationCount`, `photos`, `videos`, `costs`
   */
  expand?: string;
  lang?: string;
  moveInDate?: string;
  moveOutDate?: string;
  bookinglink?: boolean;
}

const getUnitTypeById =
  (makeCall: MakeCall) => (unitTypeId: number, query?: Query) =>
    makeCall<UnitType>('GET', `/api/v2/unit-type/${unitTypeId}`, {
      query,
    });

export { getUnitTypeById };
