import { Conversation } from './types/Conversation';
import { MakeCall } from './createMakeCall';

const markConversationAsRead =
  (makeCall: MakeCall) => (conversationId: number) =>
    makeCall<Conversation>(
      'PUT',
      `/api/v2/conversation/${conversationId}/read`,
    );
export { markConversationAsRead };
