import type { MakeCall } from '../createMakeCall';
import {
  BillingInformation,
  BillingInformationDetails,
} from '../types/BillingInformation';

export interface APICreateMultiBillingData {
  billingInfo: BillingInformation;
}

const createMultiBillingInformation =
  (makeCall: MakeCall) => (data: BillingInformationDetails) =>
    makeCall<APICreateMultiBillingData>(
      'POST',
      `/api/v2/billing/billing-info`,
      {
        data,
      },
    ).then(res => ({
      ...res,
      data: {
        billingInformation: res.data.billingInfo,
      },
    }));

export { createMultiBillingInformation };
