import type { MakeCall } from '../createMakeCall';
import { PaymentMethod } from './types';

export type Response = PaymentMethod;

const confirmPaymentMethod =
  (makeCall: MakeCall) => (uuid: string, confirmationToken: string) =>
    makeCall<Response>('PUT', `/api/v2/payment/method/pay-in/${uuid}`, {
      data: { token1: confirmationToken },
    });
export { confirmPaymentMethod };
