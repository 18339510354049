import { MakeCall } from './createMakeCall';
import { PayoutInformation } from './types/ConversationPayoutInfo';

const getConversationPayoutInfo =
  (makeCall: MakeCall) => (conversationId: number) =>
    makeCall<PayoutInformation>(
      'GET',
      `/api/v2/conversation/${conversationId}/payout-information`,
    );
export { getConversationPayoutInfo };
