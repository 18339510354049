import { FilterBuilder } from '../Types';

const countryBuilder: FilterBuilder = ({ country }) => {
  if (!country) {
    return '';
  }

  return `country:"${country}"`;
};

export { countryBuilder };
