import { MakeCall } from '../createMakeCall';
import { UnitTypePhoto, UnitTypePhotoType } from '../types/inventory/UnitType';

export interface UpdateUnitTypePhotoOptions {
  rotate?: number;
  rotateIncrement?: number;
  type?: UnitTypePhotoType;
}

const updateUnitTypePhoto =
  (makeCall: MakeCall) =>
  (
    unitTypeId: number,
    photoId: number,
    { rotate, rotateIncrement, type }: UpdateUnitTypePhotoOptions,
  ) =>
    makeCall<UnitTypePhoto[]>(
      'PUT',
      `/api/v2/unit-type/${unitTypeId}/photo/${photoId}`,
      {
        data: {
          rotate,
          rotateIncrement,
          type,
        },
      },
    );
export { updateUnitTypePhoto };
