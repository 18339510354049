import { MakeCall } from '../createMakeCall';

export interface SurveyData {
  isHa?: boolean;
  isRented?: boolean;
  isLocalStudent?: boolean;
  reason?: string;
  how?: string;
  why?: string;
}

const surveyUnpublishUnitType =
  (makeCall: MakeCall) => (unitTypeId: number, data: SurveyData) => {
    return makeCall<undefined>(
      'POST',
      `/api/v2/unit-type/${unitTypeId}/survey`,
      {
        data,
      },
    );
  };

export { surveyUnpublishUnitType };
