import { MakeCall } from './createMakeCall';

import { UserDocument } from './types/UserDocument';

export interface Query {
  /**
   * @description comma-separated string with possible values
   *  included in the UserDocumentCategory enum eg: `identification`, `proof-of-income`,
   * `proof-of-occupation`, etc
   */
  categories?: string;

  query?: string;
  orderBy?: string;
  limit?: number;
  offset?: number;
}

export interface UserDocumentsResponse {
  documents: UserDocument[];
  count: number;
}

const getUserDocuments =
  (makeCall: MakeCall) => (userId: number | 'me', query?: Query) =>
    makeCall<UserDocumentsResponse>('GET', `/api/v2/user/${userId}/document`, {
      query,
    });
export { getUserDocuments };
