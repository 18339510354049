import type { MakeCall } from '../createMakeCall';
import { PayInResource } from './types';

const getPayInsBySettlement =
  (makeCall: MakeCall) => (settlementReference: string) =>
    makeCall<{ payins: PayInResource[] }>(
      'GET',
      `/api/v2/payment/settlement/${settlementReference}/pay-in`,
    );
export { getPayInsBySettlement };
