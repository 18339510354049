import { handleActions } from '@housinganywhere/safe-redux';
import { FavoriteListing } from '@ha/api/v2/types/FavoriteListing';

import {
  ActionsUnionType as FetchActions,
  ActionTypes as FetchActionTypes,
} from '../actions/getFavoriteListings';
import {
  ActionsType as AddActions,
  ActionTypes as AddActionTypes,
} from '../actions/addFavoriteListing';
import {
  ActionsType as RemoveActions,
  ActionTypes as RemoveActionTypes,
} from '../actions/removeFavoriteListing';
import {
  ActionsUnionType as SyncPendingActions,
  ActionTypes as SyncPendingActionTypes,
} from '../actions/syncPendingFavoriteListing';

interface EntityStore {
  [key: string]: FavoriteListing;
}

export interface FavoriteListingState {
  listings: EntityStore;
  listingIds: number[];
  loadingListingIds: number[];
  fetchStarted: boolean;
  error: boolean;
  synced: boolean;
}

export const initialState: FavoriteListingState = {
  // Listing id as key, listing as value
  listings: {},
  // List of listing ids that are favorites
  listingIds: [],
  // List of listing ids performing add/remove actions
  loadingListingIds: [],
  fetchStarted: false,
  error: false,
  synced: false,
};

type ActionTypes =
  | FetchActionTypes
  | AddActionTypes
  | RemoveActionTypes
  | SyncPendingActionTypes;
type Actions = FetchActions | AddActions | RemoveActions | SyncPendingActions;

export const reducer = handleActions<
  FavoriteListingState,
  ActionTypes,
  Actions
>(
  {
    [FetchActionTypes.START]: state => ({
      ...state,
      error: false,
      fetchStarted: true,
    }),
    [FetchActionTypes.DONE]: (state, { payload: favorites }) => ({
      ...state,
      listingIds: favorites
        .filter(
          (favorite: FavoriteListing) =>
            !favorite.isDeleted && !favorite.isHidden,
        )
        .map((favorite: FavoriteListing) => favorite.listingId),
      listings: favorites.reduce(
        (entityStore: EntityStore, favorite: FavoriteListing) => ({
          ...entityStore,
          [favorite.listingId]: favorite,
        }),
        state.listings,
      ),
    }),
    [FetchActionTypes.FAIL]: state => ({
      ...state,
      error: true,
    }),
    [SyncPendingActionTypes.START]: state => ({
      ...state,
      synced: true,
    }),
    [AddActionTypes.START]: (state, { payload: listingId }) => ({
      ...state,
      loadingListingIds: state.loadingListingIds.concat(listingId),
    }),
    [AddActionTypes.DONE]: (state, { payload: listingId }) => ({
      ...state,
      listingIds: state.listingIds.concat(listingId),
      loadingListingIds: state.loadingListingIds.filter(id => id !== listingId),
    }),
    [AddActionTypes.FAIL]: (state, { payload: listingId }) => ({
      ...state,
      loadingListingIds: state.loadingListingIds.filter(id => id !== listingId),
    }),

    [RemoveActionTypes.START]: (state, { payload: listingId }) => ({
      ...state,
      loadingListingIds: state.loadingListingIds.concat(listingId),
    }),
    [RemoveActionTypes.DONE]: (state, { payload: listingId }) => {
      const { [listingId]: deletedListingId, ...listings } = state.listings;

      return {
        ...state,
        listingIds: state.listingIds.filter(id => id !== listingId),
        loadingListingIds: state.loadingListingIds.filter(
          id => id !== listingId,
        ),
        listings,
      };
    },
    [RemoveActionTypes.FAIL]: (state, { payload: listingId }) => ({
      ...state,
      loadingListingIds: state.loadingListingIds.filter(id => id !== listingId),
    }),
  },
  initialState,
);
