import { MakeCall } from './createMakeCall';
import { ChangeRequestResponse } from './types/ChangeRequest';

// Get the change request on a conversation (for Tenant and LL)
export const getChangeRequestByConversationId =
  (makeCall: MakeCall) => (conversationId: number) =>
    makeCall<ChangeRequestResponse>(
      'GET',
      `/api/v2/conversation/${conversationId}/change`,
    );
