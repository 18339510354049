import { preprocessResponse } from 'ha/utils/preprocessResponse';
import { FetchClient } from 'ha/helpers/FetchClient';

interface Response {
  lang: string;
}

export function createTranslationService(
  { languageDetectionURL }: { languageDetectionURL: string },
  fetchClient: FetchClient,
) {
  return {
    detectLanguage: (text: string) =>
      fetchClient
        .fetch(languageDetectionURL, {
          method: 'POST',
          mode: 'cors',
          body: text,
        })
        .then<Response>(preprocessResponse)
        .then(({ lang }) => lang),
  };
}
