import {
  alias,
  ApiObject,
  getAnonymousId,
  identify,
  resetAnonymousId,
  setAnonymousId,
} from '@hbf/analytics';

import {
  getUserId,
  getIsAliased,
  getUserIdAlias,
  getUserTraits,
  getAnalyticsUserData,
  getUserUUID,
} from 'ha/modules/AuthLogic/selectors';
import { Thunk } from 'ha/myredux/types';
import { CookieConsent } from 'ha/services/analytics/types';

import {
  identifyAnonymously,
  identifyRockerbox,
  identifyWithRockerbox,
} from './utils';

export const identifyUser =
  (
    {
      isCookieConsentEnabled,
      isAnonymousTrackingEnabled,
      hasUserRejectedAllCategories,
    }: CookieConsent,
    userTraits?: ApiObject,
  ): Thunk =>
  (_, getState, { analytics }) => {
    const state = getState();

    const userId = getUserId(state);

    const withAnonymousTracking =
      isCookieConsentEnabled && isAnonymousTrackingEnabled;

    if (withAnonymousTracking) {
      resetAnonymousId(hasUserRejectedAllCategories);
    }

    if (!userId) {
      // Needs to call identify, to set new anonymous id on device mode destinations like mixpanel
      identify('', userTraits);
      analytics.userIdentificationComplete();
      return;
    }

    const userIdAlias = getUserIdAlias(state);

    function unsetAnonymousId() {
      const anonymousId = getAnonymousId()!;

      console.log('analytics', 'user id and anonymous id', {
        userIdAlias,
        anonymousId,
      });

      // unset anonymous id after first page loaded
      if (userIdAlias !== anonymousId) {
        console.log(
          'analytics',
          `setting anonymous id to user id alias "${userIdAlias}", previously "${anonymousId}"`,
        );
        // alias anonymous id to user id => triggers merge without error
        alias(anonymousId, userIdAlias);
        // set anonymous id to user id => would not trigger merge moving forward
        setAnonymousId(userIdAlias);
      }
    }

    // somehow this is needed; even `analytics.onReady` do not work here
    setTimeout(unsetAnonymousId);

    const userTraitsBaseline = getUserTraits(state);
    const userDataAnalytics = getAnalyticsUserData(state);
    const identifyPayload: ApiObject = {
      ...userTraitsBaseline,
      ...userDataAnalytics,
      ...userTraits,
    };
    const userUUID = getUserUUID(state);
    const isAliased = getIsAliased(state);

    if (withAnonymousTracking && hasUserRejectedAllCategories) {
      // authenticated user id with anonymous tracking

      identifyRockerbox(userUUID, identifyPayload);

      identifyAnonymously(identifyPayload);

      analytics.userIdentificationComplete();
      return;
    }

    // Verify if merging a new identity with an existing user is required
    if (isAliased) {
      identifyWithRockerbox(userUUID, identifyPayload, userIdAlias);

      analytics.userIdentificationComplete();
    } else {
      alias(userIdAlias);

      setTimeout(() => {
        identifyWithRockerbox(userUUID, identifyPayload, userIdAlias);

        analytics.userIdentificationComplete();
      }, 2000);
    }
  };
