import { MakeCall } from './createMakeCall';

export interface CurrencyEuroRatesResponse {
  base: string;
  rates: { [key: string]: number };
}

export type GetCurrencyEuroRatesCall = () => Promise<{
  data: CurrencyEuroRatesResponse;
}>;

const getCurrencyEuroRates = (makeCall: MakeCall) => () =>
  makeCall<CurrencyEuroRatesResponse>('GET', `/api/v2/currency/EUR/rates`);
export { getCurrencyEuroRates };
