import { handleActions } from '@housinganywhere/safe-redux';

import { Language } from '@ha/intl';

import { ActionsUnionType, ActionTypes } from 'ha/modules/FeatureFlags/actions';

import { getCode } from './language';
import { Languages } from '../types';

export const initialState = [
  { code: Language.en, localisedText: 'English' },
  { code: Language.fr, localisedText: 'Français' },
  { code: Language.de, localisedText: 'Deutsch' },
  { code: Language.es, localisedText: 'Español' },
  { code: Language.it, localisedText: 'Italiano' },
  { code: Language.nl, localisedText: 'Nederlands' },
  { code: Language.pt, localisedText: 'Português (BR)' },
  { code: Language.uk, localisedText: 'Українська' },
  { code: Language.ru, localisedText: 'Русский' },
  { code: Language.pl, localisedText: 'Polski' },
  { code: Language.sv, localisedText: 'Svenska' },
  { code: Language.ro, localisedText: 'Română' },
  { code: Language.zh, localisedText: '中文' },
];

type State = Array<{ code: Language; localisedText: string }>;

export const availableLanguages = handleActions<
  State,
  ActionTypes,
  ActionsUnionType
>(
  {
    [ActionTypes.SET]: (state, { payload }) => {
      let newState: State = state;
      if (payload.languagesUA !== 'on') {
        newState = newState.filter(
          lang => ![Language.uk, Language.ru].includes(lang.code),
        );
      }
      if (payload.languagesNew !== 'on') {
        newState = newState.filter(
          lang => ![Language.pl, Language.sv, Language.ro].includes(lang.code),
        );
      }

      return newState;
    },
  },
  initialState,
);

export const getAvailableLanguages = (state: Languages) => state;

export const isLanguageAvailable = (state: Languages, lang: Language) =>
  state.map(getCode).includes(lang);
