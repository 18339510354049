import { MakeCall } from './createMakeCall';
import { TaxPayerPayload, TaxPayerResponse } from './types/TaxPayerDetails';

/**
 * Handles creating tax payer details  requests (compliance)
 * @returns tax payer details response
 */

export const createTaxPayer =
  (makeCall: MakeCall) => (payload: TaxPayerPayload) =>
    makeCall<TaxPayerResponse>('POST', `/api/v2/legal/taxpayer`, {
      data: payload,
    });
