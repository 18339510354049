export const ACTION_PREFIX = 'PAYOUTS/INVOICES';
export const STATE_NAME = 'payoutsInvoices';

// Pagination
export const INVOICES_PAGER_WIDTH_SM = 5;
export const INVOICES_PAGER_WIDTH_MD = 9;
export const INVOICES_CHUNK_SIZE = 15;

export const Actions = {
  LOAD_INVOICES_START: `${ACTION_PREFIX}/LOAD_INVOICES_START`,
  LOAD_INVOICES_DONE: `${ACTION_PREFIX}/LOAD_INVOICES_DONE`,
  LOAD_INVOICES_FAIL: `${ACTION_PREFIX}/LOAD_INVOICES_FAIL`,

  CHANGE_PAGE: `${ACTION_PREFIX}/CHANGE_PAGE`,
};
