import { AnyAction } from 'redux';
import { Actions as SearchActions } from 'ha/pages/Search/constants';
import { ActionTypes as CitiesActions } from 'ha/pages/Cities/actions';
import { ActionTypes } from '../actions/setLocalisedLocations';
import * as language from './language';
import { Languages } from '../types';

export const initialState = [];

// eslint-disable-next-line @typescript-eslint/default-param-last
const localisedLocations = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SearchActions.LOAD_DONE: {
      const languages = action.payload.langURLListWithCollections || {};

      return Object.keys(languages).map(code => ({
        code,
        localisedLocation: languages[code],
      }));
    }
    case CitiesActions.SUCCESS: {
      const languages = action.payload.langURLList || {};

      return Object.keys(languages).map(code => ({
        code,
        localisedLocation: languages[code].replace('/s/', '/'),
      }));
    }
    case ActionTypes.SET: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const getLocalisedLocation = (state: Languages, langCode: string) => {
  const lang = state.filter(
    localisedLang => language.getCode(localisedLang) === langCode,
  )[0];

  return lang ? language.getLocalisedLocation(lang) : null;
};
export { localisedLocations };
