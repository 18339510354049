import React from 'react';

import { IntlConsumer } from './IntlContext';
import { IntlObject } from './types';

interface IntlProps {
  intl: IntlObject;
}

export function withIntl<P extends IntlProps>(
  ComponentToWrap: React.ComponentType<P>,
) {
  return class WithIntl extends React.Component<Omit<P, keyof IntlProps>> {
    render() {
      return (
        <IntlConsumer>
          {value => <ComponentToWrap {...(this.props as P)} intl={value} />}
        </IntlConsumer>
      );
    }
  };
}
