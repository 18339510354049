import { MakeCall } from './createMakeCall';
import { RentalConditions } from './types/RentalConditions';

const getRentalConditionsByConversationId =
  (makeCall: MakeCall) => (conversationId: number) =>
    makeCall<RentalConditions>(
      'GET',
      `/api/v2/bookings/rental-conditions/conversation/${conversationId}`,
    );
export { getRentalConditionsByConversationId };
