import dayjs, { OpUnitType, QUnitType } from 'dayjs';
import curry from 'lodash-es/curry';

const _diff = (
  whatToDiff: OpUnitType | QUnitType,
  firstDate: Date | string,
  secondDate: Date | string,
): number => dayjs(firstDate).diff(dayjs(secondDate), whatToDiff);

const diff = curry(_diff, 3);

export { diff };
