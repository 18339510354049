import { defineKey as T } from '@ha/intl';
import { countries } from 'ha/constants/Countries';

export const ACTION_PREFIX = 'PAYOUTS/BILLING';
export const STATE_NAME = 'payoutsBilling';
export const BILLING_INFORMATION_FORM_NAME = 'UpdateBillingInfoForm';

export const messages = {
  SAVED: T('Your changes were saved'),
  NOT_SAVED: T('We could not save your changes. Please try again!'),
};

export const errorMessagesByFields = {
  firstName: T('Wrong first name'),
  lastName: T('Wrong last name'),
  address: T('Wrong address'),
  address2: T('Wrong apartment number'),
  state: T('Wrong state'),
  city: T('Wrong city'),
  vat: T('Wrong VAT ID'),
  zip: T('Wrong postalcode'),
  unknown: T('Unknown error'),
};

export const PERSON = 'naturalPerson';
export const COMPANY = 'company';
export const HIDE_VAT_ID = 'hide-vat-id';
export const SHOW_VAT_ID = 'show-vat-id';

export const supportedCountryCodes = [
  'AT',
  'BE',
  'FI',
  'DE',
  'FR',
  'DK',
  'IE',
  'IT',
  'LU',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'ES',
  'SE',
  'CH',
  'GB',
  'BG',
  'CZ',
  'EE',
  'GR',
  'HU',
  'LV',
  'LT',
  'RO',
  'SK',
  'SI',
  'CY',
  'IS',
];

export const additionalSupportedCountryCodes = ['CA', 'US'];

export const SUPPORTED_COUNTRIES = countries.filter(country =>
  supportedCountryCodes.includes(country.code),
);

export const ALL_SUPPORTED_COUNTRIES = countries.filter(country =>
  [...supportedCountryCodes, ...additionalSupportedCountryCodes].includes(
    country.code,
  ),
);
