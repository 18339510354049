import { MakeCall } from './createMakeCall';
import { Listing } from './types/Listing';

interface Query {
  expand?: string;
  startDate?: string;
  endDate?: string;
}

const getSimilarListings =
  (makeCall: MakeCall) =>
  ({ listingId, expand, startDate, endDate }: Query & { listingId: string }) =>
    makeCall<Listing[]>('GET', `/api/v2/unit-type/${listingId}/similar`, {
      query: {
        expand,
        startDate,
        endDate,
      },
    });

export { getSimilarListings };
