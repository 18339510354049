import { UnitType, UpdateUnitTypePayload } from '../types/inventory/UnitType';
import { MakeCall } from '../createMakeCall';

export interface Query {
  /**
   * @description comma-separated string with possible values: `units`, `exclusions`, `exclusionsExtended`, `bookablePeriods`, `conversationCounters`, `lastMonthConversationCount`, `photos`, `videos`, `costs`
   */
  expand?: string;
  lang?: string;
}

const updateUnitType =
  (makeCall: MakeCall) =>
  (unitTypeId: number, data: UpdateUnitTypePayload, query?: Query) =>
    makeCall<UnitType>('PUT', `/api/v2/unit-type/${unitTypeId}`, {
      data,
      query,
    });

export { updateUnitType };
