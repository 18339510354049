import { CookieAttr } from 'cookies';
import { CookieAttributes } from 'js-cookie';
import { CookiesNames } from 'ha/constants/Cookies';

export type CookieAttrs = CookieAttr | CookieAttributes;

export type CookieClient = {
  set(name: string, value: string, options?: CookieAttrs): unknown;

  get(name: string): string | undefined;
  remove?(name: string, options?: CookieAttrs): void;
};

export interface CookieService {
  getCookie: (name: CookiesNames) => string | undefined;
  setCookie: (name: CookiesNames, value: string, options?: CookieAttrs) => void;
  removeCookie: (
    name: CookiesNames,
    options?: Pick<CookieAttrs, 'path' | 'domain'>,
  ) => void;
}

export function makeCookieService(cookie: CookieClient): CookieService {
  function getCookie(name: string): string | undefined {
    try {
      return cookie.get(name);
    } catch (error) {
      return undefined;
    }
  }

  function setCookie(name: string, value: string, options?: CookieAttrs): void {
    try {
      cookie.set(name, value, options);
    } catch {
      //
    }
  }

  function removeCookie(
    name: string,
    options?: Pick<CookieAttrs, 'path' | 'domain'>,
  ): void {
    try {
      if (cookie.remove) {
        cookie.remove(name, options);
      } else {
        cookie.set(name, '');
      }
    } catch {
      //
    }
  }

  if (!cookie || !('get' in cookie) || !('set' in cookie)) {
    if (process && process.env && process.env.NODE_ENV !== 'production') {
      throw new Error(
        'Your cookie object must implement get and set function,  {get : function(name){/*...*/}, set : function(name, value, options){/*...*/}}',
      );
    }
  }

  return {
    getCookie,
    setCookie,
    removeCookie,
  };
}
