import { MakeCall } from '@ha/api/v2/createMakeCall';
import { UnitTypeQualificationRequirement } from '../types/qualifications/QualificationRequirement';
import { RequiredDocuments } from '../types/RequiredDocuments';

const updateQualificationRequirementsByUnitType =
  (makeCall: MakeCall) =>
  (unitTypeUuid: string, data: Array<RequiredDocuments>) =>
    makeCall<UnitTypeQualificationRequirement>(
      'PUT',
      `/api/v2/qualification/requirements/by-unit-type/${unitTypeUuid}`,
      {
        data: {
          documents: data,
        },
      },
    );
export { updateQualificationRequirementsByUnitType };
