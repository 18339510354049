const MAX_COORD = 180;
const MIN_COORD = -MAX_COORD;
const NORMALIZER = 2 * MAX_COORD;

export const normalizeCoordinate = (coordinate: number): number => {
  if (coordinate >= MIN_COORD && coordinate <= MAX_COORD) {
    return coordinate;
  }

  // We need to either add or subtract 360 in order to go back to the [-180,180] range
  const sign = coordinate < 0 ? 1 : -1;

  return coordinate + sign * NORMALIZER;
};
