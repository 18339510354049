export const Kinds = {
  EntirePlace: 0,
  PrivateRoom: 1,
  SharedRoom: 2,
};

export const KindsEnum = {
  EntirePlace: 'ENTIRE_PLACE',
  PrivateRoom: 'ROOM',
  SharedRoom: 'SHARED',
};
