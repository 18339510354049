export enum ConversationsFilters {
  INBOX = 'inbox',
  PENDING = 'pending',
  FAVORITE = 'favorited',
  ARCHIVED = 'archived',
  PAID = 'paid',
  EXPIRED = 'expired',
  ALL = 'all',
  UNREAD = 'unread',
}
