export const ACTION_PREFIX = 'ALERTS';
export const STATE_NAME = 'alerts';
export const SESSION_STORAGE_KEY = 'persistentAlert';

export const Actions = {
  SEND: `${ACTION_PREFIX}/SEND`,
  SEND_PERSISTENT: `${ACTION_PREFIX}/SEND_PERSISTENT`,
  SEND_ALL: `${ACTION_PREFIX}/SEND_ALL`,
  DISMISS: `${ACTION_PREFIX}/DISMISS`,
  CLEAR: `${ACTION_PREFIX}/CLEAR`,
};
