import { Client } from '@twilio/conversations';
import { Dispatch, AnyAction } from 'redux';

import { AppServices } from 'ha/services/getAppServices';

import { RealtimeNetworkError } from '../types';

export function initRealtimeService(debug = false) {
  return (
    _: Dispatch<AnyAction>,
    __: () => void,
    { apiV2, twilio }: AppServices,
  ) =>
    apiV2.getRealtimeToken('me').then<Client>(async ({ data, status }) => {
      if (data?.token) {
        return twilio.init(data.token, {
          logLevel: debug ? 'error' : 'silent',
        });
      }

      throw new RealtimeNetworkError('Twilio Token is not provided', {
        status,
      });
    });
}
