import { Language } from '@ha/intl';
import UrlPattern from 'url-pattern';

import { capitalizeFirst } from '../string/capitalizeFirst';

export interface CityCountry {
  city: string;
  country: string;
}

export const withLang = (lang?: Language) => (lang ? `(/${lang})` : '');

export const getCityCountryUrl = (citySlug: string, countrySlug: string) =>
  `/${capitalizeFirst(citySlug)}--${capitalizeFirst(countrySlug)}`;

export const getCityUrlPattern = (lang?: Language) => {
  const prefix = withLang(lang);

  const cityUrlPattern = new UrlPattern(`${prefix}/:city--:country`);
  return cityUrlPattern;
};

/**
 * Parses a URL and extracts city and country information based on the URL structure.
 *
 * @example
 * const url = 'https://example.com/Amsterdam--Netherlands';
 * const lang = 'en';
 * const result = getCityCountry({ url, lang });
 * // result: { city: 'Amsterdam', country: 'Netherlands' }
 */
export const getCityCountry = ({
  url,
  lang,
}: {
  url: string;
  lang?: Language;
}): CityCountry | null => {
  const urlObj = new URL(url);

  const cityCountry = getCityUrlPattern(lang).match(urlObj.pathname);

  return cityCountry;
};
