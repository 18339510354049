import { MakeCall } from './createMakeCall';

interface Response {
  reply: string;
}

const addReviewReportObjection =
  (makeCall: MakeCall) =>
  ({
    reportID,
    reason,
    explanation,
  }: {
    reportID: string;
    reason: string;
    explanation: string;
  }) =>
    makeCall<Response>('POST', `/api/v2/review/report/${reportID}/objection`, {
      data: {
        reason,
        explanation,
      },
    });

export { addReviewReportObjection };
