import { set, get, del } from 'idb-keyval';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFromIndexedDB = (key: string): Promise<any> => {
  try {
    return get(key);
  } catch (e) {
    // Handle the error when the Indexed DB is not supported by the browser.
    return Promise.resolve();
  }
};

export const setInIndexedDB = (
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
): Promise<void> | undefined => {
  try {
    return set(key, value);
  } catch (e) {
    // Handle the error when the Indexed DB is not supported by the browser.
    return Promise.resolve();
  }
};

export const removeFromIndexedDB = (key: string): Promise<void> => {
  try {
    return del(key);
  } catch (e) {
    // Handle the error when the Indexed DB is not supported by the browser.
    return Promise.resolve();
  }
};
