import { GlobalState } from 'ha/types/store';

import { FavoriteListing } from '@ha/api/v2/types/FavoriteListing';
import { STATE_NAME } from '../constants';

export const isError = (state: GlobalState): boolean => state[STATE_NAME].error;

export const isFetchStarted = (state: GlobalState): boolean =>
  state[STATE_NAME].fetchStarted;

export const isFavorite = (state: GlobalState, listingId: number): boolean =>
  Boolean(state[STATE_NAME].listingIds.includes(listingId));

export const isLoading = (state: GlobalState, listingId: number): boolean =>
  Boolean(state[STATE_NAME].loadingListingIds.includes(listingId));

export const getEntries = (state: GlobalState): FavoriteListing[] =>
  Object.keys(state[STATE_NAME].listings).map(
    id => state[STATE_NAME].listings[id],
  );

export const getListingsCount = (state: GlobalState): number =>
  state[STATE_NAME].listingIds.length;

export const isPendingListingSynced = (state: GlobalState): boolean =>
  state[STATE_NAME].synced;
