import { MakeCall } from './createMakeCall';

export interface Query {
  /**
   * @description comma-separated string with possible values: `countries`, `currencies`, `videos`
   */
  expand?: string;
  listingCountryCode?: string;
}

export interface ConfigurationResponse {
  allowedCountries?: string[];
  allowedCurrencies?: string[];
  videos?: {
    availableQuota: number;
    maxSize: number;
  };
}

const getListingsConfiguration = (makeCall: MakeCall) => (query?: Query) =>
  makeCall<ConfigurationResponse>('GET', `/api/v2/unit-types/configuration`, {
    query,
  });
export { getListingsConfiguration };
