import { MakeCall } from './createMakeCall';
import {
  ChangeRequestResponse,
  BookingCancellationRequestPayload,
} from './types/ChangeRequest';

/**
 * Creates a booking cancellation request
 * @param conversationId unique conversation identifier
 */
export const requestBookingCancellation =
  (makeCall: MakeCall) =>
  (conversationId: number, payload: BookingCancellationRequestPayload) =>
    makeCall<ChangeRequestResponse>(
      'POST',
      `/api/v2/conversation/${conversationId}/request-cancelation`,
      { data: payload },
    );
