import { MakeCall } from '../createMakeCall';
import { Currency } from '../types/Currencies';

import { UnitType } from '../types/inventory/UnitType';

export interface Query {
  bookablePeriods?: UnitType['bookablePeriods'];
  currency?: Currency;
  /**
   * @description comma-separated string with possible values: `units`, `exclusions`, `exclusionsExtended`, `bookablePeriods`, `conversationCounters`, `lastMonthConversationCount`, `videos`, `costs`
   */
  expand?: string;
  lang?: string;
  from?: string;
  limit?: number;
  maxPrice?: number;
  minPrice?: number;
  offset?: number;
  orderBy?: string;
  query?: string;
  to?: string;
  userId: number;
  ids?: string;
  uuids?: string;
  withUnitTypeState?: string;
  withCharitable?: boolean;
  currencies?: string;
  propertyId?: number;
}

export interface UnitTypesResponse {
  count: number;
  unitTypes: UnitType[];
}

const getUnitTypes = (makeCall: MakeCall) => (query: Query) =>
  makeCall<UnitTypesResponse>('GET', `/api/v2/unit-types`, { query });
export { getUnitTypes };
