import UrlPattern from 'url-pattern';
import { Language } from '@ha/intl';

const pathnamePattern = new UrlPattern('/:lang(/*)');

export function getLanguageFromPathname(url: string): Language {
  const { lang = Language.en } = pathnamePattern.match(url) || {};

  return Language[lang] || Language.en;
}

const uriPattern = new UrlPattern(
  'http(s)\\://(:subdomain.):domain(.:tld)(\\::port)/:lang(/*)',
);

export function getLanguageFromURI(uri?: string): Language {
  if (uri) {
    const { lang = Language.en } = uriPattern.match(uri) || {};

    return Language[lang] || Language.en;
  }

  return Language.en;
}
