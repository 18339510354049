import { MakeCall } from './createMakeCall';

import { Subscription } from './types/Subscriptions';

export interface Payload {
  paymentMethodId?: number;
  stripePaymentMethodId?: string;
  paymentIntentReference?: string;
  currency: string;
  priceCodes: string[];
  couponId?: string;
  billingCountry?: string;
  customerName?: string;
}

const createSubscription = (makeCall: MakeCall) => (data: Payload) =>
  makeCall<Subscription>('POST', `/api/v2/billing/subscription`, {
    data,
  });
export { createSubscription };
