import { MakeCall } from './createMakeCall';
import { GetReviewSurveysPayload } from './types/ReviewSurvey';
import { ReviewSurveysRequest } from './types/ReviewSurveys';

const getReviewSurveys =
  (makeCall: MakeCall) => (query: ReviewSurveysRequest) =>
    makeCall<GetReviewSurveysPayload>('GET', `/api/v2/review/surveys`, {
      query,
    });
export { getReviewSurveys };
