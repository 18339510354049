import { ThunkMiddleware } from 'redux-thunk';

import { Client, ConnectionState, Message, State } from '@twilio/conversations';
import { AnyAction } from 'redux';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { AppServices } from 'ha/services/getAppServices';
import { GlobalState } from 'ha/types/store';
import { asyncRetry } from 'ha/utils/asyncRetry';

import {
  initRealtimeService,
  realtimeConnectionUpdated,
  receiveMessage,
} from '../actions';
import { MessageAttributes } from '../actions/receiveMessage';
import { NO_RETRY_HTTP_STATUS } from '../constants';
import { RealtimeNetworkError, RealtimeMessage } from '../types';

interface Props {
  debug: boolean;
  getChannelId: (x: GlobalState) => string | null;
}

const shouldRetry = (error: RealtimeNetworkError) => {
  return !NO_RETRY_HTTP_STATUS.includes(error.status);
};

const realtimeMessagingMiddleWare = ({
  debug,
  getChannelId,
}: Props): ThunkMiddleware<GlobalState, AnyAction, AppServices> => {
  let initialized = false;

  return ({ dispatch, getState }) =>
    next =>
    action => {
      next(action);

      const channelId = getChannelId(getState());

      if (!channelId || initialized) return;

      initialized = true;
      const initDispatch = () => dispatch(initRealtimeService(debug));

      asyncRetry<Client, RealtimeNetworkError>(initDispatch, { shouldRetry })
        .then((client: Client) => {
          client.on('stateChanged', (state: State) => {
            if (state === 'initialized') {
              client.on(
                'connectionStateChanged',
                (connectionState: ConnectionState) => {
                  dispatch(realtimeConnectionUpdated(connectionState));
                },
              );

              client
                .getConversationByUniqueName(channelId)
                .then(conversation => {
                  conversation.on('messageAdded', (message: Message) => {
                    if (message.body) {
                      dispatch(
                        receiveMessage({
                          attributes:
                            (message.attributes as unknown as MessageAttributes) ||
                            {},
                          body: JSON.parse(message.body) as RealtimeMessage,
                        }),
                      );
                    }
                  });
                })
                .catch(error => {
                  reportError(
                    new Error('Failed to get conversation by unique name', {
                      cause: error,
                    }),
                  );
                });
            }
          });
        })
        .catch(error => {
          const networkError = error as undefined | RealtimeNetworkError;

          if (
            !networkError ||
            NO_RETRY_HTTP_STATUS.includes(networkError.status)
          ) {
            return;
          }

          reportError(
            new Error('Failed to initialize Realtime Service', {
              cause: networkError,
            }),
          );
        });
    };
};
export { realtimeMessagingMiddleWare };
