import { FilterBuilder } from '../Types';

export const excludeUnitTypeBuilder: FilterBuilder = ({
  excludeUnitTypeIds = [],
}) => {
  if (!excludeUnitTypeIds || !excludeUnitTypeIds.length) {
    return '';
  }

  const idsFilter = excludeUnitTypeIds
    .filter(id => id === 0 || Boolean(id))
    .map(id => {
      return `unitTypeInternalID!=${id}`;
    });

  return idsFilter.join(' AND ');
};
