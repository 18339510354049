import { createAction } from 'redux-actions';
import { Actions } from '../constants';

export const sendAlert = createAction(Actions.SEND);
export const sendPersistentAlert = createAction(Actions.SEND_PERSISTENT);
export const sendAll = createAction(Actions.SEND_ALL);
export const dismiss = createAction(Actions.DISMISS);
export const clear = createAction(Actions.CLEAR);

export const send = data => dispatch => {
  if (!data.id) {
    // eslint-disable-next-line no-param-reassign
    data.id = new Date().getTime();
  }

  dispatch(sendAlert(data));
};
