import { FetchClient } from 'ha/helpers/FetchClient';
import { createMakeCall } from '@ha/api/v2/createMakeCall';

import {
  getPaymentRequests,
  getPaymentRequestByHandle,
  cancelPaymentRequest,
} from './paymentRequests';

import { acceptPaymentPlan } from './acceptPaymentPlan';
import { createPaymentPlanV3 } from './createPaymentPlanV3';
import { getPaymentRequestsOverview } from './getPaymentRequestsOverview';
import { getPendingPaymentPlan } from './getPendingPaymentPlan';
import { getTenantServiceFeeConfiguration } from './getTenantServiceFeeConfiguration';

export type PaymentRequest = import('./paymentRequests').PaymentRequest;
export type PayReqConversationParams =
  import('./paymentRequests').PayReqConversationParams;
export type CancelPaymentRequest =
  import('./paymentRequests').CancelPaymentRequest;
export type PaymentRequestsResponse =
  import('./paymentRequests').PaymentRequestsResponse;

const createOsiris = (fetchClient: FetchClient) => {
  const makeCall = createMakeCall(fetchClient);

  return {
    acceptPaymentPlan: acceptPaymentPlan(makeCall),
    cancelPaymentRequest: cancelPaymentRequest(makeCall),
    createPaymentPlanV3: createPaymentPlanV3(makeCall),
    getPaymentRequestByHandle: getPaymentRequestByHandle(makeCall),
    getPaymentRequests: getPaymentRequests(makeCall),
    getPaymentRequestsOverview: getPaymentRequestsOverview(makeCall),
    getPendingPaymentPlan: getPendingPaymentPlan(makeCall),
    getTenantServiceFeeConfiguration:
      getTenantServiceFeeConfiguration(makeCall),
  };
};

export { createOsiris };
