import React from 'react';

interface ConsumerProps<A> {
  children: (args: A) => React.ReactNode;
}

interface ProviderProps<A> {
  value: A;
}

function createRequiredContext<A>(displayName: string) {
  const Context = React.createContext<A | undefined>(undefined);

  Context.displayName = displayName;

  const RequiredContextProvider: React.FC<
    React.PropsWithChildren<ProviderProps<A>>
  > = ({ value, children }) => (
    <Context.Provider value={value}>{children}</Context.Provider>
  );

  const RequiredContextConsumer: React.FC<ConsumerProps<A>> = ({
    children,
  }) => (
    <Context.Consumer>
      {value => {
        if (value === undefined) {
          throw new Error(
            `Value is required in context ${Context.displayName}`,
          );
        }

        return children(value);
      }}
    </Context.Consumer>
  );

  const useRequiredContext = () => {
    const value = React.useContext(Context);

    if (value === undefined) {
      throw new Error(`Value is required in context ${Context.displayName}`);
    }

    return value;
  };

  return {
    Provider: RequiredContextProvider,
    Consumer: RequiredContextConsumer,
    useContext: useRequiredContext,
  };
}

export { createRequiredContext };
