import { OPEN, CLOSE } from '../actions';

export const initialState = {
  openModal: null,
  openQueue: [],
};

// eslint-disable-next-line default-param-last
export function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN:
      if (state.openModal) {
        return {
          ...state,
          openQueue: [...state.openQueue, action.payload.modal],
        };
      }

      return { ...state, openModal: action.payload.modal };

    case CLOSE:
      if (state.openModal) {
        const [head, ...tail] = state.openQueue;

        return {
          ...state,
          openModal: head,
          openQueue: tail,
        };
      }

      return {
        ...state,
        openQueue: state.openQueue.filter(
          modal => modal !== action.payload.modal,
        ),
      };

    default:
      return state;
  }
}
