import type { MakeCall } from '../createMakeCall';

export enum PayoutAccountTypes {
  Iban = 'iban',
  Bank = 'bank',
}

export enum PayoutTypes {
  Default = 'default',
  Listing = 'listing',
  None = 'none',
}

export interface PayoutMethod {
  accountDetails: string;
  accountName: string;
  accountType: PayoutAccountTypes;
  actualPayoutDate?: string;
  expectedPayoutDate?: string;
  methodGUID: string;
  type: PayoutTypes;
}

export type GetPaymentMethodProps = {
  listingId: number;
  paymentRequestID: string;
  userId: number;
  unitTypeID: number;
};

const getPayoutMethodRoutingPaymentReq =
  (makeCall: MakeCall) =>
  ({
    listingId,
    paymentRequestID,
    userId,
    unitTypeID,
  }: GetPaymentMethodProps): Promise<{ data: PayoutMethod }> =>
    makeCall<PayoutMethod>(
      'GET',
      `/api/v2/user/${userId}/payout-method-routing/payment-request/${paymentRequestID}`,
      { query: { listingID: listingId, unitTypeID } },
    );
export { getPayoutMethodRoutingPaymentReq };
