import { MakeCall } from './createMakeCall';

import { PostUserKYCPayload, PostUserKYCResponse } from './types/Kyc';

const postUserKyc =
  (makeCall: MakeCall) =>
  (userId: 'me' | number, data: Partial<PostUserKYCPayload>) =>
    makeCall<PostUserKYCResponse>('POST', `/api/v2/user/${userId}/kyc`, {
      data,
    });
export { postUserKyc };
