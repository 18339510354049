import { MakeCall } from './createMakeCall';
import {
  ReviewSurveyReport,
  ReviewSurveyReportResponse,
} from './types/ReviewSurveyReport';

const addReviewSurveyReport =
  (makeCall: MakeCall) =>
  ({ surveyUUID, payload }: ReviewSurveyReport) =>
    makeCall<ReviewSurveyReportResponse>(
      'POST',
      `/api/v2/review/survey/${surveyUUID}/report`,
      {
        data: { payload },
      },
    );
export { addReviewSurveyReport };
