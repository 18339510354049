import { MakeCall } from './createMakeCall';
import { User } from './types/User';

const impersonateUser =
  (makeCall: MakeCall) =>
  (
    /** id for user to impersonate, `0` for stopping impersonation */ userId: number,
  ) => {
    return makeCall<User>('PUT', `/api/v2/user/${userId}/impersonate`, {
      query: {
        isIdentityPlatform: true,
      },
    });
  };

export { impersonateUser };
