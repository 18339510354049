// eslint-disable-next-line @nx/enforce-module-boundaries
import { Params as FilterBuilderParams } from 'libs/algolia/src/lib/utils/filterBuilder/Types';

export enum RadiusValue {
  DEFAULT = 'default',
  R1 = '1km',
  R5 = '5km',
  R10 = '10km',
  R15 = '15km',
  R20 = '20km',
}

export enum RadiusValueInMiles {
  DEFAULT = 'default',
  R1 = '1mi',
  R3 = '2mi',
  R6 = '6mi',
  R10 = '10mi',
  R15 = '15mi',
}

export interface AvailabilityFilter {
  moveIn: {
    type: 'date' | 'month';
    value: string;
  };
  duration?: number;
}

export interface UserSearchFilters extends FilterBuilderParams {
  currency?: string;
  radius?: RadiusValue | RadiusValueInMiles;
  availability?: AvailabilityFilter;
}
