import { diffDay, addDays, NO_START, NO_END, getToday } from '@ha/date';

const getExclusion = (startDate: string, endDate: string) => {
  const today = getToday();
  const diffTodayStart = diffDay(today, startDate);
  const diffTodayEnd = diffDay(today, endDate);

  if (diffTodayStart < 0) {
    return { from: today, to: addDays(startDate, -1) };
  }

  if (diffTodayEnd >= 0) {
    return { from: NO_START, to: NO_END };
  }

  return { from: NO_START, to: NO_START };
};
export { getExclusion };
