import { Conversation } from './types/Conversation';
import { MakeCall } from './createMakeCall';

export interface Payload {
  messageText: string;
  restartConversation?: boolean;
  containsQuickReply?: boolean;
  editedQuickReply?: boolean;
}

export interface Query {
  expand: string;
}

const sendMessage =
  (makeCall: MakeCall) =>
  (conversationId: number, data: Payload, query?: Query) =>
    makeCall<Conversation>(
      'PUT',
      `/api/v2/conversation/${conversationId}/response`,
      { data, query },
    );
export { sendMessage };
