import { MakeCall } from './createMakeCall';

import { GetUserKYCsResponse } from './types/Kyc';

const getUserKycs =
  (makeCall: MakeCall) =>
  (
    userId: 'me' | number = 'me',
    query?: {
      limit?: number;
      offset?: number;
    },
  ) =>
    makeCall<GetUserKYCsResponse>('GET', `/api/v2/user/${userId}/kyc`, {
      query,
    });
export { getUserKycs };
