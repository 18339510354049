import dayjs from 'dayjs';

interface DatesRange {
  startDate?: Date | null;
  endDate?: Date | null;
}

interface Options {
  interval: number;
}

export const getAllDatesBetweenTwoDates = (
  dates: DatesRange,
  options: Options = { interval: 1 },
): Date[] => {
  let startDate = dayjs(dates.startDate ?? undefined).set('date', 1);
  const endDate = dayjs(dates.endDate ?? undefined).set('date', 2);

  const result = [];
  while (startDate.isBefore(endDate)) {
    result.push(startDate.toDate());
    startDate = startDate.add(options.interval, 'month');
  }
  return result;
};
