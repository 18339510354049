import { HttpStatus } from '@ha/api/v2/handleApiV2Response';

export enum Actions {
  RECEIVE_MESSAGE = 'REALTIME_RECEIVE_MESSAGE',
  REALTIME_CONNECTION_UPDATED = 'REALTIME_CONNECTION_UPDATED',
}

export enum EventTypes {
  connectionStateChanged = 'connectionStateChanged',
  messageAdded = 'messageAdded',
}

// Comming from Backend, check twilio.go
// conversationMessage is sending a message (text) and structure is like message resource in api
// conversationRead  informs that messages were read on a conversation
// conversationNew a new conversation started
export enum NotificationType {
  conversationMessage = 'conversation-message',
  conversationRead = 'conversation-read',
  conversationNew = 'conversation-new',
}

export enum ConnectionState {
  disconnected = 'disconnected',
  connecting = 'connecting',
  connected = 'connected',
  error = 'error',
  denied = 'denied',
}

export const NO_RETRY_HTTP_STATUS = [
  HttpStatus.NO_CONTENT,
  HttpStatus.UNAUTHORIZED,
  HttpStatus.FORBIDDEN,
  HttpStatus.NOT_FOUND,
  HttpStatus.LOCKED,
  HttpStatus.FAILED_DEPENDENCY,
  HttpStatus.SERVICE_UNAVAILABLE,
];
