import { MakeCall } from './createMakeCall';
import { Conversation } from './types/Conversation';

export interface CreateBookingRequestPayload {
  billingCountry?: string;
  paymentMethodId?: number | null;
  paymentMethodReference?: string;
  paymentType?: string;
  signature?: string;
  stripeToken?: string;
}

export const createBookingRequest =
  (makeCall: MakeCall) =>
  (conversationId: number, data: CreateBookingRequestPayload) =>
    makeCall<Conversation>(
      'PUT',
      `/api/v2/conversation/${conversationId}/booking-request`,
      { data },
    );
