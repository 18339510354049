import mergeWith from 'lodash-es/mergeWith';

import { isAbsoluteUrl } from 'ha/utils/isAbsoluteUrl';
import type { Logger } from '@hbf/log';

interface FetchClientOptions {
  logger?: Logger;
  timeout?: number;
  callback?: (resp: Response) => void;
  getAuthorizationHeader?: () => Promise<string | undefined>;
  requestOptions?: RequestInit;
  requestHeaders?: () => { [key: string]: string } | null;
}

export class FetchClient {
  private baseUrl: string;

  private options: RequestInit;

  private getAuthorizationHeader?: () => Promise<string | undefined>;

  private logger?: Logger;

  private timeout?: number;

  private callback?: (resp: Response) => void;

  private requestHeaders?: () => { [key: string]: string } | null;

  constructor(url: string, fetchClientOptions?: FetchClientOptions) {
    this.baseUrl = url;
    this.options = {
      credentials: 'include',
      ...fetchClientOptions?.requestOptions,
    };
    this.getAuthorizationHeader = fetchClientOptions?.getAuthorizationHeader;
    this.timeout = fetchClientOptions?.timeout;
    this.logger = fetchClientOptions?.logger;
    this.callback = fetchClientOptions?.callback;
    this.requestHeaders = fetchClientOptions?.requestHeaders;
  }

  async fetch(url: string, options?: RequestInit) {
    const mergedOptions = mergeWith(
      {},
      this.options,
      options || {},
      (baseOption, newOption) =>
        Array.isArray(baseOption) && Array.isArray(newOption)
          ? newOption
          : undefined,
    );

    if (this.getAuthorizationHeader) {
      const authHeader = await this.getAuthorizationHeader();

      if (authHeader) {
        mergedOptions.headers = {
          ...mergedOptions.headers,
          Authorization: authHeader,
        };
      }
    }

    if (this.requestHeaders) {
      const reqHeader = this.requestHeaders();

      if (reqHeader) {
        mergedOptions.headers = {
          ...mergedOptions.headers,
          ...reqHeader,
        };
      }
    }

    let timeoutId: NodeJS.Timeout | undefined;

    if (this.timeout) {
      const controller = new AbortController();
      const { signal } = controller;

      mergedOptions.signal = signal;

      timeoutId = globalThis.setTimeout(() => {
        controller.abort();
      }, this.timeout);
    }

    return fetch(
      isAbsoluteUrl(url) ? url : `${this.baseUrl}${url}`,
      mergedOptions,
    )
      .then(response => {
        if (timeoutId) {
          globalThis.clearTimeout(timeoutId);
        }

        if (this.callback) {
          this.callback(response);
        }

        return response;
      })
      .catch(error => {
        if (error.name === 'AbortError') {
          this.logger?.debug({
            message: `request aborted`,
            url,
            options,
            error,
          });
        }
        throw error;
      });
  }
}
