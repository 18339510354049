import type { MakeCall } from '../createMakeCall';
import {
  GetTenantReportQuery,
  ReviewReportResponse,
} from '../types/surveyReviews/Reports';

const getReviewReport = (makeCall: MakeCall) => (query: GetTenantReportQuery) =>
  makeCall<ReviewReportResponse>('GET', `/api/v2/review/report`, {
    query,
  });

export { getReviewReport };
