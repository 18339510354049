import { MakeCall } from './createMakeCall';

export type ConfirmUserCall = (
  userID: number,
  token: string,
) => Promise<{ data: null }>;

const confirmUser = (makeCall: MakeCall) => (userID: number, token: string) =>
  makeCall<undefined>('PUT', `/api/v2/user/${userID}/confirm`, {
    data: { token },
  });
export { confirmUser };
