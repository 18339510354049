import type { MakeCall } from '../createMakeCall';
import { PayInResource } from './types';

export type Response = PayInResource;

const confirmPayIn =
  (makeCall: MakeCall) => (uuid: string, settlementReference: string) =>
    makeCall<Response>('PUT', `/api/v2/payment/pay-in/${uuid}/confirm`, {
      data: { settlementReference },
    });
export { confirmPayIn };
