import * as qs from 'query-string';

import { AnyPage } from '@ha/contentful';

import { FetchClient } from 'ha/helpers/FetchClient';
import { preprocessResponse } from 'ha/utils/preprocessResponse';

export interface HermesLoadOptions {
  includeData?: boolean;
}

export function makeHermesService(fetchClient: FetchClient) {
  return {
    load: (pathname: string, options?: HermesLoadOptions): Promise<AnyPage> => {
      const encodedUrl = encodeURIComponent(pathname);

      return fetchClient
        .fetch(`/api/v1/resource/${encodedUrl}?${qs.stringify(options || {})}`)
        .then(preprocessResponse);
    },
  };
}
