import { Loader } from '@googlemaps/js-api-loader';

import { Language } from '@ha/intl';

import { addUrlSignature } from 'ha/server/utils/googleMap/signUrl';

interface Config {
  key: string;
  language: Language;
  secret?: string;
}

type CustomizedConfig = Partial<Omit<Config, 'key'>>;

const ERROR_MESSAGE = 'init() have not been called';
const ERROR_MESSAGE_SSR_ONLY = 'method is allowed  to be called only on server';
const ERROR_MESSAGE_SECRET_IS_MISSING = 'the secret is missing';

const GOOGLE_MAP_BASE_URL = 'https://maps.googleapis.com';
const GOOGLE_STATIC_MAP_PATH = '/maps/api/staticmap';

class GoogleMapsApi {
  #config: Config;

  #client: typeof google.maps;

  #placesService: google.maps.places.PlacesService;

  #autocompleteService: google.maps.places.AutocompleteService;

  #autocomplete: typeof google.maps.places.Autocomplete;

  #geocoder: google.maps.Geocoder;

  constructor(config: Config) {
    this.#config = config;
  }

  async init({ language }: CustomizedConfig = {}) {
    if (!this.#client) {
      const googleLoader = new Loader({
        apiKey: this.#config.key,
        language: language || this.#config.language,
        libraries: ['places'],
      });

      const { maps } = await googleLoader.load();
      this.#client = maps;

      this.#placesService = new this.#client.places.PlacesService(
        document.createElement('div'),
      );

      this.#autocompleteService = new this.#client.places.AutocompleteService();

      this.#autocomplete = this.#client.places.Autocomplete;

      this.#geocoder = new this.#client.Geocoder();
    }

    return Promise.resolve(this);
  }

  get client() {
    if (!this.#client) {
      throw new Error(ERROR_MESSAGE);
    }

    return this.#client;
  }

  get placesService() {
    if (!this.#placesService) {
      throw new Error(ERROR_MESSAGE);
    }

    return this.#placesService;
  }

  get autocompleteService() {
    if (!this.#autocompleteService) {
      throw new Error(ERROR_MESSAGE);
    }

    return this.#autocompleteService;
  }

  get autocomplete() {
    if (!this.#autocomplete) {
      throw new Error(ERROR_MESSAGE);
    }

    return this.#autocomplete;
  }

  get geocoder() {
    if (!this.#geocoder) {
      throw new Error(ERROR_MESSAGE);
    }

    return this.#geocoder;
  }

  // creates a signed static google map ULR
  getStaticMapUrl = (query: string) => {
    if (typeof window !== 'undefined') {
      throw new Error(ERROR_MESSAGE_SSR_ONLY);
    }

    if (!this.#config.secret) {
      throw new Error(ERROR_MESSAGE_SECRET_IS_MISSING);
    }
    const googleUrlToSign = `${GOOGLE_STATIC_MAP_PATH}?key=${
      this.#config.key
    }&${query}`;

    return (
      GOOGLE_MAP_BASE_URL +
      addUrlSignature(googleUrlToSign, this.#config.secret)
    );
  };
}

const GoogleMapApi = (config: Config) => new GoogleMapsApi(config);
export { GoogleMapApi };
export type GoogleMapApiType = GoogleMapsApi;
