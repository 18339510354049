import { handleActions } from '@housinganywhere/safe-redux';

import { SubscriptionProduct } from '@ha/api/v2/types/Subscriptions';
import type { SubscriptionUserType } from '@ha/api/v2/getSubscriptionProductsByHandle';
import { ActionsType, ActionTypes } from '../actions/loadProduct';

interface State {
  product?: SubscriptionProduct;
  loading: boolean;
  error: boolean;
  productType: SubscriptionUserType;
}

export const initialState: State = {
  product: undefined,
  error: false,
  loading: false,
  productType: 'tenant',
};
const product = handleActions<State, ActionTypes, ActionsType>(
  {
    [ActionTypes.START]: (state, { payload }) => ({
      ...state,
      error: false,
      loading: true,
      productType: payload,
    }),
    [ActionTypes.DONE]: (state, { payload }) => ({
      ...state,
      product: payload,
      loading: false,
      error: false,
    }),
    [ActionTypes.FAIL]: state => ({
      ...state,
      error: true,
      loading: false,
    }),
  },
  initialState,
);
export { product };
