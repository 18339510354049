import { parse } from 'cookie';

export enum AuthCookies {
  RefreshToken = '__ha_rt',
  AccessToken = '__ha_at',
  CodeVerifier = '__ha_cv',
  State = '__ha_s',
  IdpSession = '__ha_idp_session',
  IdpSignedIn = '__ha_idp_in',
}

export interface CookieSet {
  refreshToken?: string;
  accessToken?: string;
  codeVerifier?: string;
  state?: string;
}

const withEmptyValue = (value: string | undefined): string | undefined => {
  // To delete a cookie, we explicitly set it to '0' before. This means that a cookie with value '0' is to be interpreted as not having a value.
  // TODO: remove check against 0 once enough time passed for browsers to expire them
  if (value === undefined || value === '' || value === '0') {
    return undefined;
  }
  return value;
};

export const parseAuthCookies = (
  cookie: string,
  extraCookies: string[] = [],
): CookieSet & Record<(typeof extraCookies)[number], string | undefined> => {
  if (!cookie) {
    return {};
  }

  const result: Record<string, string | undefined> = parse(cookie);

  return {
    refreshToken: withEmptyValue(result[AuthCookies.RefreshToken]),
    accessToken: withEmptyValue(result[AuthCookies.AccessToken]),
    codeVerifier: withEmptyValue(result[AuthCookies.CodeVerifier]),
    state: withEmptyValue(result[AuthCookies.State]),
    ...extraCookies.reduce((acc, item) => {
      acc[item] = withEmptyValue(result[item]);
      return acc;
    }, {} as Record<string, string | undefined>),
  };
};
