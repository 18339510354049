import { getMonthsBetween, addDays } from '@ha/date';

import { FilterBuilder } from '../Types';

const minimumStayMonthsBuilder: FilterBuilder = (
  {
    startDate: startDateFilterValue,
    endDate: endDateFilterValue,
    flexDays: flexDaysFilterValue,
  },
  context,
) => {
  const flexDays = flexDaysFilterValue || 0;
  const startDate =
    context?.withSrpDates && startDateFilterValue
      ? addDays(startDateFilterValue, -flexDays)
      : startDateFilterValue;
  const endDate =
    context?.withSrpDates && endDateFilterValue
      ? addDays(endDateFilterValue, flexDays)
      : endDateFilterValue;

  if (startDate && endDate) {
    return `(minimumStayMonths<=${getMonthsBetween(startDate, endDate)})`;
  }

  return '';
};

export { minimumStayMonthsBuilder };
