import { AdvertiserRatingValue } from '../../../types/SearchFilters';
import { FilterBuilder } from '../Types';

const advertiserRatingBuilder: FilterBuilder = params => {
  const { advRating } = params;

  if (!advRating) {
    return '';
  }

  const ratingsHash = {
    [AdvertiserRatingValue.OVER_FOUR]: 'advertiserMoveInOverallRating>=4',
    [AdvertiserRatingValue.OVER_THREE]: 'advertiserMoveInOverallRating>=3',
    [AdvertiserRatingValue.UNRATED]:
      'advertiserMoveInReviewCount<3 AND advertiserMoveInOverallRating<3',
  };

  return ratingsHash[advRating];
};

export { advertiserRatingBuilder };
