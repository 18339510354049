import { MakeCall } from './createMakeCall';
import { IdentityVerificationResponse } from './types/Kyc';

const getIdentityVerificationRedirectURL =
  (makeCall: MakeCall) => (userId: 'me' | number, returnURL: string) =>
    makeCall<IdentityVerificationResponse>(
      'POST',
      `/api/v2/user/${userId}/verification`,
      { data: { returnURL } },
    );

export { getIdentityVerificationRedirectURL };
