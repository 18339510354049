import { MakeCall } from './createMakeCall';

import { SubscriptionProduct } from './types/Subscriptions';

export interface Products {
  count: number;
  products: SubscriptionProduct[];
}

const getProducts = (makeCall: MakeCall) => () =>
  makeCall<Products>('GET', `/api/v2/billing/subscriptions/products`);

export { getProducts };
