import { createSelector } from 'reselect';

import { Experiments } from 'ha/constants/experiments';

import { GlobalState } from 'ha/types/store';

import { getSelectedVariants } from 'ha/modules/Experiments/selectors';

import { STATE_NAME } from './constants';
import { ABExperiment } from './types';

export const getFeatureFlags = (state: GlobalState) => state[STATE_NAME];

const listingCountriesExcludedFromIDPExperiment = ['NL'];
export const getIDPRearrangementExperiment = (listingCountryCode?: string) =>
  createSelector(
    [getSelectedVariants, getFeatureFlags],
    (experiments, featureFlags) => {
      const variant = experiments[Experiments.TX2255.name] as ABExperiment;
      const isIDPRearrangementExperimentActive =
        featureFlags.IDPRearrangementExperiment === 'on';

      const isListingCountryQualified = Boolean(
        listingCountryCode &&
          !listingCountriesExcludedFromIDPExperiment.includes(
            listingCountryCode.toUpperCase(),
          ),
      );

      return {
        isIDPRearrangementExperimentActive,
        IDPRearrangementExperimentVariant: variant,
        enableIDPRearrangementExperiment:
          variant === 'B' &&
          isIDPRearrangementExperimentActive &&
          isListingCountryQualified,
      };
    },
  );
