import { Conversation } from './types/Conversation';
import { MakeCall } from './createMakeCall';

export type GetConversationByToken = (
  token: string,
) => Promise<{ data: Conversation }>;

const getConversationByToken = (makeCall: MakeCall) => (token: string) =>
  makeCall<Conversation>('GET', `/api/v2/conversations/token/${token}`);
export { getConversationByToken };
