import { handleActions } from '@housinganywhere/safe-redux';

import { ActionsUnionType, ActionTypes } from './actions';
import { FeatureFlags } from './types';

export const initialState = {};

export const reducer = handleActions<
  FeatureFlags,
  ActionTypes,
  ActionsUnionType
>(
  {
    [ActionTypes.SET]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  initialState,
);
