import { createRequiredContext } from 'ha/core/RequiredContext';
import { Config } from 'ha/config';

const ConfigContext = createRequiredContext<Config>('ConfigContext');

export const ConfigProvider = ConfigContext.Provider;
export const ConfigConsumer = ConfigContext.Consumer;
export const useConfig = ConfigContext.useContext;

export { ConfigContext };
