import { FilterBuilder } from '../Types';

const apartmentBuilder: FilterBuilder = ({ apartmentIds = [] }) => {
  if (!apartmentIds || !apartmentIds.length) {
    return '';
  }

  const idsFilter = apartmentIds
    .filter(id => id !== '')
    .map(id => {
      return `apartmentId:"${id}"`;
    });

  return idsFilter.join(' OR ');
};

export { apartmentBuilder };
