import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';
import { FeatureFlags } from '../types';

export enum ActionTypes {
  SET = '[FeatureFlags] SET',
}

export const Actions = {
  set: (data: FeatureFlags) => createAction(ActionTypes.SET, data),
};

export type ActionsUnionType = ActionsUnion<typeof Actions>;
