import { TypeValue } from 'ha/types/SearchFilters';
import { defineKey as T } from '@ha/intl';

export const STATE_NAME = 'searchAlertListings';

export enum Actions {
  GET_SEARCH_ALERT_START = 'SearchAlertListings.GET_SEARCH_ALERT_START',
  GET_SEARCH_ALERT_DONE = 'SearchAlertListings.GET_SEARCH_ALERT_DONE',
  DELETE_SEARCH_ALERT_DONE = 'SearchAlertListings.DELETE_SEARCH_ALERT_DONE',
  ENABLE_SEARCH_ALERT_DONE = 'SearchAlertListings.ENABLE_SEARCH_ALERT_DONE',
  DISABLE_SEARCH_ALERT_DONE = 'SearchAlertListings.DISABLE_SEARCH_ALERT_DONE',
  EDIT_SEARCH_ALERT_DONE = 'SearchAlertListings.EDIT_SEARCH_ALERT_DONE',
  GET_SEARCH_ALERT_RESULTS_COUNT_START = 'SearchAlertListings.GET_SEARCH_ALERT_RESULTS_COUNT_START',
  GET_SEARCH_ALERT_RESULTS_COUNT_DONE = 'SearchAlertListings.GET_SEARCH_ALERT_RESULTS_COUNT_DONE',
  GET_SEARCH_ALERT_RESULTS_COUNT_FAIL = 'SearchAlertListings.GET_SEARCH_ALERT_RESULTS_COUNT_FAIL',
}

export const KindsToCategories = {
  'entire place': [TypeValue.APARTMENT, TypeValue.STUDIO],
  'private room': [TypeValue.PRIVATE_ROOM],
  'shared room': [TypeValue.SHARED_ROOM],
};

export const KindsToCategoriesTranslated = {
  'entire place': [T('Apartment'), T('Studio')],
  'private room': [T('Private Room')],
  'shared room': [T('Shared Room')],
};
