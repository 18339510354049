import { MakeCall } from './createMakeCall';

/**
 * Handles add tags to taxpayer info for the taxpayer uuid (compliance)
 * @returns tax payer tags details response
 */

export const createTaxPayerTags =
  (makeCall: MakeCall) => (taxPayerInfoUuid: string, payload: string) =>
    makeCall<string[]>(
      'PUT',
      `/api/v2/legal/taxpayer/${taxPayerInfoUuid}/tags`,
      {
        data: {
          tags: [`unit-type-uuid:${payload}`],
        },
      },
    );
