import { MakeCall } from './createMakeCall';

export interface Channels {
  user: string;
  conversations: string[];
}

export interface RealtimeResponse {
  identity: string;
  token: string;
  channels: Channels;
}

type UserId = number | 'me';

export type GetRealtimeTokenCall = (userId: UserId) => Promise<{
  data: RealtimeResponse;
  status: number;
}>;

const getRealtimeToken = (makeCall: MakeCall) => (userId: UserId) =>
  makeCall<RealtimeResponse>('GET', `/api/v2/user/${userId}/realtime`);
export { getRealtimeToken };
