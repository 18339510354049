export enum TopicCluster {
  BeforeTenancy = 'beforeTenancy',
  CaseStudies = 'caseStudies',
  DuringTenancy = 'duringTenancy',
  HelpCenter = 'helpCenter',
  HousingTrends = 'housingTrends',
  Investing = 'investing',
  Laws = 'laws',
  LivingIn = 'livingIn',
  MovingAbroad = 'movingAbroad',
  News = 'news',
  PreRenting = 'preRenting',
  ProductNews = 'productNews',
  ProductUpdates = 'productUpdates',
  RentalLaws = 'rentalLaws',
  RentingAbroad = 'rentingAbroad',
  RentingOut = 'rentingOut',
  Studying = 'studying',
  Working = 'working',
}
