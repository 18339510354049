import { MakeCall } from './createMakeCall';

import { PayoutMethod } from './types/PayoutMethods';
import { PayoutMethodPayload } from './types/UserPayoutMethod';

const addPayoutMethod = (makeCall: MakeCall) => (data: PayoutMethodPayload) =>
  makeCall<PayoutMethod>('POST', `/api/v2/user/me/payout-method`, {
    data,
  });
export { addPayoutMethod };
