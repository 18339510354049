import type { MakeCall } from '../createMakeCall';

interface LinkPayoutMethodResponse {
  isChanged?: boolean;
}

const linkPayoutMethod =
  (makeCall: MakeCall) =>
  (payoutMethodUuid: string, settlementReference: string) =>
    makeCall<LinkPayoutMethodResponse>(
      'PUT',
      '/api/v2/payout/link-payout-method',
      {
        data: { payoutMethodUuid, settlementReference },
      },
    );

export { linkPayoutMethod };
