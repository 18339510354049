interface ChoiceResult {
  outcome: 'accepted' | 'dismissed';
}

interface Options {
  onChoice: (outcome: ChoiceResult) => void;
  onError: (error: Error) => void;
}

// https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent
interface BeforeInstallPromptEvent extends Event {
  userChoice: Promise<ChoiceResult>;
}

const handleInstallPrompt =
  ({ onChoice, onError }: Options) =>
  (event: BeforeInstallPromptEvent) => {
    event.userChoice.then(onChoice).catch(onError);
  };

export { handleInstallPrompt };
