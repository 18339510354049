import type { MakeCall } from '../createMakeCall';
import {
  BillingInformation,
  BillingInformationDetails,
} from '../types/BillingInformation';

export interface APICreateMultiBillingData {
  billingInfo: BillingInformation;
}

const updateMultiBillingInformation =
  (makeCall: MakeCall) =>
  (data: BillingInformationDetails, billingInfoUUID: string) =>
    makeCall<APICreateMultiBillingData>(
      'PUT',
      `/api/v2/billing/billing-info/${billingInfoUUID}`,
      {
        data,
      },
    ).then(res => ({
      ...res,
      data: {
        billingInformation: res.data.billingInfo,
      },
    }));

export { updateMultiBillingInformation };
