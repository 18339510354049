import { MakeCall } from './createMakeCall';

import { KycCountries } from './types/Kyc';

export interface Query {
  asCountryOfResidence?: boolean;
}

const getKycCountries =
  (makeCall: MakeCall) =>
  (userId: 'me' | number = 'me', query?: Query) =>
    makeCall<KycCountries>('GET', `/api/v2/user/${userId}/kyc-countries`, {
      query,
    });
export { getKycCountries };
