import { MakeCall, MakeCallPromise } from '../createMakeCall';

export interface CommissionPlan {
  percentage: number;
  startDate: string;
}

const getCommissionPlan =
  (makeCall: MakeCall) =>
  (userId: number): MakeCallPromise<CommissionPlan> =>
    makeCall<CommissionPlan>('GET', `/api/v2/user/${userId}/commission-plan`);

export { getCommissionPlan };
