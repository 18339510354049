import dayjs from 'dayjs';

import { addHours, dateToTimestamp } from '@ha/date';

import { FilterBuilder } from '../Types';

const publishedDateTSBuilder: FilterBuilder = (params, context) => {
  let { publishedBeforeHrs } = params;

  if (!publishedBeforeHrs && context?.searchAlert === 'daily') {
    publishedBeforeHrs = 24;
  }
  if (!publishedBeforeHrs && context?.searchAlert === 'instant') {
    publishedBeforeHrs = 1;
  }

  if (!publishedBeforeHrs) {
    return '';
  }

  // current time but with 0 mins, 0 secs, and 0 ms
  const currentDateTime = dayjs().startOf('hour').toISOString();

  const publishedDate = addHours(currentDateTime, -publishedBeforeHrs);
  const publishedDateTS = dateToTimestamp(new Date(publishedDate));

  return `publishedDateTS>${publishedDateTS}`;
};

export { publishedDateTSBuilder };
