import { Dispatch } from 'redux';
import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';
import { Device } from 'ha/types/Device';
import { BreakPoints } from './constants';
import { Ui } from './types';

export enum ActionTypes {
  SET_DEVICE = 'UI/SET_DEVICE',
  SET_MEDIA_BREAKPOINTS = 'UI/SET_MEDIA_BREAKPOINTS',
}

export const setDevice = (device: Device) =>
  createAction(ActionTypes.SET_DEVICE, device);
export const setMediaBreakpoints = (
  breakpoints: Partial<Ui['mediaQueryBreakpoints']>,
) => createAction(ActionTypes.SET_MEDIA_BREAKPOINTS, breakpoints);

const Actions = {
  setDevice,
  setMediaBreakpoints,
};

export type ActionsUnionType = ActionsUnion<typeof Actions>;

export const setupMediaQueryListeners = () => (dispatch: Dispatch) => {
  const media: Array<[string, MediaQueryList]> = Object.entries(
    BreakPoints,
  ).map(([name, value]) => [
    name,
    window.matchMedia(`(min-width: ${value}em)`),
  ]);

  const commonHandler = () =>
    dispatch(
      Actions.setMediaBreakpoints(
        media.reduce(
          (acc, [name, query]) => ({
            ...acc,
            [name]: query.matches,
          }),
          {
            lg: false,
            md: false,
            sm: false,
            xl: false,
            xs: false,
          },
        ),
      ),
    );

  media.forEach(([, q]) => q.addListener(commonHandler), media);

  commonHandler();
};
