import React from 'react';

import {
  Callback,
  ERROR_MESSAGES,
  OneTrustCategoryIds,
} from '@hbf/cookie-consent';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { useConfig } from 'ha/helpers/config';
import { isEnabled, useFeatureFlags } from 'ha/modules/FeatureFlags';
import { useServices } from 'ha/services';
import { CookieConsent } from 'ha/services/analytics/types';
import { optInTracking, optOutTracking } from 'ha/utils/unless';

import { useCookieConsentContext } from '../contexts';
import { initializeGoogleConsent } from '../utils';

export const INITIALISATION_ERROR =
  'Error cookie consent client initialisation';

const useCookieConsent = () => {
  const { analytics } = useServices();
  const { unless } = useConfig();
  const {
    cookieConsentBanner,
    enableAnonymousTracking,
    enableGoogleConsent,
    enableRudderstackBeacon,
  } = useFeatureFlags();
  const { client } = useCookieConsentContext();

  const initAnalyticsCallback = React.useCallback(
    (cookieConsent?: CookieConsent) => {
      analytics.init({
        cookieConsent,
        useBeacon: isEnabled(enableRudderstackBeacon),
      });
    },
    [analytics, enableRudderstackBeacon],
  );

  const initUnlessCallback = React.useCallback(() => {
    if (unless.enabled) {
      optInTracking();
    }
  }, [unless]);

  React.useEffect(() => {
    const isCookieConsentEnabled = isEnabled(cookieConsentBanner);

    if (!isCookieConsentEnabled) {
      // Regular flow without cookie consent.
      if (isEnabled(enableGoogleConsent)) {
        initializeGoogleConsent();
      }

      initAnalyticsCallback();
      initUnlessCallback();
      return;
    }

    if (!client) {
      return;
    }

    const cookieConsent: CookieConsent = {
      isCookieConsentEnabled,
      isAnonymousTrackingEnabled: isEnabled(enableAnonymousTracking),
      isConsentGiven: client.isConsentGiven(),
      hasUserRejectedAllCategories: true, // strictly necessary by default
    };

    const callbacks: Callback[] = [
      {
        requiredCategoryIds: [OneTrustCategoryIds.StrictlyNecessary],
        callback: () => optOutTracking('essential'),
      },
      {
        requiredCategoryIds: [OneTrustCategoryIds.Performance],
        callback: initUnlessCallback,
      },
    ];

    if (!cookieConsent.isConsentGiven) {
      initAnalyticsCallback(cookieConsent);
    } else {
      callbacks.push({
        requiredCategoryIds: [OneTrustCategoryIds.StrictlyNecessary],
        callback: () =>
          initAnalyticsCallback({
            ...cookieConsent,
            hasUserRejectedAllCategories: client.hasUserRejectedAllCategories(),
          }),
      });
    }

    client.init(callbacks).catch((error: Error) => {
      // Some browsers block script loading, the issue is known
      // https://housinganywhere.atlassian.net/browse/BUS-588
      if (error?.message === ERROR_MESSAGES.LOADING_ONETRUST_SCRIPT) {
        return;
      }

      reportError(INITIALISATION_ERROR, {
        metaData: { error },
      });
    });
  }, [
    client,
    cookieConsentBanner,
    enableAnonymousTracking,
    enableGoogleConsent,
    initAnalyticsCallback,
    initUnlessCallback,
  ]);
};

export { useCookieConsent };
