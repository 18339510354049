import { MakeCall } from './createMakeCall';

export interface Payload {
  listingId: number;
  startDate?: string;
  endDate?: string;
}

const createProspectiveLead = (makeCall: MakeCall) => (data: Payload) => {
  return makeCall<undefined>('POST', `/api/v2/conversations/prospective-lead`, {
    data,
  });
};
export { createProspectiveLead };
