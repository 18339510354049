import { Dispatch } from 'redux';
import { Action } from '@housinganywhere/safe-redux';
import { ActionTypes } from '../actions';

function handleOpenNewTab(url: string) {
  window.open(url, '_blank');
}

export function newTabMiddleware() {
  return () =>
    (next: Dispatch) =>
    (action: Action<string, { url: string }>) => {
      if (action?.type === ActionTypes.NEW_TAB) {
        handleOpenNewTab(action.payload.url);
      }
      next(action);
    };
}
