import { handleActions } from '@housinganywhere/safe-redux';

import { City } from '@ha/api/v2/types';

import { ActionTypes, ActionsUnionType } from '../actions/actions';

interface State {
  entities: City[];
  error: boolean;
  loaded: boolean;
  loading: boolean;
}

const reducer = handleActions<State, ActionTypes, ActionsUnionType>(
  {
    [ActionTypes.LOAD_START]: state => ({
      ...state,
      loading: true,
    }),
    [ActionTypes.LOAD_DONE]: (state, action) => ({
      ...state,
      entities: [...state.entities, ...action.payload],
      loading: false,
      error: false,
      loaded: true,
    }),
    [ActionTypes.LOAD_FAIL]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  {
    entities: [],
    error: false,
    loaded: false,
    loading: false,
  },
);
export { reducer };
