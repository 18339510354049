import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';
import { User } from '@ha/api/v2/types/User';
import {
  ImpersonationInfo,
  MyUserInfoApiV2,
} from '@ha/api/v2/types/UserInformation';
import { AuthLogicTypes } from 'ha/modules/AuthLogic/constants';

export const AuthLogicActions = {
  updateUser: (payload: Partial<User>) =>
    createAction(AuthLogicTypes.updateUser, payload),
  initUser: (payload: { user: User; userInformation: MyUserInfoApiV2 }) =>
    createAction(AuthLogicTypes.initUser, payload),
  fulfillImpersonation: (payload: ImpersonationInfo) =>
    createAction(AuthLogicTypes.fulfillImpersonation, payload),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AuthLogicActions = ActionsUnion<typeof AuthLogicActions>;
