import { MakeCall } from './createMakeCall';
import { ListingExclusionsResponse } from './getListingExclusions';

const replacesListingExclusions =
  (makeCall: MakeCall) =>
  (
    listingId: number,
    {
      rangeFrom,
      rangeTo,
      dates,
      adjustDates = true,
      actionToken,
    }: ListingExclusionsResponse & {
      adjustDates?: boolean;
      actionToken?: string;
    },
  ) =>
    makeCall<ListingExclusionsResponse>(
      'PUT',
      `/api/v2/listing/${listingId}/exclusions`,
      {
        data: { dates, rangeFrom, rangeTo, adjustDates, actionToken },
      },
    );
export { replacesListingExclusions };
