import { MakeCall } from './createMakeCall';

const getListingIcsCalendarLink =
  (makeCall: MakeCall) => (listingId: number, hash: string, lang?: string) =>
    makeCall<undefined>(
      'GET',
      `/api/v2/listing/${listingId}/calendar/${hash}`,
      {
        query: { lang },
      },
    );
export { getListingIcsCalendarLink };
