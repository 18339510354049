// get list of payment method types and possible details

import type { MakeCall } from '../createMakeCall';
import { PayInMethod, PaymentMethodDetail } from './types';

type Response = {
  methodTypes: Array<{
    type: PayInMethod;
    detail: PaymentMethodDetail;
  }>;
};

const getPaymentMethodTypes = (makeCall: MakeCall) => () =>
  makeCall<Response>('GET', `/api/v2/payment/method-types`);
export { getPaymentMethodTypes };
