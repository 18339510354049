export enum Locale {
  en = 'en-gb',
  fr = 'fr',
  de = 'de',
  es = 'es',
  it = 'it',
  nl = 'nl',
  pt = 'pt',
  zh = 'zh-cn',
  uk = 'uk',
  ru = 'ru',
  pl = 'pl',
  sv = 'sv',
  ro = 'ro',
  us = 'en-us',
}
