import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';

import { HttpStatus } from '@ha/api/v2/handleApiV2Response';
import { AnyPage } from '@ha/contentful';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { Thunk } from 'ha/myredux/types';
import { HermesLoadOptions } from 'ha/services/hermes';
import { GlobalState } from 'ha/types/store';

import { getPath } from 'ha/modules/Hermes/utils/getPath';

import { getCurrentLanguageCode } from '../LanguageSwitcher/selectors';

import { getInfo, getData } from './selectors';

export enum ActionTypes {
  START = '[hermes] LOAD_START',
  DONE = '[hermes] LOAD_DONE',
  NOT_FOUND = '[hermes] NOT_FOUND',
  FAIL = '[hermes] LOAD_FAIL',
}

export const Actions = {
  start: () => createAction(ActionTypes.START),
  done: (data: AnyPage) => createAction(ActionTypes.DONE, data),
  notFound: () => createAction(ActionTypes.NOT_FOUND),
  fail: () => createAction(ActionTypes.FAIL),
};

export type ActionsUnionType = ActionsUnion<typeof Actions>;

export const loadHermesData =
  (
    pathname: string,
    options: HermesLoadOptions = { includeData: true },
  ): Thunk<GlobalState> =>
  (dispatch, getState, { hermes }) => {
    const state = getState();
    const info = getInfo(state);

    const infoIsLoaded = info && info.path === pathname;
    const dataIsLoaded = options.includeData ? Boolean(getData(state)) : true;

    if (infoIsLoaded && dataIsLoaded) {
      return Promise.resolve();
    }

    dispatch(Actions.start());
    const language = getCurrentLanguageCode(state);
    const path = getPath(pathname, language);

    return hermes
      .load(path, options)
      .then(data => {
        dispatch(Actions.done(data));
      })
      .catch(error => {
        // Both these status codes are expected from Hermes
        // 404 means Contentful does not have data for the City and Country
        // 304 means the data has not changed since the last call, but Fetch API
        // treats it as a NOT OK response, therefore it's an error
        if (error?.code === HttpStatus.NOT_MODIFIED) {
          return;
        }

        if (error?.code === HttpStatus.NOT_FOUND) {
          dispatch(Actions.notFound());
          return;
        }

        dispatch(Actions.fail());

        try {
          const isError = error instanceof Error;

          reportError('loadHermesData failed', {
            metaData: {
              pathname,
              options,
              errorCode: error?.code || 'undefined',
              isError,
              stack: isError ? error.stack : '',
            },
          });
        } catch {
          // ignore
        }
      });
  };
