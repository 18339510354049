import { Language } from '@ha/intl';
import { preprocessResponse } from 'ha/utils/preprocessResponse';
import { Category } from '@ha/contentful';
import { FetchClient } from 'ha/helpers/FetchClient';

interface CountryRequestParams {
  code: string;
  lang: Language;
}

interface City {
  cityCanonical: string;
  cityName: string;
  countryName: string;
  categories: Category[] | null;
  latitude: number;
  longitude: number;
  mapRadius: number;
  isFeaturedCity: boolean;
}

export interface CountryResponse {
  cities: City[];
}

interface CityRequestParams {
  countryCode: string;
  name: string;
  lang: Language;
}

export interface CityResponse {
  categories: string[];
}

export function makeAlmanacService(fetchClient: FetchClient) {
  return {
    loadCity: ({
      countryCode,
      name,
      lang,
    }: CityRequestParams): Promise<CityResponse> =>
      fetchClient
        .fetch(
          `/api/v2/countries/${countryCode}/cities/${name}?language=${lang}`,
        )
        .then(preprocessResponse),
    loadCountry: ({
      code,
      lang,
    }: CountryRequestParams): Promise<CountryResponse> =>
      fetchClient
        .fetch(`/api/v2/countries/${code}/?language=${lang}`)
        .then(preprocessResponse),
  };
}
