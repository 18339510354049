import isNil from 'lodash-es/isNil';

export function isDefined(value: unknown): boolean {
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  if (typeof value === 'object' && value !== null) {
    const nestedValues = Object.values(value).filter(isDefined);

    return nestedValues.length > 0;
  }

  return !isNil(value);
}
