import { UserSearch } from '@ha/api/v2/types/UserSearch';
import { STATE_NAME, ActionsTypes, SavedSearchActions } from '../constants';

export type State = Readonly<{
  list: UserSearch[];
  loading: boolean;
  loaded: boolean;
  errors: Array<{}>;
  count: number;
}>;

export interface RootState {
  [STATE_NAME]: State;
}

export const initialState: State = {
  errors: [],
  list: [],
  loaded: false,
  loading: false,
  count: 0,
};

export function savedSearchesReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: State = initialState,
  action: SavedSearchActions,
): State {
  switch (action.type) {
    case ActionsTypes.LOAD_START:
      return { ...state, loading: true };
    case ActionsTypes.LOAD_SUCCESS:
      return {
        ...state,
        list: action.payload.searches,
        loading: false,
        loaded: true,
        count: action.payload.count,
      };
    case ActionsTypes.LOAD_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
}

const getRootState = (state: RootState) => state[STATE_NAME];

export const getSavedSearches = (state: RootState) => getRootState(state).list;
export const getIsLoading = (state: RootState) => getRootState(state).loading;
export const getIsLoaded = (state: RootState) => getRootState(state).loaded;
