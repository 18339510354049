export const STATE_NAME = 'experiments';

export enum ActionTypes {
  SET_VARIANTS = 'EXPERIMENTS/SET_VARIANTS',
  PLAY_EXPERIMENT = 'EXPERIMENTS/PLAY_EXPERIMENT',
}

// https://housinganywhere.atlassian.net/browse/CAAI-1370
export const RankingExperimentWhitelistedCities = [
  'amsterdam',
  'athens',
  'barcelona',
  'berlin',
  'bologna',
  'brussels',
  'budapest',
  'florence',
  'frankfurt am main',
  'hamburg',
  'lisbon',
  'ljubljana',
  'madrid',
  'milan',
  'munich',
  'new york city',
  'padova',
  'paris',
  'porto',
  'prague',
  'rome',
  'rotterdam',
  'turin',
  'valencia',
  'vienna',
];
