import { MakeCall } from './createMakeCall';

import { UserDocument, UserDocumentCategory } from './types/UserDocument';

const editUserDocument =
  (makeCall: MakeCall) =>
  (
    userId: 'me' | number,
    docUUID: string,
    data: {
      title?: string;
      filename?: string;
      category?: UserDocumentCategory;
    },
  ) =>
    makeCall<UserDocument>(
      'PUT',
      `/api/v2/user/${userId}/document/${docUUID}`,
      {
        data,
      },
    );
export { editUserDocument };
