import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';
import { Thunk } from 'ha/myredux/types';
import { GlobalState } from 'ha/types/store';

import { track } from '@hbf/analytics';
import { getPlayedExperiments } from './selectors';
import { ActionTypes } from './constants';

export const Actions = {
  setVariants: (
    variants: { [name: string]: string },
    userIdentifier?: string,
  ) =>
    createAction(ActionTypes.SET_VARIANTS, {
      variants,
      userIdentifier,
    }),
  play: (name: string, variant: string, userIdentifier?: string) => {
    track('$experiment_started', {
      'Experiment name': name,
      'Variant name': variant,
      nonInteraction: 1,
      ha_anonymous_id: userIdentifier,
    });

    return createAction(ActionTypes.PLAY_EXPERIMENT, name);
  },
};

export type ExperimentsActionsType = ActionsUnion<typeof Actions>;

export const playExperiment =
  (
    name: string,
    variant: string,
    userIdentifier?: string,
  ): Thunk<GlobalState> =>
  (dispatch, getState) => {
    if (!getPlayedExperiments(getState()).includes(name)) {
      dispatch(Actions.play(name, variant, userIdentifier));
    }
  };

export const { setVariants } = Actions;
