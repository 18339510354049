import type { MakeCall } from '../createMakeCall';

export type AllPayoutMethodRoutingData = {
  unitTypeIdsByMethodId: {
    [payoutMethodId: string]: number[];
  };
};

const getAllPayoutMethodRouting = (makeCall: MakeCall) => (userId: number) =>
  makeCall<AllPayoutMethodRoutingData>(
    'GET',
    `/api/v2/user/${userId}/payout-routing/link`,
  );

export { getAllPayoutMethodRouting };
