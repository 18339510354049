import { Language } from '@ha/intl';
import { UserCountriesCode } from 'ha/pages/PrivateLandlordLanding/types';

type CountrySlug = Record<string, string>;

const PrivateLandlordPageLanguageSlugs: Record<Language, string> = {
  [Language.en]: 'renting-out-private--',
  [Language.fr]: 'location-privée--',
  [Language.de]: 'privat-vermieten--',
  [Language.es]: 'alquiler-privado--',
  [Language.it]: 'affitto-da-privati--',
  [Language.nl]: 'particulier-verhuren--',
  [Language.pt]: 'alugar-privado--',
  [Language.uk]: 'Здача-в-оренду-приватно--',
  [Language.ru]: 'сдать-в-аренду-частный--',
  [Language.pl]: 'wynajem-prywatnie--',
  [Language.sv]: 'hyra-ut-privat--',
  [Language.ro]: 'inchiriere-privat--',
  [Language.zh]: '私人出租--',
};

export const PrivateLandlordPageCountrySlugMap: Record<
  UserCountriesCode,
  CountrySlug
> = {
  AT: {
    [Language.en]: `${PrivateLandlordPageLanguageSlugs.en}Austria`,
    [Language.fr]: `${PrivateLandlordPageLanguageSlugs.fr}Autriche`,
    [Language.de]: `${PrivateLandlordPageLanguageSlugs.de}Österreich`,
    [Language.es]: `${PrivateLandlordPageLanguageSlugs.es}Austria`,
    [Language.it]: `${PrivateLandlordPageLanguageSlugs.it}Austria`,
    [Language.nl]: `${PrivateLandlordPageLanguageSlugs.nl}Oostenrijk`,
    [Language.pt]: `${PrivateLandlordPageLanguageSlugs.pt}Áustria`,
    [Language.uk]: `${PrivateLandlordPageLanguageSlugs.uk}Австрія`,
    [Language.ru]: `${PrivateLandlordPageLanguageSlugs.ru}Австрия`,
    [Language.pl]: `${PrivateLandlordPageLanguageSlugs.pl}Austria`,
    [Language.sv]: `${PrivateLandlordPageLanguageSlugs.sv}Österrike`,
    [Language.ro]: `${PrivateLandlordPageLanguageSlugs.ro}Austria`,
    [Language.zh]: `${PrivateLandlordPageLanguageSlugs.zh}奥地利`,
  },
  BE: {
    [Language.en]: `${PrivateLandlordPageLanguageSlugs.en}Belgium`,
    [Language.fr]: `${PrivateLandlordPageLanguageSlugs.fr}Belgique`,
    [Language.de]: `${PrivateLandlordPageLanguageSlugs.de}Belgien`,
    [Language.es]: `${PrivateLandlordPageLanguageSlugs.es}Bélgica`,
    [Language.it]: `${PrivateLandlordPageLanguageSlugs.it}Belgio`,
    [Language.nl]: `${PrivateLandlordPageLanguageSlugs.nl}België`,
    [Language.pt]: `${PrivateLandlordPageLanguageSlugs.pt}Bélgica`,
    [Language.uk]: `${PrivateLandlordPageLanguageSlugs.uk}Бельгія`,
    [Language.ru]: `${PrivateLandlordPageLanguageSlugs.ru}Бельгия`,
    [Language.pl]: `${PrivateLandlordPageLanguageSlugs.pl}Belgia`,
    [Language.sv]: `${PrivateLandlordPageLanguageSlugs.sv}Belgien`,
    [Language.ro]: `${PrivateLandlordPageLanguageSlugs.ro}Belgia`,
    [Language.zh]: `${PrivateLandlordPageLanguageSlugs.zh}比利时`,
  },
  CA: {
    [Language.en]: `${PrivateLandlordPageLanguageSlugs.en}Canada`,
    [Language.fr]: `${PrivateLandlordPageLanguageSlugs.fr}Canada`,
    [Language.de]: `${PrivateLandlordPageLanguageSlugs.de}Kanada`,
    [Language.es]: `${PrivateLandlordPageLanguageSlugs.es}Canadá`,
    [Language.it]: `${PrivateLandlordPageLanguageSlugs.it}Canada`,
    [Language.nl]: `${PrivateLandlordPageLanguageSlugs.nl}Canada`,
    [Language.pt]: `${PrivateLandlordPageLanguageSlugs.pt}Canadá`,
    [Language.uk]: `${PrivateLandlordPageLanguageSlugs.uk}Канада`,
    [Language.ru]: `${PrivateLandlordPageLanguageSlugs.ru}Канада`,
    [Language.pl]: `${PrivateLandlordPageLanguageSlugs.pl}Kanada`,
    [Language.sv]: `${PrivateLandlordPageLanguageSlugs.sv}Kanada`,
    [Language.ro]: `${PrivateLandlordPageLanguageSlugs.ro}Canada`,
    [Language.zh]: `${PrivateLandlordPageLanguageSlugs.zh}加拿大`,
  },
  CZ: {
    [Language.en]: `${PrivateLandlordPageLanguageSlugs.en}Czech-Republic`,
    [Language.fr]: `${PrivateLandlordPageLanguageSlugs.fr}Tchéquie`,
    [Language.de]: `${PrivateLandlordPageLanguageSlugs.de}Tschechien`,
    [Language.es]: `${PrivateLandlordPageLanguageSlugs.es}Chequia`,
    [Language.it]: `${PrivateLandlordPageLanguageSlugs.it}Cechia`,
    [Language.nl]: `${PrivateLandlordPageLanguageSlugs.nl}Tsjechië`,
    [Language.pt]: `${PrivateLandlordPageLanguageSlugs.pt}República-Checa`,
    [Language.uk]: `${PrivateLandlordPageLanguageSlugs.uk}Чехiя`,
    [Language.ru]: `${PrivateLandlordPageLanguageSlugs.ru}Чешская-Республика`,
    [Language.pl]: `${PrivateLandlordPageLanguageSlugs.pl}Czechy`,
    [Language.sv]: `${PrivateLandlordPageLanguageSlugs.sv}Tjeckien`,
    [Language.ro]: `${PrivateLandlordPageLanguageSlugs.ro}Republica-Cehă`,
    [Language.zh]: `${PrivateLandlordPageLanguageSlugs.zh}捷克`,
  },
  FR: {
    [Language.en]: `${PrivateLandlordPageLanguageSlugs.en}France`,
    [Language.it]: `${PrivateLandlordPageLanguageSlugs.it}Francia`,
    [Language.es]: `${PrivateLandlordPageLanguageSlugs.es}Francia`,
    [Language.fr]: `${PrivateLandlordPageLanguageSlugs.fr}France`,
    [Language.de]: `${PrivateLandlordPageLanguageSlugs.de}Frankreich`,
    [Language.nl]: `${PrivateLandlordPageLanguageSlugs.nl}Frankreich`,
    [Language.pt]: `${PrivateLandlordPageLanguageSlugs.pt}França`,
    [Language.uk]: `${PrivateLandlordPageLanguageSlugs.uk}Франція`,
    [Language.ru]: `${PrivateLandlordPageLanguageSlugs.ru}Франция`,
    [Language.pl]: `${PrivateLandlordPageLanguageSlugs.pl}Francja`,
    [Language.sv]: `${PrivateLandlordPageLanguageSlugs.sv}Frankrike`,
    [Language.ro]: `${PrivateLandlordPageLanguageSlugs.ro}Franța`,
    [Language.zh]: `${PrivateLandlordPageLanguageSlugs.zh}法国`,
  },
  DE: {
    [Language.en]: `${PrivateLandlordPageLanguageSlugs.en}Germany`,
    [Language.fr]: `${PrivateLandlordPageLanguageSlugs.fr}Allemagne`,
    [Language.de]: `${PrivateLandlordPageLanguageSlugs.de}Deutschland`,
    [Language.es]: `${PrivateLandlordPageLanguageSlugs.es}Alemania`,
    [Language.it]: `${PrivateLandlordPageLanguageSlugs.it}Germania`,
    [Language.nl]: `${PrivateLandlordPageLanguageSlugs.nl}Duitsland`,
    [Language.pt]: `${PrivateLandlordPageLanguageSlugs.pt}Alemanha`,
    [Language.uk]: `${PrivateLandlordPageLanguageSlugs.uk}Німеччина`,
    [Language.ru]: `${PrivateLandlordPageLanguageSlugs.ru}Германия`,
    [Language.pl]: `${PrivateLandlordPageLanguageSlugs.pl}Niemcy`,
    [Language.sv]: `${PrivateLandlordPageLanguageSlugs.sv}Tyskland`,
    [Language.ro]: `${PrivateLandlordPageLanguageSlugs.ro}Ӂермания`,
    [Language.zh]: `${PrivateLandlordPageLanguageSlugs.zh}德国`,
  },
  GR: {
    [Language.en]: `${PrivateLandlordPageLanguageSlugs.en}Greece`,
    [Language.fr]: `${PrivateLandlordPageLanguageSlugs.fr}Grèce`,
    [Language.de]: `${PrivateLandlordPageLanguageSlugs.de}Griechenland`,
    [Language.es]: `${PrivateLandlordPageLanguageSlugs.es}Grecia`,
    [Language.it]: `${PrivateLandlordPageLanguageSlugs.it}Grecia`,
    [Language.nl]: `${PrivateLandlordPageLanguageSlugs.nl}Griekenland`,
    [Language.pt]: `${PrivateLandlordPageLanguageSlugs.pt}Grécia`,
    [Language.uk]: `${PrivateLandlordPageLanguageSlugs.uk}Греція`,
    [Language.ru]: `${PrivateLandlordPageLanguageSlugs.ru}Греция`,
    [Language.pl]: `${PrivateLandlordPageLanguageSlugs.pl}Grecja`,
    [Language.sv]: `${PrivateLandlordPageLanguageSlugs.sv}Grekland`,
    [Language.ro]: `${PrivateLandlordPageLanguageSlugs.ro}Grecia`,
    [Language.zh]: `${PrivateLandlordPageLanguageSlugs.zh}希腊`,
  },
  HU: {
    [Language.en]: `${PrivateLandlordPageLanguageSlugs.en}Hungary`,
    [Language.fr]: `${PrivateLandlordPageLanguageSlugs.fr}Hongrie`,
    [Language.de]: `${PrivateLandlordPageLanguageSlugs.de}Ungarn`,
    [Language.es]: `${PrivateLandlordPageLanguageSlugs.es}Hungría`,
    [Language.it]: `${PrivateLandlordPageLanguageSlugs.it}Ungheria`,
    [Language.nl]: `${PrivateLandlordPageLanguageSlugs.nl}Hongarije`,
    [Language.pt]: `${PrivateLandlordPageLanguageSlugs.pt}Hungria`,
    [Language.uk]: `${PrivateLandlordPageLanguageSlugs.uk}Угорщина`,
    [Language.ru]: `${PrivateLandlordPageLanguageSlugs.ru}Венгрия`,
    [Language.pl]: `${PrivateLandlordPageLanguageSlugs.pl}Węgry`,
    [Language.sv]: `${PrivateLandlordPageLanguageSlugs.sv}Ungern`,
    [Language.ro]: `${PrivateLandlordPageLanguageSlugs.ro}Унгария`,
    [Language.zh]: `${PrivateLandlordPageLanguageSlugs.zh}匈牙利`,
  },
  IS: {
    [Language.en]: `${PrivateLandlordPageLanguageSlugs.en}Iceland`,
    [Language.fr]: `${PrivateLandlordPageLanguageSlugs.fr}Islande`,
    [Language.de]: `${PrivateLandlordPageLanguageSlugs.de}Island`,
    [Language.es]: `${PrivateLandlordPageLanguageSlugs.es}Islandia`,
    [Language.it]: `${PrivateLandlordPageLanguageSlugs.it}Islanda`,
    [Language.nl]: `${PrivateLandlordPageLanguageSlugs.nl}IJsland`,
    [Language.pt]: `${PrivateLandlordPageLanguageSlugs.pt}Islândia`,
    [Language.uk]: `${PrivateLandlordPageLanguageSlugs.uk}Ісландія`,
    [Language.ru]: `${PrivateLandlordPageLanguageSlugs.ru}Исландия`,
    [Language.pl]: `${PrivateLandlordPageLanguageSlugs.pl}Islandia`,
    [Language.sv]: `${PrivateLandlordPageLanguageSlugs.sv}Island`,
    [Language.ro]: `${PrivateLandlordPageLanguageSlugs.ro}Islanda`,
    [Language.zh]: `${PrivateLandlordPageLanguageSlugs.zh}冰岛`,
  },
  IE: {
    [Language.en]: `${PrivateLandlordPageLanguageSlugs.en}Ireland`,
    [Language.fr]: `${PrivateLandlordPageLanguageSlugs.fr}Irlande`,
    [Language.de]: `${PrivateLandlordPageLanguageSlugs.de}Irland`,
    [Language.es]: `${PrivateLandlordPageLanguageSlugs.es}Irlanda`,
    [Language.it]: `${PrivateLandlordPageLanguageSlugs.it}Irlanda`,
    [Language.nl]: `${PrivateLandlordPageLanguageSlugs.nl}Ierland`,
    [Language.pt]: `${PrivateLandlordPageLanguageSlugs.pt}Irlanda`,
    [Language.uk]: `${PrivateLandlordPageLanguageSlugs.uk}Республіка-Ірландія`,
    [Language.ru]: `${PrivateLandlordPageLanguageSlugs.ru}Ирландия`,
    [Language.pl]: `${PrivateLandlordPageLanguageSlugs.pl}Irlandia`,
    [Language.sv]: `${PrivateLandlordPageLanguageSlugs.sv}Irland`,
    [Language.ro]: `${PrivateLandlordPageLanguageSlugs.ro}Irlanda`,
    [Language.zh]: `${PrivateLandlordPageLanguageSlugs.zh}爱尔兰`,
  },
  IT: {
    [Language.en]: `${PrivateLandlordPageLanguageSlugs.en}Italy`,
    [Language.fr]: `${PrivateLandlordPageLanguageSlugs.fr}Italie`,
    [Language.de]: `${PrivateLandlordPageLanguageSlugs.nl}Italien`,
    [Language.es]: `${PrivateLandlordPageLanguageSlugs.es}Italia`,
    [Language.it]: `${PrivateLandlordPageLanguageSlugs.it}Italia`,
    [Language.nl]: `${PrivateLandlordPageLanguageSlugs.nl}Italien`,
    [Language.pt]: `${PrivateLandlordPageLanguageSlugs.pt}Itália`,
    [Language.uk]: `${PrivateLandlordPageLanguageSlugs.uk}Італія`,
    [Language.ru]: `${PrivateLandlordPageLanguageSlugs.ru}Италия`,
    [Language.pl]: `${PrivateLandlordPageLanguageSlugs.pl}Włochy`,
    [Language.sv]: `${PrivateLandlordPageLanguageSlugs.sv}Italien`,
    [Language.ro]: `${PrivateLandlordPageLanguageSlugs.ro}Italia`,
    [Language.zh]: `${PrivateLandlordPageLanguageSlugs.zh}意大利`,
  },
  NL: {
    [Language.en]: `${PrivateLandlordPageLanguageSlugs.en}Netherlands`,
    [Language.fr]: `${PrivateLandlordPageLanguageSlugs.fr}Pays-Bas`,
    [Language.de]: `${PrivateLandlordPageLanguageSlugs.de}Niederlande`,
    [Language.es]: `${PrivateLandlordPageLanguageSlugs.es}Holanda`,
    [Language.it]: `${PrivateLandlordPageLanguageSlugs.it}Olanda`,
    [Language.nl]: `${PrivateLandlordPageLanguageSlugs.nl}Niederlande`,
    [Language.pt]: `${PrivateLandlordPageLanguageSlugs.pt}Países-Baixos`,
    [Language.uk]: `${PrivateLandlordPageLanguageSlugs.uk}Нідерланди`,
    [Language.ru]: `${PrivateLandlordPageLanguageSlugs.ru}Нидерланды`,
    [Language.pl]: `${PrivateLandlordPageLanguageSlugs.pl}Holandia`,
    [Language.sv]: `${PrivateLandlordPageLanguageSlugs.sv}Nederländerna`,
    [Language.ro]: `${PrivateLandlordPageLanguageSlugs.ro}Țările-de-Jos`,
    [Language.zh]: `${PrivateLandlordPageLanguageSlugs.zh}荷兰`,
  },
  PL: {
    [Language.en]: `${PrivateLandlordPageLanguageSlugs.en}Poland`,
    [Language.fr]: `${PrivateLandlordPageLanguageSlugs.fr}Pologne`,
    [Language.de]: `${PrivateLandlordPageLanguageSlugs.de}Polen`,
    [Language.es]: `${PrivateLandlordPageLanguageSlugs.es}Polonia`,
    [Language.it]: `${PrivateLandlordPageLanguageSlugs.it}Polonia`,
    [Language.nl]: `${PrivateLandlordPageLanguageSlugs.nl}Polen`,
    [Language.pt]: `${PrivateLandlordPageLanguageSlugs.pt}Polónia`,
    [Language.uk]: `${PrivateLandlordPageLanguageSlugs.uk}Польща`,
    [Language.ru]: `${PrivateLandlordPageLanguageSlugs.ru}Польша`,
    [Language.pl]: `${PrivateLandlordPageLanguageSlugs.pl}Polska`,
    [Language.sv]: `${PrivateLandlordPageLanguageSlugs.sv}Polen`,
    [Language.ro]: `${PrivateLandlordPageLanguageSlugs.ro}Polonia`,
    [Language.zh]: `${PrivateLandlordPageLanguageSlugs.zh}波兰`,
  },
  PT: {
    [Language.en]: `${PrivateLandlordPageLanguageSlugs.en}Portugal`,
    [Language.fr]: `${PrivateLandlordPageLanguageSlugs.fr}Portugal`,
    [Language.de]: `${PrivateLandlordPageLanguageSlugs.de}Portugal`,
    [Language.es]: `${PrivateLandlordPageLanguageSlugs.es}Portugal`,
    [Language.it]: `${PrivateLandlordPageLanguageSlugs.it}Portogallo`,
    [Language.nl]: `${PrivateLandlordPageLanguageSlugs.nl}Portugal`,
    [Language.pt]: `${PrivateLandlordPageLanguageSlugs.pt}Portugal`,
    [Language.uk]: `${PrivateLandlordPageLanguageSlugs.uk}Портуґалія`,
    [Language.ru]: `${PrivateLandlordPageLanguageSlugs.ru}Португалия`,
    [Language.pl]: `${PrivateLandlordPageLanguageSlugs.pl}Portugalia`,
    [Language.sv]: `${PrivateLandlordPageLanguageSlugs.sv}Portugal`,
    [Language.ro]: `${PrivateLandlordPageLanguageSlugs.ro}Portugalia`,
    [Language.zh]: `${PrivateLandlordPageLanguageSlugs.zh}葡萄牙`,
  },
  ES: {
    [Language.en]: `${PrivateLandlordPageLanguageSlugs.en}Spain`,
    [Language.fr]: `${PrivateLandlordPageLanguageSlugs.fr}Espagne`,
    [Language.de]: `${PrivateLandlordPageLanguageSlugs.de}Spanien`,
    [Language.es]: `${PrivateLandlordPageLanguageSlugs.es}España`,
    [Language.it]: `${PrivateLandlordPageLanguageSlugs.it}Spagna`,
    [Language.nl]: `${PrivateLandlordPageLanguageSlugs.nl}Spanien`,
    [Language.pt]: `${PrivateLandlordPageLanguageSlugs.pt}Espanha`,
    [Language.uk]: `${PrivateLandlordPageLanguageSlugs.uk}Іспанія`,
    [Language.ru]: `${PrivateLandlordPageLanguageSlugs.ru}Испания`,
    [Language.pl]: `${PrivateLandlordPageLanguageSlugs.pl}Hiszpania`,
    [Language.sv]: `${PrivateLandlordPageLanguageSlugs.sv}Spanien`,
    [Language.ro]: `${PrivateLandlordPageLanguageSlugs.ro}Spania`,
    [Language.zh]: `${PrivateLandlordPageLanguageSlugs.zh}西班牙`,
  },
  SE: {
    [Language.en]: `${PrivateLandlordPageLanguageSlugs.en}Sweden`,
    [Language.fr]: `${PrivateLandlordPageLanguageSlugs.fr}Suède`,
    [Language.de]: `${PrivateLandlordPageLanguageSlugs.de}Schweden`,
    [Language.es]: `${PrivateLandlordPageLanguageSlugs.es}Suecia`,
    [Language.it]: `${PrivateLandlordPageLanguageSlugs.it}Svezia`,
    [Language.nl]: `${PrivateLandlordPageLanguageSlugs.nl}Zweden`,
    [Language.pt]: `${PrivateLandlordPageLanguageSlugs.pt}Suécia`,
    [Language.uk]: `${PrivateLandlordPageLanguageSlugs.uk}Швеція`,
    [Language.ru]: `${PrivateLandlordPageLanguageSlugs.ru}Швеция`,
    [Language.pl]: `${PrivateLandlordPageLanguageSlugs.pl}Szwecja`,
    [Language.sv]: `${PrivateLandlordPageLanguageSlugs.sv}Sverige`,
    [Language.ro]: `${PrivateLandlordPageLanguageSlugs.ro}Suedia`,
    [Language.zh]: `${PrivateLandlordPageLanguageSlugs.zh}瑞典`,
  },
  GB: {
    [Language.en]: `${PrivateLandlordPageLanguageSlugs.en}United-Kingdom`,
    [Language.fr]: `${PrivateLandlordPageLanguageSlugs.fr}Royaume-Uni`,
    [Language.de]: `${PrivateLandlordPageLanguageSlugs.de}Vereinigtes-Königreich`,
    [Language.es]: `${PrivateLandlordPageLanguageSlugs.es}Reino-Unido`,
    [Language.it]: `${PrivateLandlordPageLanguageSlugs.it}Regno-Unito`,
    [Language.nl]: `${PrivateLandlordPageLanguageSlugs.nl}Königreich`,
    [Language.pt]: `${PrivateLandlordPageLanguageSlugs.pt}Reino-Unido`,
    [Language.uk]: `${PrivateLandlordPageLanguageSlugs.uk}Сполучене-Королівство`,
    [Language.ru]: `${PrivateLandlordPageLanguageSlugs.ru}Великобритания`,
    [Language.pl]: `${PrivateLandlordPageLanguageSlugs.pl}Zjednoczone-Królestwo-Wielkiej-Brytanii`,
    [Language.sv]: `${PrivateLandlordPageLanguageSlugs.sv}Storbritannien`,
    [Language.ro]: `${PrivateLandlordPageLanguageSlugs.ro}Marea-Britanie`,
    [Language.zh]: `${PrivateLandlordPageLanguageSlugs.zh}英国`,
  },
  US: {
    [Language.en]: `${PrivateLandlordPageLanguageSlugs.en}United-States`,
    [Language.fr]: `${PrivateLandlordPageLanguageSlugs.fr}États-Unis`,
    [Language.de]: `${PrivateLandlordPageLanguageSlugs.de}USA`,
    [Language.es]: `${PrivateLandlordPageLanguageSlugs.es}Estados-Unidos`,
    [Language.it]: `${PrivateLandlordPageLanguageSlugs.it}Stati-Unitio`,
    [Language.nl]: `${PrivateLandlordPageLanguageSlugs.nl}Vereinigtes-Königreich`,
    [Language.pt]: `${PrivateLandlordPageLanguageSlugs.pt}Estados-Unidos`,
    [Language.uk]: `${PrivateLandlordPageLanguageSlugs.uk}Сполучені-Штати-Америки`,
    [Language.ru]: `${PrivateLandlordPageLanguageSlugs.ru}США`,
    [Language.pl]: `${PrivateLandlordPageLanguageSlugs.pl}Stany-Zjednoczone`,
    [Language.sv]: `${PrivateLandlordPageLanguageSlugs.sv}Amerikas-Förenta-Stater`,
    [Language.ro]: `${PrivateLandlordPageLanguageSlugs.ro}Statele-Unite-ale-Americii`,
    [Language.zh]: `${PrivateLandlordPageLanguageSlugs.zh}美国`,
  },
  CH: {
    [Language.en]: `${PrivateLandlordPageLanguageSlugs.en}Switzerland`,
    [Language.fr]: `${PrivateLandlordPageLanguageSlugs.fr}Confédération-Suisse`,
    [Language.de]: `${PrivateLandlordPageLanguageSlugs.de}Schweiz`,
    [Language.es]: `${PrivateLandlordPageLanguageSlugs.es}Suiza`,
    [Language.it]: `${PrivateLandlordPageLanguageSlugs.it}Confederazione-Svizzera`,
    [Language.nl]: `${PrivateLandlordPageLanguageSlugs.nl}Zwitserland`,
    [Language.pt]: `${PrivateLandlordPageLanguageSlugs.pt}Suíça`,
    [Language.uk]: `${PrivateLandlordPageLanguageSlugs.uk}Швейцарія`,
    [Language.ru]: `${PrivateLandlordPageLanguageSlugs.ru}Швейцария`,
    [Language.pl]: `${PrivateLandlordPageLanguageSlugs.pl}Szwajcaria`,
    [Language.sv]: `${PrivateLandlordPageLanguageSlugs.sv}Schweiz`,
    [Language.ro]: `${PrivateLandlordPageLanguageSlugs.ro}Elveția`,
    [Language.zh]: `${PrivateLandlordPageLanguageSlugs.zh}瑞士`,
  },
};

export const getPrivateLandlordPageRoutes = () =>
  Object.values(PrivateLandlordPageCountrySlugMap).flatMap(localeSlugMap =>
    Object.values(localeSlugMap),
  );

export function findCountryCodeByPathname(pathname: string) {
  const url = decodeURI(pathname);
  const slug = url.substring(url.lastIndexOf('/') + 1);

  const countryCodes = Object.entries(PrivateLandlordPageCountrySlugMap);

  const matchingCountryCodeEntry = countryCodes.find(([, languageSlugMap]) => {
    return Object.values(languageSlugMap).includes(slug);
  });

  return matchingCountryCodeEntry?.[0];
}

export const routesWithFreePricingPlan = [
  ...Object.values(PrivateLandlordPageCountrySlugMap.IT),
];
