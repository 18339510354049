import { defineKey, Language } from '@ha/intl';

import { PricingPlanFeature, UserCountries, UserCountriesCode } from './types';

export const PRIVATE_LL_IMGIX_PATH = 'pages/private-landlord';

export const USER_COUNTRY_DEFAULT_VALUE = UserCountries.DE;

export const PRIVATE_LANDLORD_ROLLOUT_CONFIG = {
  countries: [{ name: 'Italy', countryCode: 'IT' }],
  cities: [
    { name: 'Rome' },
    { name: 'Milan' },
    { name: 'Turin' },
    { name: 'Florence' },
    { name: 'Bologna' },
    { name: 'Padua' },
  ],
};

export const privateLandlordMetaTitleTranslations: Record<
  UserCountriesCode,
  string
> = {
  AT: defineKey('private_landing.meta_title_austria'),
  BE: defineKey('private_landing.meta_title_belgium'),
  CA: defineKey('private_landing.meta_title_canada'),
  CZ: defineKey('private_landing.meta_title_czech_republic'),
  FR: defineKey('private_landing.meta_title_france'),
  DE: defineKey('private_landing.meta_title_germany'),
  GR: defineKey('private_landing.meta_title_greece'),
  HU: defineKey('private_landing.meta_title_hungary'),
  IS: defineKey('private_landing.meta_title_iceland'),
  IE: defineKey('private_landing.meta_title_ireland'),
  IT: defineKey('private_landing.meta_title_italy'),
  NL: defineKey('private_landing.meta_title_netherlands'),
  PL: defineKey('private_landing.meta_title_poland'),
  PT: defineKey('private_landing.meta_title_portugal'),
  ES: defineKey('private_landing.meta_title_spain'),
  SE: defineKey('private_landing.meta_title_sweden'),
  GB: defineKey('private_landing.meta_title_uk'),
  US: defineKey('private_landing.meta_title_usa'),
  CH: defineKey('private_landing.meta_title_switzerland'),
};

export const privateLandlordMetaDescriptionTranslations: Record<
  UserCountriesCode,
  string
> = {
  AT: defineKey('private_landing.meta_description_austria'),
  BE: defineKey('private_landing.meta_description_belgium'),
  CA: defineKey('private_landing.meta_description_canada'),
  CZ: defineKey('private_landing.meta_description_czech_republic'),
  FR: defineKey('private_landing.meta_description_france'),
  DE: defineKey('private_landing.meta_description_germany'),
  GR: defineKey('private_landing.meta_description_greece'),
  HU: defineKey('private_landing.meta_description_hungary'),
  IS: defineKey('private_landing.meta_description_iceland'),
  IE: defineKey('private_landing.meta_description_ireland'),
  IT: defineKey('private_landing.meta_description_italy'),
  NL: defineKey('private_landing.meta_description_netherlands'),
  PL: defineKey('private_landing.meta_description_poland'),
  PT: defineKey('private_landing.meta_description_portugal'),
  ES: defineKey('private_landing.meta_description_spain'),
  SE: defineKey('private_landing.meta_description_sweden'),
  GB: defineKey('private_landing.meta_description_uk'),
  US: defineKey('private_landing.meta_description_usa'),
  CH: defineKey('private_landing.meta_description_switzerland'),
};

export const PRIVATE_LANDLORD_DEFAULT_CITIES: Record<
  UserCountriesCode,
  string
> = {
  IT: 'Rome',
  FR: 'Paris',
  US: 'Washington',
  DE: 'Berlin',
  ES: 'Madrid',
  NL: 'Amsterdam',
  PT: 'Lisbon',
  AT: 'Vienna',
  BE: 'Brussels',
  CA: 'Ottawa',
  SE: 'Stockholm',
  CZ: 'Prague',
  GB: 'London',
  GR: 'Athens',
  HU: 'Budapest',
  IE: 'Dublin',
  IS: 'Reykjavik',
  PL: 'Warsaw',
  CH: 'Bern',
};

export enum CountriesISOCodes {
  Italy = 'it',
}

export enum PropertyTypes {
  Studio = 0,
  SharedRoom = 1,
  PrivateRoom = 2,
  ApartmentOneBed = 3,
  ApartmentTwoBed = 4,
  ApartmentThreeBedAndMore = 5,
}

export const PropertyTypeTranslations: Record<PropertyTypes, string> = {
  [PropertyTypes.Studio]: defineKey('private_landing.rent.property_studio'),
  [PropertyTypes.SharedRoom]: defineKey(
    'private_landing.rent.property_shared_room',
  ),
  [PropertyTypes.PrivateRoom]: defineKey(
    'private_landing.rent.property_private_room',
  ),
  [PropertyTypes.ApartmentOneBed]: defineKey(
    'private_landing.rent.property_1br',
  ),
  [PropertyTypes.ApartmentTwoBed]: defineKey(
    'private_landing.rent.property_2br',
  ),
  [PropertyTypes.ApartmentThreeBedAndMore]: defineKey(
    'private_landing.rent.property_3br',
  ),
};

export const SupportedCountriesTranslations: Record<UserCountriesCode, string> =
  {
    IT: defineKey('home.in_country.Italy'),
    FR: defineKey('home.in_country.France'),
    US: defineKey('home.in_country.UnitedStates'),
    DE: defineKey('home.in_country.Germany'),
    ES: defineKey('home.in_country.Spain'),
    NL: defineKey('home.in_country.Netherlands'),
    PT: defineKey('home.in_country.Portugal'),
    AT: defineKey('home.in_country.Austria'),
    BE: defineKey('home.in_country.Belgium'),
    CA: defineKey('home.in_country.Canada'),
    SE: defineKey('in_country.sweden'),
    CZ: defineKey('home.in_country.CzechRepublic'),
    GB: defineKey('home.in_country.UnitedKingdom'),
    GR: defineKey('home.in_country.Greece'),
    HU: defineKey('home.in_country.Hungary'),
    IE: defineKey('home.in_country.Ireland'),
    IS: defineKey('home.in_country.Iceland'),
    PL: defineKey('home.in_country.Poland'),
    CH: defineKey('home.in_country.Switzerland'),
  };

export const PricingPlanFeatures: Record<string, PricingPlanFeature> = {
  planOne: {
    label: defineKey('private_landing.pricing.free_plan_title'),
    list: [
      {
        id: 1,
        label: defineKey('private_landing.pricing.free_plan_limit_number'),
        subtitle: {
          label: defineKey('private_landing.pricing.free_plan_renews'),
        },
      },
      {
        id: 2,
        label: defineKey('private_landing.pricing.free_plan_listings'),
      },
      {
        id: 3,
        label: defineKey('private_landing.pricing.free_plan_calendar'),
      },
      {
        id: 4,
        label: defineKey('private_landing.pricing.free_plan_sync'),
      },
      {
        id: 5,
        label: defineKey('private_landing.pricing.free_plan_iban'),
      },
      {
        id: 6,
        label: defineKey('private_landing.pricing.free_plan_invoice'),
      },
      {
        id: 7,
        label: defineKey('private_landing.pricing.free_plan_support'),
      },
    ],
  },
  planTwo: {
    label: defineKey('private_landing.pricing.paid_plan_title'),
    list: [
      {
        id: 1,
        label: defineKey(
          'private_landing.pricing.paid_plan_unlimited_bookings',
        ),
        subtitle: {
          label: defineKey('private_landing.pricing.paid_plan_fee_from_rent'),
        },
      },
      {
        id: 2,
        label: defineKey('private_landing.pricing.paid_plan_multiunits'),
      },
      {
        id: 3,
        label: defineKey('private_landing.pricing.paid_plan_calendar'),
      },
      {
        id: 4,
        label: defineKey('private_landing.pricing.paid_plan_sync'),
      },
      {
        id: 5,
        label: defineKey('private_landing.pricing.paid_plan_integrations'),
      },
      {
        id: 6,
        label: defineKey('private_landing.pricing.paid_plan_iban'),
      },
      {
        id: 7,
        label: defineKey('private_landing.pricing.paid_plan_manager'),
      },
    ],
  },
  planOnlyPremium: {
    label: defineKey('private_landing.pricing.what_included'),
    list: [
      {
        id: 1,
        label: defineKey(
          'private_landing.pricing.paid_plan_unlimited_bookings',
        ),
        subtitle: {
          label: defineKey('private_landing.pricing.paid_plan_possibilities'),
        },
      },
      {
        id: 2,
        label: defineKey('private_landing.pricing.paid_plan_multiunits'),
      },
      {
        id: 3,
        label: defineKey('private_landing.pricing.paid_plan_availability'),
      },
      {
        id: 4,
        label: defineKey('private_landing.pricing.paid_plan_integrations'),
      },
      {
        id: 5,
        label: defineKey('private_landing.pricing.paid_plan_iban'),
      },
      {
        id: 6,
        label: defineKey('private_landing.pricing.paid_plan_invoices'),
      },
      {
        id: 7,
        label: defineKey('private_landing.pricing.paid_plan_manager'),
      },
    ],
  },
};

export const countryLanguageMap: Record<
  UserCountriesCode,
  {
    defaultLang: Language;
    // Alternate languages that are indexable by search engines for this country.
    // The default language also is indexable but we are separating default and
    // alternate languages in case we want to use default language for redirecting user etc...
    alternateLangs?: Language[];
  }
> = {
  AT: {
    defaultLang: Language.de,
    alternateLangs: [Language.en],
  },
  BE: {
    defaultLang: Language.de,
    alternateLangs: [Language.en, Language.fr],
  },
  CA: {
    defaultLang: Language.en,
    alternateLangs: [Language.fr],
  },
  CZ: {
    defaultLang: Language.en,
  },
  FR: {
    defaultLang: Language.fr,
    alternateLangs: [Language.en],
  },
  DE: {
    defaultLang: Language.de,
    alternateLangs: [Language.en],
  },
  GR: {
    defaultLang: Language.en,
  },
  HU: {
    defaultLang: Language.en,
  },
  IS: {
    defaultLang: Language.en,
  },
  IE: {
    defaultLang: Language.en,
  },
  IT: {
    defaultLang: Language.it,
    alternateLangs: [Language.en],
  },
  NL: {
    defaultLang: Language.nl,
    alternateLangs: [Language.en],
  },
  PL: {
    defaultLang: Language.en,
  },
  PT: {
    defaultLang: Language.pt,
    alternateLangs: [Language.en],
  },
  ES: {
    defaultLang: Language.es,
    alternateLangs: [Language.en],
  },
  SE: {
    defaultLang: Language.sv,
    alternateLangs: [Language.en],
  },
  GB: {
    defaultLang: Language.en,
  },
  US: {
    defaultLang: Language.en,
  },
  CH: {
    defaultLang: Language.en,
    alternateLangs: [Language.fr, Language.de],
  },
};

export const CTA_EVENT_NAME = 'Clicked CTA on non-professional LP';

export const commonEventProps = {
  category: 'Supply landing',
  pageName: 'non-professional',
};

export const marketingAnchorTags = {
  valuesSection: 'values',
  pricingSection: 'pricing',
  rentalProcessSection: 'how-it-works',
};
