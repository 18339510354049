import { MakeCall } from '@ha/api/v2/createMakeCall';

export interface TenantServiceFeeConfiguration {
  enabled: boolean;
  propertyCountryCodes: string[];
}

const getTenantServiceFeeConfiguration = (makeCall: MakeCall) => () =>
  makeCall<TenantServiceFeeConfiguration>(
    'GET',
    '/payment-scheduler/v1/service-fee-configuration',
  );

export { getTenantServiceFeeConfiguration };
