import { MakeCall } from '../createMakeCall';

import { UnitTypeVideoUploadRecord } from '../types/inventory/UnitType';

const requestUnitTypeVideoUpload =
  (makeCall: MakeCall) => (unitTypeId: number) =>
    makeCall<UnitTypeVideoUploadRecord>(
      'POST',
      `/api/v2/unit-type/${unitTypeId}/videos/request`,
    );
export { requestUnitTypeVideoUpload };
