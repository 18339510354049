import { Language } from '@ha/intl';
import { MakeCall } from './createMakeCall';

import { City } from './types/City';

export interface FeaturedCitiesResource {
  cities: City[];
  count: number;
}

export interface Query {
  lang?: Language;
  include?: string;
  exclude?: string;
}

const getFeaturedCities = (makeCall: MakeCall) => (query?: Query) =>
  makeCall<FeaturedCitiesResource>('GET', `/api/v2/geonames/cities/featured`, {
    query,
  });
export { getFeaturedCities };
