import { MakeCall } from './createMakeCall';
import { City } from './types/City';

export interface GetCitiesNearbyQuery {
  // Mutually exclusive with lat/lng and countryCode
  query?: string;
  // Mutually exclusive with query/countryCode
  latitude?: number;
  // Mutually exclusive with query/countryCode
  longitude?: number;
  // Required for query or lat/lng
  radius?: number;
  // Mutually exclusive with lat/lng and query
  countryCode?: string;
  lang?: string;
  excludeQuery?: boolean;
  sameCountry?: boolean;
  minListings?: number;
  minPopulation?: number;
  maxPopulation?: number;
}

export interface CitiesNearbyResponse {
  cities: City[];
  count: number;
}

export type GetCitiesNearbyCall = (
  data: GetCitiesNearbyQuery,
) => Promise<{ data: CitiesNearbyResponse }>;

const getCitiesNearby = (makeCall: MakeCall) => (query: GetCitiesNearbyQuery) =>
  makeCall<CitiesNearbyResponse>('GET', `/api/v2/geonames/cities`, {
    query,
  });
export { getCitiesNearby };
