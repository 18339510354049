import { FilterBuilder } from '../Types';

const advertiserBuilder: FilterBuilder = ({ advertiserId }) => {
  if (!advertiserId) {
    return '';
  }

  return `advertiserId:${advertiserId}`;
};

export { advertiserBuilder };
