import { useSelector } from 'react-redux';

import { GlobalState } from 'ha/types/store';

import { getFeatureFlags } from './selectors';
import { FeatureFlags, FeatureFlagsProps } from './types';

interface StateProps {
  featureFlags: FeatureFlags;
}

const mapStateToProps = (state: GlobalState): StateProps => ({
  featureFlags: getFeatureFlags(state),
});

export function useFeatureFlags(): Partial<FeatureFlagsProps> {
  const { featureFlags } = useSelector(mapStateToProps);

  return featureFlags;
}
