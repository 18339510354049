import { MakeCall } from './createMakeCall';
import {
  ChangeRequestResponse,
  BookingModificationRequestPayload,
} from './types/ChangeRequest';

/**
 * Creates a booking modification request
 * @param conversationId unique conversation identifier
 */
export const requestBookingModification =
  (makeCall: MakeCall) =>
  (conversationId: number, payload: BookingModificationRequestPayload) =>
    makeCall<ChangeRequestResponse>(
      'POST',
      `/api/v2/conversation/${conversationId}/request-modification`,
      { data: payload },
    );
