import { MakeCall } from './createMakeCall';
import { TaxPayerInfo } from './types/TaxPayerDetails';

interface TaxPayerParams {
  limit?: number;
  offset?: number;
}

export interface GetTaxPayerDetailsResponse {
  taxPayers: TaxPayerInfo[];
  count: number;
}

/**
 * @description makes a call to fetch the user's compliance details
 * @param limit sets maximum results in each call
 * @param offset sets base value
 */

export const getTaxPayerDetails =
  (makeCall: MakeCall) => (query?: TaxPayerParams) =>
    makeCall<GetTaxPayerDetailsResponse>('GET', `/api/v2/legal/taxpayer`, {
      query,
    });
