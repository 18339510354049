import { Message } from './types/Conversation';
import { MakeCall } from './createMakeCall';

export interface Query {
  translateToLanguage?: string;
}

export interface ConversationMessages {
  messages: Message[];
  count: number;
}

const getConversationMessages =
  (makeCall: MakeCall) => (conversationId: number, query?: Query) =>
    makeCall<ConversationMessages>(
      'GET',
      `/api/v2/conversation/${conversationId}/messages`,
      {
        query,
      },
    );
export { getConversationMessages };
