import { FilterBuilder } from '../Types';
import { FreePlacesValue } from '../../../types/SearchFilters';

const freePlacesBuilder: FilterBuilder = params => {
  const { freePlaces } = params;

  const enumToNumber: { [key in FreePlacesValue]: number } = {
    [FreePlacesValue.ZERO]: 0,
    [FreePlacesValue.ONE]: 1,
    [FreePlacesValue.TWO]: 2,
    [FreePlacesValue.THREE]: 3,
    [FreePlacesValue.FOUR]: 4,
    [FreePlacesValue.FIVE_AND_MORE]: 5,
  };
  const freePlacesNumber = freePlaces && enumToNumber[freePlaces];

  if (!freePlacesNumber) {
    return '';
  }

  return `freePlaces>=${freePlacesNumber}`;
};

export { freePlacesBuilder };
