/**
 * Capitalizes the first letter of a string.
 *
 * @param {string} text - The input string.
 * @returns {string} The input string with the first letter capitalized.
 * @example
 * const capitalized = capitalizeFirst('hello World');
 * console.log(capitalized); // Output: 'Hello World'
 */
export const capitalizeFirst = (text: string) => {
  return text.substring(0, 1).toUpperCase() + text.substring(1);
};
