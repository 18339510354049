import { Conversation } from './types/Conversation';
import { MakeCall } from './createMakeCall';

export interface Payload {
  startDate: string;
  endDate: string;
}

const changeConversationDate =
  (makeCall: MakeCall) => (conversationId: number, data: Payload) =>
    makeCall<Conversation>(
      'PUT',
      `/api/v2/conversation/${conversationId}/new-dates`,
      { data },
    );
export { changeConversationDate };
