import { MakeCall } from './createMakeCall';
import { User } from './types/User';

export interface Query {
  expand?: 'defaults';
  // This will force the request to reach the write replica. In case of doubt, don't use it.
  initial?: boolean;
}

const getUser =
  (makeCall: MakeCall) =>
  (userId: number | 'me' = 'me', query?: Query) =>
    makeCall<User>('GET', `/api/v2/user/${userId}`, {
      query,
    });

export { getUser };
