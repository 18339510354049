import { MakeCall } from './createMakeCall';
import {
  PropertyRegistrationInfo,
  PropertyRegistrationInfoPayload,
} from './types/PropertyRegistrationInfo';

/**
 * Handles fetching Land/Property  registration details   (compliance)
 * @returns land registration details response
 */

export const getPropertyRegistrationInfo =
  (makeCall: MakeCall) => (query: Partial<PropertyRegistrationInfoPayload>) =>
    makeCall<PropertyRegistrationInfo>('GET', `/api/v2/property-info`, {
      query,
    });
