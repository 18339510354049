import { GlobalState } from 'ha/types/store';
import { STATE_NAME } from './constants';

export const getState = (state: GlobalState) => state[STATE_NAME];
export const getPlayedExperiments = (state: GlobalState) =>
  getState(state).played;
export const getUserIdentifier = (state: GlobalState) =>
  getState(state).userIdentifier;
export const getSelectedVariants = (state: GlobalState) =>
  getState(state).variants;
