// get list of payment methods for the authorized user

import type { MakeCall } from '../createMakeCall';
import { PaymentMethod } from './types';

type Query = {
  limit?: number;
  offset?: number;
  pspName?: string;
};

type Response = {
  count: number;
  methods: PaymentMethod[];
};

const getUserPaymentMethods = (makeCall: MakeCall) => (query: Query) =>
  makeCall<Response>('GET', `/api/v2/payment/method/pay-in`, { query });
export { getUserPaymentMethods };
