const base64 = {
  atob: (encoded: string): string => {
    if (typeof global.atob === 'undefined' && Buffer) {
      return Buffer.from(encoded, 'base64').toString();
    }

    return global.atob(encoded);
  },

  btoa: (str: string): string => {
    if (typeof global.btoa === 'undefined' && Buffer) {
      return Buffer.from(str).toString('base64');
    }

    return global.btoa(str);
  },
};

export { base64 };
