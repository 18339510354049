export const convertSquareMeterToSquareFootString = (m2Size: number) =>
  (m2Size * 10.7639150512).toFixed();

export const convertSquareMeterToSquareFoot = (m2Size: number) =>
  Math.round(m2Size * 10.7639150512);

export const convertSquareFootToSquareMeter = (ft2Size: number) =>
  ft2Size * 0.092903;

export const convertKilometerToMile = (kmDistance: number) =>
  (kmDistance * 0.621371).toFixed();

export const convertMileToKilometer = (miDistance: number) =>
  (miDistance * 1.60934).toFixed();

export const getAreaUnit = (props: { isImperialSystem: boolean }) =>
  `${!props.isImperialSystem ? 'm²' : 'sqft'}`;

export const getAreaLabel = (
  size: string | null | undefined,
  isImperialSystem: boolean,
) => {
  if (!size) {
    return '';
  }
  return `${
    !isImperialSystem
      ? size
      : convertSquareMeterToSquareFootString(parseFloat(size))
  } ${getAreaUnit({ isImperialSystem })}`;
};
