import { ThunkMiddleware } from 'redux-thunk';
import { AnyAction } from 'redux';

import { getUserId } from 'ha/modules/AuthLogic/selectors';
import { AppServices } from 'ha/services/getAppServices';
import { GlobalState } from 'ha/types/store';
import {
  isConnectionUpdatedAction,
  isRecieveMessageAction,
} from 'ha/modules/RealtimeMessaging/utils';

import { getInboxCounters } from '../actions';

const inboxCounterMiddleware: ThunkMiddleware<
  GlobalState,
  AnyAction,
  AppServices
> =
  ({ dispatch, getState }) =>
  next =>
  action => {
    next(action);

    const STATE = getState();
    const userId = getUserId(STATE);

    if (
      userId &&
      (isRecieveMessageAction(action) || isConnectionUpdatedAction(action))
    ) {
      dispatch(getInboxCounters(userId));
    }
  };

export { inboxCounterMiddleware };
