import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';

import { City } from '@ha/api/v2/types';

export enum ActionTypes {
  LOAD_START = 'FEATURED_CITIES/LOAD_START',
  LOAD_DONE = 'FEATURED_CITIES/LOAD_DONE',
  LOAD_FAIL = 'FEATURED_CITIES/LOAD_FAIL',
}

export const Actions = {
  start: () => createAction(ActionTypes.LOAD_START),
  done: (cities: City[]) => createAction(ActionTypes.LOAD_DONE, cities),
  fail: (error: Error) => createAction(ActionTypes.LOAD_FAIL, error),
};
export type ActionsUnionType = ActionsUnion<typeof Actions>;
