/**
 * This utility class provides localization for `react-day-picker`
 * through `dayjs`.
 * See: https://react-day-picker.js.org/api/LocaleUtils/
 */
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(localeData);

export const formatDate = (date: Date, format = 'L', locale = 'en'): string =>
  dayjs(date).locale(locale).format(format);

export const formatDay = (day: Date, locale = 'en'): string =>
  dayjs(day).locale(locale).format('ddd ll');

export const formatMonthTitle = (month: Date, locale = 'en'): string =>
  dayjs(month).locale(locale).format('MMMM YYYY');

export const formatWeekdayShort = (weekday: number, locale = 'en'): string =>
  dayjs().locale(locale).localeData().weekdaysMin()[weekday];

export const formatWeekdayLong = (weekday: number, locale = 'en'): string =>
  dayjs().locale(locale).localeData().weekdays()[weekday];

export const getFirstDayOfWeek = (locale = 'en'): number =>
  dayjs().locale(locale).localeData().firstDayOfWeek();

export const getMonths = (
  locale = 'en',
): [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
] => dayjs().locale(locale).localeData().months();

export const parseDate = (str: string, format = 'L', locale = 'en'): Date =>
  dayjs(str, { format, locale }, true).toDate();
