export const STORAGE_KEY = 'AFFILIATE_PARTNERS';

export enum ProviderNames {
  Google = 'google',
  Display = 'display',
  YouTube = 'youtube',
  PerformanceMax = 'pmax',
  Facebook = 'facebook',
  Nestpick = 'nestpick',
  HomeToGo = 'hometogo',
  Affiliate = 'affiliate',
}

export enum ProviderParams {
  Google = 'gclid',
  Facebook = 'fbclid',
  NestpickTransactionId = 'nestpick_transaction_id',
  AffiliatePartnerId = 'affiliate_partner_id',
}

export const emptyState = {
  affiliateSource: {
    provider: null,
    clickId: null,
  },
  adTouchPoints: null,
};
