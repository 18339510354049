import { BillsValue } from '../../../types/SearchFilters';
import { FilterBuilder } from '../Types';

const billsBuilder: FilterBuilder = ({ bills = [] }) => {
  if (
    bills.length === 0 ||
    (bills.includes(BillsValue.INCLUDED) && bills.includes(BillsValue.EXCLUDED))
  ) {
    return '';
  }

  return {
    [BillsValue.INCLUDED]: 'utilities:I',
    [BillsValue.EXCLUDED]: 'utilities:E OR utilities:P',
  }[bills[0]];
};

export { billsBuilder };
