import UrlPattern from 'url-pattern';

import { Language } from '../../lib/types';

import {
  AnswersSupportedLangs,
  ContentfulSupportedLanguages,
  aboutPages,
  directMessaging,
  easyPropertyManagement,
  globalReach,
  homePages,
  integrations,
  onlineViewing,
  PaymentsUrls,
  renting,
  rentingOut,
  securePayments,
  smartSearch,
  superiorMatching,
} from './constants';

export const marketingPages = [
  ...Object.values(PaymentsUrls),
  aboutPages,
  directMessaging,
  easyPropertyManagement,
  globalReach,
  homePages,
  integrations,
  onlineViewing,
  renting,
  rentingOut,
  securePayments,
  smartSearch,
  superiorMatching,
];

export const isContentfulSupported = (lang: Language) =>
  ContentfulSupportedLanguages.includes(lang);

const languageUrls = (lang: Language) => marketingPages.map(page => page[lang]);

export const getMarketingPageIndex = (slug: string, lang: Language) =>
  languageUrls(lang).indexOf(`/${slug}`);

export const getMarketingPageUrl = (pageIndex: number, lang: Language) =>
  languageUrls(lang)[pageIndex];

const getAnswersPageUrl = (link: string, lang: Language) => {
  const answersMatchPattern = new UrlPattern(
    '(http(s)\\://)answers.housinganywhere.:tld(/*)',
  );

  if (
    !AnswersSupportedLangs.includes(lang) ||
    !answersMatchPattern.match(link)
  ) {
    return undefined;
  }

  return link.replace(`/${Language.en}/`, `/${lang}/`);
};

export const getSubPageUrl = (link: string | undefined, lang: Language) => {
  const myMatchPattern = new UrlPattern('(/:lang/)my(/*)');
  if (
    !link ||
    isContentfulSupported(lang) ||
    Boolean(myMatchPattern.match(link))
  ) {
    return link;
  }

  const answersPageUrl = getAnswersPageUrl(link, lang);
  if (answersPageUrl) {
    return answersPageUrl;
  }

  const pageIndex = getMarketingPageIndex(link, Language.en);
  if (pageIndex >= 0) {
    return getMarketingPageUrl(pageIndex, lang).substring(1);
  }

  return undefined;
};
