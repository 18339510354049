import type { MakeCall } from '../createMakeCall';

export interface RoutingIDs {
  listingID: number;
  unitTypeID: number;
}

type Payload = {
  payoutMethodId: string;
  payoutRoutingLinkIDs: RoutingIDs[];
};

const updatePayoutMethodRouting =
  (makeCall: MakeCall) => (userId: number, data: Payload) =>
    makeCall<void>('POST', `/api/v2/user/${userId}/payout-routing/link`, {
      data: {
        payoutMethodGuid: data.payoutMethodId,
        payoutRoutingLinkIDs: data.payoutRoutingLinkIDs,
      },
    });

export { updatePayoutMethodRouting };
