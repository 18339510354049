import { MakeCall } from './createMakeCall';

import { PayoutMethod } from './types/PayoutMethods';
import { PayoutMethodPayload } from './types/UserPayoutMethod';

const updatePayoutMethod =
  (makeCall: MakeCall) =>
  (methodId: number | string, data: PayoutMethodPayload) =>
    makeCall<PayoutMethod>('PUT', `/api/v2/user/me/payout-method/${methodId}`, {
      data,
    });
export { updatePayoutMethod };
