import { MakeCall } from './createMakeCall';

import { KycDocumentsResponse } from './types/Kyc';

const getKycDocuments =
  (makeCall: MakeCall) => (userId: 'me' | number, kycUuid: string) =>
    makeCall<KycDocumentsResponse>(
      'GET',
      `/api/v2/user/${userId}/kyc/${kycUuid}/documents`,
    );
export { getKycDocuments };
