import { handleActions } from '@housinganywhere/safe-redux';
import { ExperimentsActionsType } from './actions';
import { ActionTypes } from './constants';

import { ExperimentsState } from './types';

const initialState: ExperimentsState = {
  played: [],
  variants: {},
  userIdentifier: '',
};

export const reducer = handleActions<
  ExperimentsState,
  ActionTypes,
  ExperimentsActionsType
>(
  {
    [ActionTypes.PLAY_EXPERIMENT]: (state, { payload }) => ({
      ...state,
      played: [...state.played, payload],
    }),
    [ActionTypes.SET_VARIANTS]: (
      state,
      { payload: { variants, userIdentifier = '' } },
    ) => ({
      ...state,
      variants: {
        ...state.variants,
        ...variants,
      },
      userIdentifier,
    }),
  },
  initialState,
);
