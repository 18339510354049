import { Language } from '@ha/intl';
import { MakeCall } from '../createMakeCall';
import { Currency } from '../types/Currencies';

import { UnitTypesSummary } from '../types/inventory/UnitType';

export interface Query {
  userId: number | null;
  withUnitTypeState?: string;
  minPrice?: number;
  maxPrice?: number;
  currency?: Currency;
  from?: string;
  to?: string;
  lang?: Language;
  query?: string;
  withCharitable?: boolean;
}

export type GetUnitTypesSummaryCall = (
  data: Query,
) => Promise<{ data: UnitTypesSummary }>;

const getUnitTypesSummary =
  (makeCall: MakeCall) =>
  ({
    currency,
    from,
    lang,
    maxPrice,
    minPrice,
    query,
    to,
    userId,
    withCharitable,
    withUnitTypeState,
  }: Query) =>
    makeCall<UnitTypesSummary>('GET', `/api/v2/unit-types/summary`, {
      query: {
        currency,
        from,
        lang,
        maxPrice,
        minPrice,
        query,
        to,
        userId,
        withCharitable,
        withUnitTypeState,
      },
    });
export { getUnitTypesSummary };
