import { FilterBuilder } from '../Types';

export const excludeAdvertiserBuilder: FilterBuilder = ({
  excludeAdvertiserId,
}) => {
  if (!excludeAdvertiserId) {
    return '';
  }

  return `advertiserId!=${excludeAdvertiserId}`;
};
