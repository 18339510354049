import type { MakeCall } from '../createMakeCall';
import { QualificationQualifyUser } from '../types/qualifications/QualificationQualifyUser';

export type Payload = {
  listingUuid?: string;
  unitTypeUuid?: string;
};

export type Response = QualificationQualifyUser;

const qualificationQualifyUser = (makeCall: MakeCall) => (data: Payload) =>
  makeCall<Response>('POST', `/api/v2/qualification/qualify-user`, { data });
export { qualificationQualifyUser };
