import { Language } from '../../../lib/types';

export const PaymentsUrls = {
  tenant: {
    [Language.en]: '/online-rent-payment',
    [Language.fr]: '/paiement-locatif-en-ligne',
    [Language.de]: '/online-mietzahlung',
    [Language.es]: '/pago-alquiler-online',
    [Language.it]: '/pagamento-affitto-online',
    [Language.nl]: '/online-huur-betalen',
    [Language.pt]: '/pagamento-de-aluguel-online',
    // [Language.zh]: '/在线支付租金',
    [Language.zh]: '/online-rent-payment',
    [Language.uk]: '/онлайн-оплата-оренди',
    [Language.ru]: '/онлайн-оплата-аренды',
    [Language.pl]: '/platnosc-czynszu-online',
    [Language.sv]: '/hyresbetalning-online',
    [Language.ro]: '/plata-chiriei-online',
  },
  landlord: {
    [Language.en]: '/online-rent-collection',
    [Language.fr]: '/loyers-en-ligne',
    [Language.de]: '/online-mieteinzuege',
    [Language.es]: '/cobro-alquiler-online',
    [Language.it]: '/riscuoti-gli-affitti-online',
    [Language.nl]: '/online-huurinkomsten-beheren',
    [Language.pt]: '/cobranca-de-aluguel-online',
    // [Language.zh]: '/网上收租',
    [Language.zh]: '/online-rent-collection',
    [Language.uk]: '/збір-орендної-плати-онлайн',
    [Language.ru]: '/получайте-аренду-онлайн',
    [Language.pl]: '/pobieranie-czynszu-online',
    [Language.sv]: '/hyresuppbord-online',
    [Language.ro]: '/incasarea-chiriei-online',
  },
};
