import type { MakeCall } from '../createMakeCall';
import { QualificationRequirementDefault } from '../types/qualifications/QualificationRequirement';
import { RequiredDocuments } from '../types/RequiredDocuments';

const updateQualificationRequirementsDefaults =
  (makeCall: MakeCall) =>
  (applyToExistingListing: boolean, data: Array<RequiredDocuments>) =>
    makeCall<QualificationRequirementDefault>(
      'PUT',
      `/api/v2/qualification/requirements/defaults/`,
      {
        data: {
          applyToExistingListing,
          documents: data,
        },
      },
    );
export { updateQualificationRequirementsDefaults };
