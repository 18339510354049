import { Client, ClientOptions } from '@twilio/conversations';

const twilio = () => {
  let client: Promise<Client> | null;

  return {
    init: (token: string, options?: ClientOptions) => {
      if (client) {
        return client;
      }

      client = import(
        /* webpackChunkName: "twilio-conversations" */ '@twilio/conversations'
      )
        .then(module => {
          return new module.Client(token, options);
        })
        .catch(err => {
          client = null;
          throw err;
        });

      return client;
    },
  };
};
export { twilio };
