import { createBrowserRouter } from 'react-router-dom';

import { apiV2 as createApiV2 } from '@ha/api/v2/api-v2';

import { Config } from 'ha/config';

import { FetchClient } from 'ha/helpers/FetchClient';
import { IntlObject } from 'ha/i18n';

import { TokamakConfig } from 'ha/server/config';
import type { FlagsmithClient } from 'ha/server/routers/ha/utils/makeFlagsmithClient';

import { createAlgoliaService as algoliaService } from './algolia';
import { makeAlmanacService } from './almanac';
import { makeAnalyticsService } from './analytics';
import { makeBellService, makeBellServiceV2 } from './bell';
import { CookieClient, makeCookieService } from './cookie';
import { createFBService as makeFBService } from './facebook';
import { GoogleMapApi as googleMapApi } from './GoogleMapApi';
import { makeHermesService } from './hermes';
import { makeMetisService } from './metis';
import { createOsiris as makeOsirisService } from './osiris';
import { createTranslationService } from './Translation';
import { twilio as makeTwilioService } from './twilio';

interface Clients {
  haApi: FetchClient;
  osiris: FetchClient;
  hermes: FetchClient;
  almanac: FetchClient;
  metis: FetchClient;
  bell: FetchClient;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  algolia: ReturnType<typeof algoliaService>;
  flagsmith?: FlagsmithClient;
  cookie: CookieClient;
}
interface AppServicesSetup {
  config: Config;
  intl: IntlObject;
  clients: Clients;
  serverConfig?: Pick<TokamakConfig, 'google'>;
  routerRef: { current: ReturnType<typeof createBrowserRouter> | null };
}

export function getAppServices({
  config,
  intl,
  clients,
  serverConfig,
  routerRef,
}: AppServicesSetup) {
  return {
    intl,
    routerRef,
    translation: createTranslationService(
      {
        languageDetectionURL: config.languageDetectionURL,
      },
      clients.haApi,
    ),
    algolia: clients.algolia,
    facebook: makeFBService({
      clientId: config.facebook.client_id,
      clientToken: config.facebookClientToken,
    }),
    googleMapApi: googleMapApi({
      key: config.google.maps_key,
      language: intl.lang,
      secret: serverConfig?.google?.secret,
    }),
    apiV2: createApiV2(clients.haApi),
    hermes: makeHermesService(clients.hermes),
    almanac: makeAlmanacService(clients.almanac),
    metis: makeMetisService(clients.metis),
    osiris: makeOsirisService(clients.osiris),
    bell: makeBellService(clients.bell),
    bellV2: makeBellServiceV2(clients.bell),
    cookie: makeCookieService(clients.cookie),
    analytics: makeAnalyticsService(config.analytics),
    twilio: makeTwilioService(),
    flagsmith: clients.flagsmith,
  };
}

export type AppServices = ReturnType<typeof getAppServices>;
