import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';
import { Thunk } from 'ha/myredux/types';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { getListingsSupportedCurrenciesFetchStatus } from '../selectors';

export enum ActionTypes {
  START = '[supportedCurrencies] LOAD_SUPPORTED_CURRENCIES',
  DONE = '[supportedCurrencies] LOAD_SUPPORTED_CURRENCIES_DONE',
  FAIL = '[supportedCurrencies] LOAD_SUPPORTED_CURRENCIES_FAIL',
}

export const Actions = {
  start: () => createAction(ActionTypes.START),
  done: (currencies: string[]) => createAction(ActionTypes.DONE, currencies),
  fail: () => createAction(ActionTypes.FAIL),
};
export type ActionsUnionType = ActionsUnion<typeof Actions>;

export const fetchListingsSupportedCurrencies =
  (): Thunk =>
  (dispatch, getState, { apiV2 }) => {
    const state = getState();

    const fetchStatus = getListingsSupportedCurrenciesFetchStatus(state);
    if (fetchStatus === 'success' || fetchStatus === 'loading') {
      return Promise.resolve();
    }

    dispatch(Actions.start());

    return apiV2
      .getListingsConfiguration({
        expand: 'currencies',
      })
      .then(({ data }) => {
        dispatch(Actions.done(data.allowedCurrencies || []));
      })
      .catch(error => {
        reportError('Error getting the supported currencies', {
          metaData: { error },
        });
        dispatch(Actions.fail());
      });
  };
