import { defineKey as T } from '@ha/intl';

export const STATE_NAME = 'profileEdit';

export enum ProfileSectionsValues {
  PERSONAL_INFORMATION = 'personal_information',
  SUPPORTING_DOCUMENTS = 'supporting_documents',
  CONTACT_INFORMATION = 'contact_information',
  CHANGE_PASSWORD = 'change_password',
  NOTIFICATION_SETTINGS = 'notification_settings',
  BILLING_SUBSCRIPTIONS = 'billing_subscriptions',
}

/**
 * @description use the useSectionName hook instead of calling this const directly
 */
export const ProfileSectionsNames = {
  [ProfileSectionsValues.PERSONAL_INFORMATION]: T('account.profile.title'),
  [ProfileSectionsValues.SUPPORTING_DOCUMENTS]: {
    advertiser: T('editProfile.DocumentsAdvertiser_navmenu'),
    tenant: T('editProfile.DocumentsTenants_navmenu'),
  },
  [ProfileSectionsValues.CONTACT_INFORMATION]: T('editProfile.Contact_navmenu'),
  [ProfileSectionsValues.CHANGE_PASSWORD]: T('editProfile.Password_navmenu'),
  [ProfileSectionsValues.NOTIFICATION_SETTINGS]: T(
    'editProfile.Notifications_navmenu',
  ),
  [ProfileSectionsValues.BILLING_SUBSCRIPTIONS]: T(
    'account.subscription.title',
  ),
};
