import memoize from 'lodash-es/memoize';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { Locale } from './types';
import { isSameMonth, isSameYear } from 'date-fns';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);

/**
 * @todo use `dateStyle` and `timeStyle` when have sufficient support
 * https://caniuse.com/mdn-javascript_builtins_intl_datetimeformat_datetimeformat_datestyle
 * https://caniuse.com/mdn-javascript_builtins_intl_datetimeformat_datetimeformat_timestyle
 */
export const DateTimeFormat = {
  DEFAULT: {},
  DATE: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
  DATE_LONG: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
  DATE_SHORT: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  DATE_PARTIAL: {
    month: 'long',
    year: 'numeric',
  },
  YEAR: {
    year: 'numeric',
  },
  MONTH: {
    month: 'long',
  },
  WEEKDAY: {
    weekday: 'long',
  },
  TIME: {
    hour: 'numeric',
    minute: 'numeric',
  },
  DATE_TIME: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',

    hour: 'numeric',
    minute: 'numeric',
  },
  DATE_TIME_LONG: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',

    hour: 'numeric',
    minute: 'numeric',
  },
} satisfies Record<string, Intl.DateTimeFormatOptions>;

type FormatDateTimeRangeOptions = Intl.DateTimeFormatOptions & {
  compact?: boolean;
};

export const DateTimeRangeFormat = {
  DATE_SHORT_COMPACT: {
    compact: true,

    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
} satisfies Record<string, FormatDateTimeRangeOptions>;

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;

export function createDateTimeFormat(locale: Locale) {
  const getDateTimeFormatter = memoize(
    (options: Intl.DateTimeFormatOptions) =>
      new Intl.DateTimeFormat(locale, options),
    options => JSON.stringify(options, Object.keys(options).sort()),
  );

  const getRelativeTimeFormatter = memoize(
    (options: Intl.RelativeTimeFormatOptions) =>
      new Intl.RelativeTimeFormat(locale, options),
    options => JSON.stringify(options, Object.keys(options).sort()),
  );

  function formatDateTime(
    date: Date,
    options: Intl.DateTimeFormatOptions = DateTimeFormat.DEFAULT,
  ) {
    return getDateTimeFormatter(options).format(date);
  }

  function formatDateTimeRange(
    value1: Date | null | undefined,
    value2: Date | null | undefined,
    {
      compact = false,
      ...options
    }: FormatDateTimeRangeOptions = DateTimeFormat.DEFAULT,
  ) {
    let value = '';

    if (value1) {
      const adjustedOptions: Intl.DateTimeFormatOptions = {
        ...options,
        ...(compact && value2
          ? {
              ...(isSameMonth(value1, value2) ? { month: undefined } : {}),
              ...(isSameYear(value1, value2) ? { year: undefined } : {}),
            }
          : {}),
      };

      value += formatDateTime(value1, adjustedOptions);
    }

    if (value2) {
      value += value ? ' – ' : '';
      value += formatDateTime(value2, options);
    }

    return value;
  }

  function formatRelativeTime(date: Date) {
    const now = Date.now();
    const delta = Math.round(date.getTime() - now);
    const deltaAbs = Math.abs(delta);

    let amount = Math.floor(delta / SECOND);
    let unit: Intl.RelativeTimeFormatUnit = 'seconds';

    if (deltaAbs >= DAY * 365) {
      // amount = Math.floor(delta / YEAR);
      amount = date.getFullYear() - new Date(now).getFullYear(); // accounts for leap year
      unit = 'year';
    } else if (deltaAbs >= 28 * DAY) {
      amount =
        delta <= 0
          ? -new Date(deltaAbs).getMonth()
          : new Date(delta).getMonth(); // accounts for variable number of days
      if (amount === 0) {
        amount = delta <= 0 ? -1 : 1;
      }
      unit = 'month';
    } else if (deltaAbs >= WEEK) {
      amount = Math.floor(delta / WEEK);
      unit = 'week';
    } else if (deltaAbs >= DAY) {
      amount = Math.floor(delta / DAY);
      unit = 'day';
    } else if (deltaAbs >= HOUR) {
      amount = Math.floor(delta / HOUR);
      unit = 'hour';
    } else if (deltaAbs >= MINUTE) {
      amount = Math.floor(delta / MINUTE);
      unit = 'minute';
    }

    return getRelativeTimeFormatter({
      numeric: 'auto',
    }).format(amount, unit);
  }

  /**
   * @deprecated use `formatDateTime`
   */
  function formatDate(format: string, date: Date | string | undefined) {
    return dayjs(date).locale(locale).format(format);
  }

  return {
    getDateTimeFormatter,
    getRelativeTimeFormatter,
    formatDateTime,
    formatDateTimeRange,
    formatRelativeTime,
    /**
     * @deprecated use `formatDateTime`
     */
    formatDate,
  };
}
