import { MakeCall } from './createMakeCall';

const toggleFavoriteConversation =
  (makeCall: MakeCall) => (conversationID: number, toFavor: boolean) =>
    makeCall<undefined>(
      'PUT',
      `/api/v2/conversation/${conversationID}/${
        toFavor ? 'favorite' : 'unfavorite'
      }`,
    );
export { toggleFavoriteConversation };
