export const STATE_NAME = 'verifyPhoneNumber';

const ACTION_PREFIX = 'VERIFY_PHONE_NUMBER';

export const Actions = {
  UPDATE: `${ACTION_PREFIX}/UPDATE`,
  SET_EXPIRE_TIME: `${ACTION_PREFIX}/SET_EXPIRE_TIME`,
};

export const TIME_TO_REQUEST_ANOTHER_CODE = 30000;
export const CODE_REQUEST_TYPES = {
  SMS: 'sms',
  VOICE: 'voice',
};
