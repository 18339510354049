type Fn<U> = () => Promise<U>;

interface RetryProps<E extends Error> {
  retries?: number;
  interval?: number;
  shouldRetry?: (error: E) => boolean;
}

// https://en.wikipedia.org/wiki/Exponential_backoff
// E -> factor
// c -> number of trys
// I -> Interval
//
// E(c) = (2^c - 1) / 2
// E(c) * I
export const backOff = (times: number, interval: number) =>
  ((2 ** times - 1) / 2) * interval;

const delay = (n: number) =>
  new Promise(res => {
    setTimeout(res, n);
  });

const retry = <U, E extends Error>(
  count: number,
  fn: Fn<U>,
  config?: RetryProps<E>,
): Promise<U> => {
  const {
    retries = 5,
    interval = 5000,
    shouldRetry = () => true,
  } = config || {};

  return fn().catch(err => {
    if (!shouldRetry(err) || retries === 1) {
      return Promise.reject(err);
    }

    return delay(backOff(count, interval)).then(() =>
      retry(count + 1, fn, {
        retries: retries - 1,
        interval,
      }),
    );
  });
};

const asyncRetry = <U, E extends Error = Error>(
  fn: Fn<U>,
  config?: RetryProps<E>,
) => retry(1, fn, config);
export { asyncRetry };
