import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';
import { Thunk } from 'ha/myredux/types';

import { reportError } from 'ha/helpers/bugReporter/reportError';

import { getListingsSupportedCountriesFetchStatus } from '../selectors';

export enum ActionTypes {
  START = '[supportedCountries] LOAD_SUPPORTED_COUNTRIES',
  DONE = '[supportedCountries] LOAD_SUPPORTED_COUNTRIES_DONE',
  FAIL = '[supportedCountries] LOAD_SUPPORTED_COUNTRIES_FAIL',
}

export const Actions = {
  start: () => createAction(ActionTypes.START),
  done: (countries: string[]) => createAction(ActionTypes.DONE, countries),
  fail: () => createAction(ActionTypes.FAIL),
};
export type ActionsUnionType = ActionsUnion<typeof Actions>;

export const fetchListingsSupportedCountries =
  (): Thunk =>
  (dispatch, getState, { apiV2 }) => {
    const state = getState();

    const fetchStatus = getListingsSupportedCountriesFetchStatus(state);
    if (fetchStatus === 'success' || fetchStatus === 'loading') {
      return Promise.resolve();
    }

    dispatch(Actions.start());

    return apiV2
      .getListingsConfiguration({
        expand: 'countries',
      })
      .then(({ data }) => {
        dispatch(Actions.done(data.allowedCountries || []));
      })
      .catch(error => {
        reportError('Error getting the supported countries', {
          metaData: { error },
        });
        dispatch(Actions.fail());
      });
  };
