import { RulesValue, Options } from '../../../types/SearchFilters';

import { FilterBuilder } from '../Types';

const rulesBuilder: FilterBuilder = ({ rules }) => {
  if (!rules || (rules && rules.length === 0)) {
    return '';
  }

  const query: string[] = [];

  if (rules.includes(RulesValue.SMOKING)) {
    query.push(
      `(${[Options.YES, Options.DISCUSSABLE, Options.OUTSIDE]
        .map(i => `facility_smoking_allowed:${i}`)
        .join(' OR ')})`,
    );
  }

  if (rules.includes(RulesValue.PETS)) {
    query.push(
      `(${[Options.YES, Options.DISCUSSABLE]
        .map(i => `facility_animal_allowed:${i}`)
        .join(' OR ')})`,
    );
  }

  if (rules.includes(RulesValue.INSTRUMENT)) {
    query.push(
      `(${[Options.YES, Options.DISCUSSABLE]
        .map(i => `facility_play_music:${i}`)
        .join(' OR ')})`,
    );
  }

  return query.join(' AND ');
};

export { rulesBuilder };
