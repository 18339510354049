import { isContentfulSupported } from '@ha/contentful';
import { Language } from '@ha/intl';

import { FeaturedCountries } from 'ha/constants/FeaturedCountries';

const isEqualPath = (pathname: string, slug: string, lang: Language) => {
  if (lang === Language.en) {
    return true;
  }

  return `/${lang}/${slug}` === pathname;
};

const getPath = (pathname: string, lang: Language) => {
  if (isContentfulSupported(lang)) {
    return pathname;
  }
  const featuredCountry = FeaturedCountries.find(country =>
    isEqualPath(pathname, country.name, lang),
  );
  if (featuredCountry) {
    return `/${featuredCountry.name}`;
  }
  return pathname;
};

export { getPath };
