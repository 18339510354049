import { handleActions } from '@housinganywhere/safe-redux';

import { ActionsUnionType, ActionTypes } from './actions';
import { HermesState } from './types';

export const initialState = {
  info: null,
  data: null,
  error: false,
};

const reducer = handleActions<HermesState, ActionTypes, ActionsUnionType>(
  {
    [ActionTypes.START]: state => ({
      ...state,
      error: false,
    }),
    [ActionTypes.DONE]: (state, { payload }) => ({
      ...state,
      ...payload,
      error: false,
    }),
    [ActionTypes.NOT_FOUND]: () => initialState,
    [ActionTypes.FAIL]: () => ({
      ...initialState,
      error: true,
    }),
  },
  initialState,
);

export { reducer };
