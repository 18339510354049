import memoize from 'lodash-es/memoize';
import { Locale, CurrencyFormatOptions } from './types';

export const NumberFormat = {} as Record<
  'DEFAULT' | 'NO_FRACTION' | 'NO_FRACTION_NO_GROUPING',
  Intl.NumberFormatOptions
>;

NumberFormat.DEFAULT = {};

NumberFormat.NO_FRACTION = {
  ...NumberFormat.DEFAULT,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

NumberFormat.NO_FRACTION_NO_GROUPING = {
  ...NumberFormat.NO_FRACTION,
  useGrouping: false,
};

export const CurrencyFormat = {} as Record<
  | 'DEFAULT'
  | 'DEFAULT_NO_FRACTION'
  | 'WITH_CURRENCY_CODE'
  | 'WITH_CURRENCY_SYMBOL'
  | 'WITH_CURRENCY_CODE_NO_FRACTION'
  | 'WITH_CURRENCY_CODE_NO_FRACTION_NO_GROUPING'
  | 'WITH_CURRENCY_SYMBOL_NO_FRACTION'
  | 'WITH_CURRENCY_CODE_DOUBLE_FRACTION'
  | 'WITH_CURRENCY_SYMBOL_NO_FRACTION_NO_GROUPING'
  | 'WITH_CURRENCY_SYMBOL_DOUBLE_FRACTION',
  CurrencyFormatOptions
>;

CurrencyFormat.DEFAULT = {
  style: 'currency',
  currencyDisplay: 'code',
  withCurrency: false,
};

CurrencyFormat.DEFAULT_NO_FRACTION = {
  ...CurrencyFormat.DEFAULT,
  ...NumberFormat.NO_FRACTION,
};

/* Recommended presets */

CurrencyFormat.WITH_CURRENCY_CODE = {
  ...CurrencyFormat.DEFAULT,
  currencyDisplay: 'code',
  withCurrency: true,
};

CurrencyFormat.WITH_CURRENCY_SYMBOL = {
  ...CurrencyFormat.DEFAULT,
  currencyDisplay: 'symbol',
  withCurrency: true,
};

CurrencyFormat.WITH_CURRENCY_CODE_NO_FRACTION = {
  ...NumberFormat.NO_FRACTION,
  ...CurrencyFormat.WITH_CURRENCY_CODE,
};

CurrencyFormat.WITH_CURRENCY_CODE_NO_FRACTION_NO_GROUPING = {
  ...NumberFormat.NO_FRACTION_NO_GROUPING,
  ...CurrencyFormat.WITH_CURRENCY_CODE,
};

CurrencyFormat.WITH_CURRENCY_SYMBOL_NO_FRACTION = {
  ...NumberFormat.NO_FRACTION,
  ...CurrencyFormat.WITH_CURRENCY_SYMBOL,
};

CurrencyFormat.WITH_CURRENCY_SYMBOL_NO_FRACTION_NO_GROUPING = {
  ...NumberFormat.NO_FRACTION_NO_GROUPING,
  ...CurrencyFormat.WITH_CURRENCY_SYMBOL,
};

/* Custom purpose-made presets  */

CurrencyFormat.WITH_CURRENCY_CODE_DOUBLE_FRACTION = {
  ...CurrencyFormat.DEFAULT,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  withCurrency: true,
};

CurrencyFormat.WITH_CURRENCY_SYMBOL_DOUBLE_FRACTION = {
  ...CurrencyFormat.WITH_CURRENCY_SYMBOL,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: false,
};

const UnitFormat: Record<string, Intl.NumberFormatOptions> = {};

UnitFormat.DEFAULT = {
  style: 'unit',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  unitDisplay: 'long',
};

const CURRENCY_REMOVAL_RE = /(?:^[^\d,.]+)|(?:[^\d,.]+$)/gi;

export function createNumberFormat(locale: Locale) {
  const getFormatter = memoize(
    (options: Intl.NumberFormatOptions) =>
      new Intl.NumberFormat(locale, options),
    options => JSON.stringify(options, Object.keys(options).sort()),
  );

  const getFormatterWithDynamicLocale = memoize(
    (options: Intl.NumberFormatOptions, dynamicLocale: Locale) =>
      new Intl.NumberFormat(dynamicLocale, options),
    options => JSON.stringify(options, Object.keys(options).sort()),
  );

  function formatNumber(value: number, options?: Intl.NumberFormatOptions) {
    return getFormatter(options || NumberFormat.DEFAULT).format(value);
  }

  function formatCurrency(
    /** amount in cents */
    value: number,
    currency: string,
    options?: CurrencyFormatOptions,
  ) {
    const isUsdCurrency = currency === 'USD';
    const currencyFormatOptions = isUsdCurrency
      ? CurrencyFormat.WITH_CURRENCY_SYMBOL_NO_FRACTION_NO_GROUPING
      : CurrencyFormat.DEFAULT;

    const intlOptions = {
      ...currencyFormatOptions,
      ...options,
      style: 'currency',
      currency,
      ...(isUsdCurrency && { useGrouping: true }),
    };

    if (isUsdCurrency) {
      intlOptions.useGrouping = true;
    }

    const { withCurrency, ...restIntlOptions } = intlOptions;

    const formatter = isUsdCurrency
      ? getFormatterWithDynamicLocale(restIntlOptions, Locale.us)
      : getFormatter(restIntlOptions);

    let result = formatter.format(value / 100);

    if (!withCurrency) {
      result = result.replace(CURRENCY_REMOVAL_RE, '');
    }

    if (process.env.NODE_ENV === 'test') {
      return result.replace(/\s/gi, ' ');
    }

    return result;
  }

  function formatCurrencyRange(
    currency: string,
    /** first amount in cents */
    value1: number | null | undefined,
    /** second amount in cents */
    value2: number | null | undefined,
    options?: CurrencyFormatOptions,
  ) {
    let value = '';

    if (value1 !== undefined && value1 !== null) {
      value += formatCurrency(value1, currency, options);
    }

    if (value2 !== undefined && value2 !== null) {
      value += value ? ' - ' : '';
      value += formatCurrency(value2, currency, options);
    }

    return value;
  }

  function formatUnit(
    value: number,
    unit: string,
    options?: Intl.NumberFormatOptions,
  ) {
    const intlOptions = {
      ...UnitFormat.DEFAULT,
      ...options,
      style: 'unit',
      unit,
    };

    return getFormatter(intlOptions).format(value);
  }

  return {
    formatNumber,
    formatCurrency,
    formatCurrencyRange,
    formatUnit,
  };
}
