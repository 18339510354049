interface CustomErrorObject {
  ErrorMsg?: string;
  error?: string;
  message?: string;
  key?: string;
  detail?: string;
  body?: {
    errors?: Array<{
      detail: string;
    }>;
  };
}

export function getMessageFromError(
  error: Error | CustomErrorObject | string,
): string {
  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error === 'string') {
    return error;
  }

  if (error.ErrorMsg) {
    return error.ErrorMsg;
  }

  if (error.error) {
    return error.error;
  }

  if (error.message) {
    return error.message;
  }

  if (error.key && error.detail) {
    return `${error.key}: ${error.detail}`;
  }

  if (error.body && error.body.errors) {
    return error.body.errors.reduce(
      (memo, err) => `${memo} ${err.detail}`.trim(),
      '',
    );
  }

  return 'Something goes wrong!';
}
