interface CountryData {
  title?: string;
}

export function normalizeCountryData(data: CountryData) {
  return {
    ...data,
    country: {
      title: data.title,
    },
  };
}
