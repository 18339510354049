import { MakeCall } from './createMakeCall';
import { User } from './types/User';

export interface AvatarURLPayload {
  avatarUrl: string;
}

const uploadAvatarByUrl = (makeCall: MakeCall) => (data: AvatarURLPayload) =>
  makeCall<User>('POST', `/api/v2/user/me/avatar-url`, {
    data,
  });
export { uploadAvatarByUrl };
