import { MakeCall } from './createMakeCall';
import { ListingContractType, ListingPricingType } from './types/Listing';
import { RentalConditions } from './types/RentalConditions';

export type RentalConditionsEstimateSource =
  | {
      listingId: number;
    }
  | {
      unitTypeId: number;
    }
  | {
      listingUuid: string;
    }
  | {
      conversationId: number;
    }
  | {
      conversationUuid: string;
    };

export interface RentalConditionsEstimateParameters {
  startDate: string;
  endDate: string;
  pricingType?: ListingPricingType;
  contractType?: ListingContractType;
  bookingLink?: boolean;
  strictRentalPeriodId?: number;
}

export interface RentalConditionsFeatureFlags {
  rentalConditions?: boolean;
  bookingLink?: boolean;
}

type FeatureFlagHeaders = 'bookingLink';

const getRentalConditionsEstimate =
  (makeCall: MakeCall) =>
  (
    source: RentalConditionsEstimateSource,
    parameters: RentalConditionsEstimateParameters,
    featureFlags?: RentalConditionsFeatureFlags,
  ) => {
    const features: FeatureFlagHeaders[] = [];
    if (featureFlags?.bookingLink) {
      features.push('bookingLink');
    }

    const headers =
      features.length > 0 ? { 'X-HA-Features': features.join(';') } : {};

    return makeCall<RentalConditions>(
      'POST',
      `/api/v2/bookings/rental-conditions/estimate`,
      {
        data: {
          source,
          ...parameters,
        },
        headers,
      },
    );
  };
export { getRentalConditionsEstimate };
