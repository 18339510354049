import type { MakeCall } from '../createMakeCall';
import {
  PayoutMethodLinkPayload,
  PriorityRules,
} from '../types/PayoutMethodsV2';

const linkUnitTypeToPayoutMethod =
  (makeCall: MakeCall) => (data: PayoutMethodLinkPayload) =>
    makeCall<PriorityRules>(
      'PUT',
      `/api/v2/payout-method/${data.payoutMethodGUID}/priority`,
      {
        data: { rules: [data.unitTypeId] },
      },
    );

export { linkUnitTypeToPayoutMethod };
