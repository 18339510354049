// Date filter constants
export const NO_END_DATE = 'unlimited';
export const DATE_RANGE_FORMAT = 'D MMM YYYY';

// Filter fixed values
export enum CanonicalFurniture {
  Furnished = 'furnished',
  Unfurnished = 'unfurnished',
}

export enum CanonicalBills {
  Included = 'included',
  Excluded = 'excluded',
}

export enum CanonicalRegistration {
  Possible = 'possible',
  NotPossible = 'not-possible',
}
