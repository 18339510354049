import { MakeCall } from './createMakeCall';

export interface VerificationRequest {
  email: string;
  phone: string;
  phoneCountryCode: string;
  comments: string;
}

const verifyManually =
  (makeCall: MakeCall) => (userId: number | 'me', data: VerificationRequest) =>
    makeCall<undefined>('POST', `/api/v2/user/${userId}/verify-manually`, {
      data,
    });
export { verifyManually };
