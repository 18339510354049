import { MakeCall } from '@ha/api/v2/createMakeCall';

interface Response {
  reply: string;
}

const addTenantReviewReply =
  (makeCall: MakeCall) =>
  ({ tenancyUUID, message }: { tenancyUUID: string; message: string }) =>
    makeCall<Response>(
      'POST',
      `/api/v2/review/reply?tenancyUUID=${tenancyUUID}`,
      {
        data: {
          reply: message,
        },
      },
    );

export { addTenantReviewReply };
