import { MakeCall } from '../createMakeCall';
import { UnitTypePhoto } from '../types/inventory/UnitType';

const confirmUnitTypePhotoUpload =
  (makeCall: MakeCall) => (unitTypeId: number, photoId: number) =>
    makeCall<UnitTypePhoto[]>(
      'PUT',
      `/api/v2/unit-type/${unitTypeId}/photo/${photoId}/confirm`,
    );
export { confirmUnitTypePhotoUpload };
