import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { playExperiment } from '../actions';
import { getSelectedVariants, getUserIdentifier } from '../selectors';

import { VariantProps } from './Variant';

export type ExperimentControl =
  | {
      /** indicates if experiment is enabled; defaults to true */
      enabled: boolean;
      /** indicates which variant to display when experiment is disabled with `enabled = false`; defaults to none */
      disabledVariant: string;
    }
  | { enabled?: undefined; disabledVariant?: undefined };

export const useExperiment = (
  name: string,
  { enabled = true, disabledVariant = undefined }: ExperimentControl,
) => {
  const experiments = useSelector(getSelectedVariants);
  const variant = experiments[name];

  const userIdentifier = useSelector(getUserIdentifier);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!enabled) return;

    dispatch(playExperiment(name, variant, userIdentifier));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return enabled ? variant : disabledVariant;
};

export type ExperimentProps =
  | {
      name: string;
      children: Array<React.ReactElement<VariantProps>>;
    }
  | ({
      name: string;
      children: Array<React.ReactElement<VariantProps>>;
    } & ExperimentControl);

export const Experiment = React.memo(
  ({ name, children, ...controlProps }: ExperimentProps) => {
    const variant = useExperiment(name, controlProps);

    const variants = {};

    React.Children.forEach(children, element => {
      if (React.isValidElement(element)) {
        variants[element.props.name] = element;
      }
    });

    return variant ? variants[variant] || null : null;
  },
);
