import {
  FacilitiesValue,
  HeatingOptions,
  Options,
} from '../../../types/SearchFilters';

import { FilterBuilder } from '../Types';

const facilitiesBuilder: FilterBuilder = ({ facilities }) => {
  if (!facilities || (facilities && facilities.length === 0)) {
    return '';
  }

  const query: string[] = [];

  if (facilities.includes(FacilitiesValue.PRIVATE_BATHROOM)) {
    query.push(`facility_bathroom:${Options.PRIVATE}`);
  }

  if (facilities.includes(FacilitiesValue.BALCONY_TERRACE)) {
    query.push(
      `(${[Options.PRIVATE, Options.SHARED]
        .map(i => `facility_balcony_terrace:${i}`)
        .join(' OR ')})`,
    );
  }

  if (facilities.includes(FacilitiesValue.GARDEN)) {
    query.push(
      `(${[Options.PRIVATE, Options.SHARED]
        .map(i => `facility_garden:${i}`)
        .join(' OR ')})`,
    );
  }

  if (facilities.includes(FacilitiesValue.KITCHEN)) {
    query.push(
      `(${[Options.PRIVATE, Options.SHARED]
        .map(i => `facility_kitchen:${i}`)
        .join(' OR ')})`,
    );
  }

  if (facilities.includes(FacilitiesValue.PET_FRIENDLY)) {
    query.push(
      'facility_animal_allowed:yes OR facility_animal_allowed:discussable',
    );
  }
  if (facilities.includes(FacilitiesValue.STUDENT_HOUSING)) {
    query.push('facility_student_housing:yes');
  }

  if (facilities.includes(FacilitiesValue.PARKING)) {
    query.push(
      `(${[Options.PRIVATE, Options.SHARED]
        .map(i => `facility_parking:${i}`)
        .join(' OR ')})`,
    );
  }

  if (facilities.includes(FacilitiesValue.WHEELCHAIR_ACCESSIBLE)) {
    query.push('facility_wheelchair_accessible:yes');
  }

  if (facilities.includes(FacilitiesValue.BED)) {
    query.push('facility_bed:yes');
  }

  if (facilities.includes(FacilitiesValue.HEATING)) {
    query.push(
      `(${[
        HeatingOptions.CENTRAL,
        HeatingOptions.ELECTRICAL,
        HeatingOptions.GAS,
        HeatingOptions.WOOD_STOVE,
      ]
        .map(i => `facility_heating:${i}`)
        .join(' OR ')})`,
    );
  }

  if (facilities.includes(FacilitiesValue.BASEMENT)) {
    query.push(
      `(${[Options.PRIVATE, Options.SHARED]
        .map(i => `facility_basement:${i}`)
        .join(' OR ')})`,
    );
  }

  return query.join(' AND ');
};

export { facilitiesBuilder };
