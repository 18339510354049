import { MakeCall } from '@ha/api/v2/createMakeCall';

import { PaymentPlanDetails } from './types';

const getPendingPaymentPlan =
  (makeCall: MakeCall) =>
  (
    paymentPlanToken: string,
  ): Promise<{ data: PaymentPlanDetails; status: number }> =>
    makeCall<PaymentPlanDetails>(
      'GET',
      `/api/v1/plans/pending/${paymentPlanToken}`,
    );
export { getPendingPaymentPlan };
