import { MakeCall } from './createMakeCall';
import { ConversationsCounters } from './types/ConversationsCounters';

export interface GetConversationCountersQuery {
  listingId?: number;
  /** enable controlled release starting with Managed Accounts  */
  stateFF?: boolean;
}

export type CountersResponse = ConversationsCounters;

const getConversationCounters =
  (makeCall: MakeCall) =>
  (userId: number, query?: GetConversationCountersQuery) =>
    makeCall<CountersResponse>(
      'GET',
      `/api/v2/conversations/counters/user/${userId}`,
      { query },
    );
export { getConversationCounters };
