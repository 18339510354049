import dayjs from 'dayjs';
import { TranslateFunc, FormatDateFunc } from '@ha/intl';

import { DateRange } from './types';
import { NO_END } from './constants';

export function getAvailabilityLabel(
  bookablePeriods: DateRange[] = [],
  T: TranslateFunc,
  formatDate: FormatDateFunc,
  isMultiUnit?: boolean,
): string {
  if (!bookablePeriods?.length) {
    return T('Unavailable');
  }

  let startDate = bookablePeriods[0].from;
  let endDate = bookablePeriods[0].to;

  if (isMultiUnit) {
    bookablePeriods.forEach(period => {
      if (
        dayjs(period.from).isBefore(startDate) ||
        (dayjs(period.from).isSame(dayjs(startDate)) &&
          dayjs(period.to).isAfter(endDate))
      ) {
        startDate = period.from;
        endDate = period.to;
      }
    });
  }

  let start = formatDate('D MMM YYYY', startDate);
  let end = formatDate('D MMM YYYY', endDate);

  if (dayjs().isAfter(startDate) && dayjs().isBefore(endDate)) {
    start = T('Now');
  }

  if (dayjs(endDate).isSame(NO_END)) {
    end = T('Unlimited');
  }

  return `${start} - ${end}`;
}
