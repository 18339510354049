import { handleActions } from '@housinganywhere/safe-redux';
import { ActionsUnionType, ActionTypes } from './actions';
import { Ui } from './types';

const initialState: Ui = {
  isMobile: false,
  isTablet: false,
  isIos: false,
  iosVersion: NaN,
  mediaQueryBreakpoints: {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  },
};

const uiReducer = handleActions<Ui, ActionTypes, ActionsUnionType>(
  {
    [ActionTypes.SET_MEDIA_BREAKPOINTS]: (state, action) => ({
      ...state,
      mediaQueryBreakpoints: {
        ...state.mediaQueryBreakpoints,
        ...action.payload,
      },
    }),
    [ActionTypes.SET_DEVICE]: (state, action) => {
      const {
        payload: { mobile, tablet, isIos, iosVersion },
      } = action;
      const isMobile = !!mobile;
      const isTablet = !!tablet;
      const isDesktop = !isMobile && !isTablet;

      return {
        ...state,
        isMobile,
        isTablet,
        isIos,
        iosVersion,
        mediaQueryBreakpoints: {
          ...state.mediaQueryBreakpoints,
          xs: isMobile || isTablet || isDesktop,
          sm: isTablet || isDesktop,
          md: isDesktop,
          lg: isDesktop,
          xl: isDesktop,
        },
      };
    },
  },
  initialState,
);

export { uiReducer };
