// Use consistency tokens included on the api v2 header in order to reduce issues
// regarding replication lag that happen when replicating data between the write and read db instances
// https://housinganywhere.atlassian.net/wiki/spaces/PT/pages/3727753356/Technical+spec+Consistency+Tokens

import { parse } from 'cookie';
import Cookies from 'cookies';
import { CookiesStatic } from 'js-cookie';

import { Cookies as CookiesConstants } from 'ha/constants/Cookies';
import { HttpHeaders } from 'ha/constants/HttpHeaders';

interface LocalToken {
  tokens: string[];
  expires: Date | undefined;
}

export class ConsistencyToken {
  private cnstTokensLocal: LocalToken = {
    tokens: [],
    expires: undefined,
  };

  private setLocalToken = (tokens: string[], expires: Date | undefined) => {
    this.cnstTokensLocal = {
      tokens,
      expires,
    };
  };

  private validateAndClearLocalTokens = () => {
    if (
      this.cnstTokensLocal.expires &&
      this.cnstTokensLocal?.expires < new Date()
    ) {
      this.setLocalToken([], undefined);
    }
  };

  // Retrieve the saved consistency tokens from the cookies
  static getConsistencyTokensForHeaderServer = (cookies: Cookies) => {
    const consistencyTokens = cookies.get(CookiesConstants.CONSISTENCY_TOKEN);

    return consistencyTokens
      ? { [HttpHeaders.ConsistencyToken]: consistencyTokens }
      : null;
  };

  // Retrieve the saved consistency tokens stored locally or from cookies
  getConsistencyTokensForHeaderClient = () => {
    this.validateAndClearLocalTokens();

    if (this.cnstTokensLocal?.tokens?.length) {
      return {
        [HttpHeaders.ConsistencyToken]: this.cnstTokensLocal?.tokens.toString(),
      };
    }

    const cookie = document?.cookie;
    if (cookie) {
      const token = parse(cookie)[CookiesConstants.CONSISTENCY_TOKEN];
      if (token) {
        const expiresAt = new Date();
        // sets the expiration 5 minutes from now;
        expiresAt.setSeconds(expiresAt.getSeconds() + Number(300));
        this.setLocalToken(token.split(','), expiresAt);

        return {
          [HttpHeaders.ConsistencyToken]: token,
        };
      }
    }

    return null;
  };

  // Retrieve the new consistency token from the response header and saves it to cookies
  updateConsistencyToken = (
    cookies: CookiesStatic | Cookies,
    response: Response,
  ) => {
    const consistencyTokenWithMaxAge = response?.headers?.get(
      HttpHeaders.ConsistencyToken,
    );

    if (!consistencyTokenWithMaxAge) {
      return;
    }

    const newToken = consistencyTokenWithMaxAge?.split(',')[0];
    const tokenArray = consistencyTokenWithMaxAge?.split('max-age=');
    const maxAgeInSeconds = tokenArray?.pop();

    if (!newToken || !maxAgeInSeconds) {
      return;
    }

    let savedTokens =
      cookies.get(CookiesConstants.CONSISTENCY_TOKEN)?.split(',') || [];

    // remove token if already exist and add it on the tail of the array
    savedTokens = savedTokens.filter(item => item !== newToken);
    savedTokens?.push(newToken);

    // limit the tokens to 5 so to avoid cookie of being too big
    savedTokens = savedTokens.slice(-5);

    const expiresAt = new Date();
    expiresAt.setSeconds(expiresAt.getSeconds() + Number(maxAgeInSeconds));

    this.setLocalToken(savedTokens, expiresAt);

    cookies.set(CookiesConstants.CONSISTENCY_TOKEN, savedTokens.toString(), {
      expires: expiresAt,
    });
  };
}
