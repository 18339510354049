import { MakeCall } from '@ha/api/v2/createMakeCall';

import { PaymentRequestsOverview } from './types';

const getPaymentRequestsOverview =
  (makeCall: MakeCall) => (tenancyUUIDs: string[]) =>
    makeCall<{ data: PaymentRequestsOverview[] }>(
      'GET',
      '/api/v1/payments/overview',
      {
        query: {
          tenancy: tenancyUUIDs.join(','),
        },
      },
    );
export { getPaymentRequestsOverview };
