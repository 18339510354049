import { MakeCall } from './createMakeCall';

type SitemapResponse = Array<{
  language: string;
  title: string;
  url: string;
}>;

const getSitemaps = (makeCall: MakeCall) => () =>
  makeCall<SitemapResponse>('GET', '/sitemaps/sitemap-c1.json');
export { getSitemaps };
