import { MakeCall } from './createMakeCall';

import { Subscription } from './types/Subscriptions';

const reactivateSubscription =
  (makeCall: MakeCall) => (subId: string | number) =>
    makeCall<Subscription>(
      'POST',
      `/api/v2/billing/subscription/${subId}/reactivate`,
    );
export { reactivateSubscription };
