import { GenderValue } from '../../../types/SearchFilters';
import { FilterBuilder } from '../Types';

const genderBuilder: FilterBuilder = ({ gender = [] }) => {
  if (
    gender.length === 0 ||
    (gender.includes(GenderValue.BOYS) && gender.includes(GenderValue.GIRLS))
  ) {
    return '';
  }

  return {
    [GenderValue.BOYS]: `NOT preferredGender:${GenderValue.GIRLS}`,
    [GenderValue.GIRLS]: `NOT preferredGender:${GenderValue.BOYS}`,
  }[gender[0]];
};

export { genderBuilder };
