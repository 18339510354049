import type { MakeCall } from '../createMakeCall';
import {
  AdvertiserSurveysResponse,
  GetAdvertiserSurveysQuery,
} from '../types/surveyReviews/AdvertiserSurveys';

const getReviewAdvertiserSurveys =
  (makeCall: MakeCall) => (query: GetAdvertiserSurveysQuery) =>
    makeCall<AdvertiserSurveysResponse>(
      'GET',
      '/api/v2/review/landlord/surveys',
      {
        query,
      },
    );

export { getReviewAdvertiserSurveys };
