import { RoomsValue, TypeValue } from '../../../types/SearchFilters';
import { MaxBedroomCount } from '../constants';
import { FilterBuilder } from '../Types';

const range = (start: number, end: number) =>
  Array.from({ length: end - start }, (_, k) => k + start);

const roomsBuilder: FilterBuilder = (params, context) => {
  const bedroomCount = params.rooms?.bedroomCount || [];
  const hasStudio =
    params.categories?.includes(TypeValue.STUDIO) ||
    (context?.expanded && params.categories?.includes(TypeValue.PRIVATE_ROOM));

  // for building the facet query for the propertyType, we want
  // to exclude the room filter.
  if (bedroomCount.length === 0 || context?.withoutPropertyType) {
    return '';
  }

  const bedroomCountSet = new Set(bedroomCount);
  if (context?.expanded) {
    if (bedroomCountSet.has(RoomsValue.TWO)) {
      bedroomCountSet.add(RoomsValue.THREE);
    }
    if (bedroomCountSet.has(RoomsValue.ONE)) {
      bedroomCountSet.add(RoomsValue.TWO);
    }
  }

  let query = Array.from(bedroomCountSet)
    .sort()
    .map((val: string) => {
      if (val === RoomsValue.FOUR_AND_MORE) {
        return range(4, MaxBedroomCount + 1)
          .map(
            count =>
              `facility_bedroom_count:${count}${
                count === MaxBedroomCount ? '+' : ''
              }`,
          )
          .join(' OR ');
      }

      return `facility_bedroom_count:${val}`;
    })
    .join(' OR ');

  if (hasStudio) {
    query = `${query} OR facility_bedroom_count:0`;
  }

  return query;
};

export { roomsBuilder };
