import { TransactionEvent } from '@sentry/types';

export function filterTransactions(matchers: RegExp[]) {
  return (transactionEvent: TransactionEvent) => {
    if (
      matchers.some(matcher => matcher.test(transactionEvent.transaction ?? ''))
    ) {
      return transactionEvent;
    }

    return null;
  };
}

export function groupTransactions(replacers: [RegExp, string][]) {
  return (transactionEvent: TransactionEvent) => {
    if (!transactionEvent?.transaction) return transactionEvent;

    const replacer = replacers.find(([trace]) =>
      trace.test(transactionEvent.transaction ?? ''),
    );

    if (!replacer) return transactionEvent;

    const [regexp, replacement] = replacer;

    return {
      ...transactionEvent,
      transaction: transactionEvent.transaction.replace(regexp, replacement),
    };
  };
}

export function beforeSend(
  operations: ((transaction: TransactionEvent) => TransactionEvent | null)[],
) {
  return (transactionEvent: TransactionEvent) => {
    return operations.reduce((acc, fn) => fn(acc), transactionEvent);
  };
}
