import { createRequiredContext } from 'ha/core/RequiredContext';

import { AppServices } from './getAppServices';

const ServicesContext = createRequiredContext<AppServices>('ServicesContext');

export const ServicesProvider = ServicesContext.Provider;
export const ServicesConsumer = ServicesContext.Consumer;
export const useServices = ServicesContext.useContext;

export { ServicesContext };
