import { ThunkMiddleware } from 'redux-thunk';

import scroller from 'react-scroll/modules/mixins/scroller';

const scrollOptions = { offset: -200, smooth: true };

export const scrollMiddleware: ThunkMiddleware = () => {
  return next => action => {
    next(action);

    const { meta } = action;

    if (!meta || !meta.scrollTo) return;

    if (meta.scrollTo) {
      scroller.scrollTo(`position-${meta.scrollTo}`, scrollOptions);
    }
  };
};
