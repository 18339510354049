import {
  Actions,
  NotificationType,
} from 'ha/modules/RealtimeMessaging/constants';
import { reportError } from 'ha/helpers/bugReporter/reportError';
import { ThunkMiddleware } from 'redux-thunk';
import { RootState } from '../types';

const realtimeMiddleware: ThunkMiddleware<RootState> =
  ({ dispatch, getState }) =>
  next =>
  action => {
    next(action);

    if (action.type !== Actions.RECEIVE_MESSAGE) return;

    Promise.all([
      import(/* webpackChunkName: "ha-pages-Talk-index-js" */ '../actions'),
      import(/* webpackChunkName: "ha-pages-Talk-index-js" */ '../selectors'),
    ])
      .then(([actions, selectors]) => {
        const state = getState();
        const isInitialState = selectors.getInitial(state);
        const conversationId = selectors.getConversationId(state);

        if (
          isInitialState === undefined ||
          conversationId !== action.payload.message.conversationId
        ) {
          return;
        }

        if (action.payload.type === NotificationType.conversationMessage) {
          dispatch(
            // @ts-ignore
            actions.loadUpdatedConversation(
              action.payload.message.conversationId,
            ),
          );
        }

        if (action.payload.type === NotificationType.conversationRead) {
          dispatch(actions.readMessages(action.payload.message));
        }
      })
      .catch(reportError);
  };

export { realtimeMiddleware };
