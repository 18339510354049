import { GoogleConsentCommand, GoogleConsentTypes } from '@hbf/cookie-consent';

export const initializeGoogleConsent = () => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  if (!window.gtag) {
    window.gtag = function gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    };
  }

  [
    {
      command: GoogleConsentCommand.default,
      consent: GoogleConsentTypes.denied,
    },
    {
      command: GoogleConsentCommand.update,
      consent: GoogleConsentTypes.granted,
    },
  ].forEach(({ command, consent }) => {
    window.gtag('consent', command, {
      analytics_storage: consent,
      ad_storage: consent,
      functionality_storage: consent,
      personalization_storage: consent,
      security_storage: consent,
      ad_user_data: consent,
      ad_personalization: consent,
    });
  });
};
