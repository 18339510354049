import {
  ApiObject,
  registerSuperProperties,
  registerSuperPropertiesOnce,
} from '@hbf/analytics';
import { isOneTrustInitialised } from '@hbf/cookie-consent';

import {
  getIsMobile,
  getIsTablet,
  getCurrentScreenSize,
} from 'ha/modules/UI/selectors';
import { Thunk } from 'ha/myredux/types';
import { CookieConsent } from 'ha/services/analytics/types';

import { getCampaignParams } from '../helpers/getCampaignParams';
import { getUtmSource } from '../helpers/getUtmSource';

import { identifyUser } from './identifyUser';

export interface SetupAnalyticsArgs {
  url: URL;
  referrer: string;
}

export const setupAnalytics =
  ({ url, referrer }: SetupAnalyticsArgs): Thunk =>
  (dispatch, getState, { analytics }) => {
    analytics.onReady((cookieConsent: CookieConsent) => {
      const { isCookieConsentEnabled } = cookieConsent;

      const source = getUtmSource(url, referrer);
      const campaignParams = getCampaignParams(url.searchParams);
      const state = getState();
      const isMobile = getIsMobile(state);
      const isTablet = getIsTablet(state);
      const screenSize = getCurrentScreenSize(state);
      const userTraits: ApiObject = {
        isCookieConsentEnabled,
        ...campaignParams.params,
        ...campaignParams.firstParams,
        Source: source,
      };

      registerSuperProperties({
        ...campaignParams.params,
        CurrentSource: source,
        isMobile,
        isTablet,
        isDesktop: !isMobile && !isTablet,
        screenSize,
        content_type: 'home_listing',
        isOneTrustInitialised: isOneTrustInitialised(),
      });
      registerSuperPropertiesOnce({ Source: source });

      dispatch(identifyUser(cookieConsent, userTraits));
    });
  };
