import { BlogSlugs } from '../../constants';

export const getTenantsGlobalBlogPath = () =>
  `/${BlogSlugs.Homepage}/${BlogSlugs.Tenants}`;

export const getTenantsCountryBlogPath = (countryPath: string) => {
  let path = countryPath;
  if (!path.startsWith('/')) {
    path = `/${path}`;
  }
  return getTenantsGlobalBlogPath() + path;
};
