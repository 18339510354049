import { MakeCall } from './createMakeCall';
import { TaxPayerPayload, TaxPayerResponse } from './types/TaxPayerDetails';

/**
 * Handles creating tax payer details  requests (compliance)
 * @returns updated tax payer details response
 */

export const updateTaxPayer =
  (makeCall: MakeCall) =>
  (taxPayerInfoUuid: string, payload: Partial<TaxPayerPayload>) =>
    makeCall<TaxPayerResponse>(
      'PUT',
      `/api/v2/legal/taxpayer/${taxPayerInfoUuid}`,
      {
        data: payload,
      },
    );
