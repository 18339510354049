import type { MakeCall } from '../createMakeCall';
import {
  PayoutMethodLinkPayload,
  PriorityRules,
} from '../types/PayoutMethodsV2';

const linkUnitTypeToDefaultPayoutMethod =
  (makeCall: MakeCall) => (data: PayoutMethodLinkPayload) =>
    makeCall<PriorityRules>(
      'DELETE',
      `/api/v2/payout-method/${data.payoutMethodGUID}/priority`,
      {
        data: { rules: [data.unitTypeId] },
      },
    );

export { linkUnitTypeToDefaultPayoutMethod };
