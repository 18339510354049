import { MakeCall } from '../createMakeCall';

import { UnitTypeVideo } from '../types/inventory/UnitType';

const confirmUnitTypeVideoUpload =
  (makeCall: MakeCall) => (unitTypeId: number, videoId: number) =>
    makeCall<UnitTypeVideo[]>(
      'PUT',
      `/api/v2/unit-type/${unitTypeId}/video/${videoId}/confirm`,
    );
export { confirmUnitTypeVideoUpload };
