import { PayoutMethodV2 } from '../types/PayoutMethodsV2';
import type { MakeCall } from '../createMakeCall';

export interface APIPayoutMethods {
  payoutMethods: PayoutMethodV2[];
  count: number;
}

const getPayoutMethodsV2 = (makeCall: MakeCall) => () =>
  makeCall<APIPayoutMethods>('GET', `/api/v2/payout-method`);

export { getPayoutMethodsV2 };
