import { MakeCall } from './createMakeCall';

import { KycDocumentsResponse } from './types/Kyc';

const deleteKycDocument =
  (makeCall: MakeCall) =>
  (userId: 'me' | number, kycUuid: string, data: { documentUuid: string }) =>
    makeCall<KycDocumentsResponse>(
      'DELETE',
      `/api/v2/user/${userId}/kyc/${kycUuid}/documents`,
      { data },
    );
export { deleteKycDocument };
