import { AmenitiesValue, HeatingOptions } from '../../../types/SearchFilters';

import { FilterBuilder } from '../Types';

const amenitiesBuilder: FilterBuilder = ({ amenities }) => {
  if (!amenities || (amenities && amenities.length === 0)) {
    return '';
  }

  const query: string[] = [];

  if (amenities.includes(AmenitiesValue.TV)) {
    query.push('facility_tv:yes');
  }

  if (amenities.includes(AmenitiesValue.DESK)) {
    query.push('facility_desk:yes');
  }

  if (amenities.includes(AmenitiesValue.DISHWASHER)) {
    query.push('facility_dishwasher:yes');
  }

  if (amenities.includes(AmenitiesValue.WASHING_MACHINE)) {
    query.push('facility_washing_machine:yes');
  }

  if (amenities.includes(AmenitiesValue.DRYER)) {
    query.push('facility_dryer:yes');
  }

  if (amenities.includes(AmenitiesValue.AIR_CONDITIONING)) {
    query.push('facility_ac:yes');
  }

  if (amenities.includes(AmenitiesValue.HEATING)) {
    query.push(
      `(${[
        HeatingOptions.CENTRAL,
        HeatingOptions.ELECTRICAL,
        HeatingOptions.GAS,
        HeatingOptions.WOOD_STOVE,
      ]
        .map(i => `facility_heating:${i}`)
        .join(' OR ')})`,
    );
  }

  return query.join(' AND ');
};

export { amenitiesBuilder };
