import { MakeCall } from './createMakeCall';

const initiateIDVerification =
  (makeCall: MakeCall) => (conversationId: number) =>
    makeCall<undefined>(
      'PUT',
      `/api/v2/conversation/${conversationId}/initiate-id-verification`,
    );

export { initiateIDVerification };
