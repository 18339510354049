// https://developers.google.com/maps/documentation/maps-static/digital-signature#sample-code-for-url-signing
import { createHmac } from 'crypto';

import * as url from 'url';

// Convert from 'web safe' base64 to true base64.
const removeWebSafe = (safeEncodedString: string) =>
  safeEncodedString.replace(/-/g, '+').replace(/_/g, '/');

// Convert from true base64 to 'web safe' base64
const makeWebSafe = (encodedString: string) =>
  encodedString.replace(/\+/g, '-').replace(/\//g, '_');

// Takes a base64 code and decodes it.
const decodeBase64Hash = (code: string) => Buffer.from(code, 'base64');

// Takes a key and signs the data with it.
const encodeBase64Hash = (key: Buffer, data: string) => {
  return createHmac('sha1', key).update(data).digest('base64');
};

// Sign a URL using a secret key.
export const addUrlSignature = (path: string, secret: string) => {
  const uri = url.parse(path);
  const safeSecret = decodeBase64Hash(removeWebSafe(secret));
  const hashedSignature = makeWebSafe(
    encodeBase64Hash(safeSecret, uri.path || ''),
  );

  return `${url.format(uri)}&signature=${hashedSignature}`;
};
