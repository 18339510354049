import type { MakeCall } from '../createMakeCall';
import { BillingInformation } from '../types/BillingInformation';

export interface APICreateMultiBillingData {
  billingInfo: BillingInformation;
}

const setBillingAddressAsDefault =
  (makeCall: MakeCall) => (billingInfoUUID: string) =>
    makeCall<APICreateMultiBillingData>(
      'PUT',
      `/api/v2/billing/billing-info/${billingInfoUUID}/priority/set-default`,
    );

export { setBillingAddressAsDefault };
