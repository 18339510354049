import React from 'react';

import { useFavoriteListingActions } from '../hooks/useFavoriteListingActions';

interface Children {
  onClick: React.MouseEventHandler;
  isLoading: boolean;
  isFavorite: boolean;
}

interface Props {
  listingId: number;
  children: (p: Children) => JSX.Element;
}

export const FavoriteListingContainer: React.FC<Props> = ({
  children,
  listingId,
}) => {
  const { isFavorite, isLoading, onClick } =
    useFavoriteListingActions(listingId);

  return children({
    onClick,
    isFavorite,
    isLoading,
  });
};
