import { MakeCall } from './createMakeCall';
import { City } from './types';

const getCities =
  (makeCall: MakeCall) =>
  (data: { list: Array<{ query: string }>; languages: string[] }) =>
    makeCall<{ [key: string]: City[] }>(
      'POST',
      '/api/v2/geonames/search-city-multiple',
      { data },
    );
export { getCities };
