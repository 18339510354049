import { MakeCall } from './createMakeCall';

import { Subscription, SubscriptionState } from './types/Subscriptions';

export interface Query {
  userId: number;
  ids?: number[];
  uuids?: string[];
  states?: SubscriptionState[];
  orderBy?: 'id' | 'id-desc' | 'created-desc';
  limit?: number;
  offset?: number;
}

export interface Subscriptions {
  count: number;
  subscriptions: Subscription[];
}

const getSubscriptions = (makeCall: MakeCall) => (query: Query) =>
  makeCall<Subscriptions>('GET', `/api/v2/billing/subscriptions`, {
    query,
  });

export { getSubscriptions };
