import { Conversation } from './types/Conversation';
import { MakeCall } from './createMakeCall';

export interface Query {
  /** possible values -- "stateHistory" "messages" "unitType" "listing" "advertiser" "tenant" "tenancy" "rentalConditions" */
  expand: string;
  translateToLanguage?: string;
}

const getConversation =
  (makeCall: MakeCall) => (conversationId: number | string, query?: Query) =>
    makeCall<Conversation>('GET', `/api/v2/conversation/${conversationId}`, {
      query,
    });

export { getConversation };
