import React from 'react';

import { useSelector } from 'react-redux';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { useIntl } from 'ha/i18n';
import { useServices } from 'ha/services';

import { clearUserData } from '../clearUserData';
import { getImpersonatingAdmin } from '../selectors';

interface ImpersonatingAdmin {
  id: number;
  name: string;
}

interface ChildrenProps {
  impersonatingAdmin: ImpersonatingAdmin | null;
  onStop: () => void;
}

interface Props {
  children: React.FunctionComponent<ChildrenProps>;
}

export const useImpersonationInformation = () => {
  const { urlResolver } = useIntl();
  const { apiV2 } = useServices();

  const impersonatingAdmin = useSelector(getImpersonatingAdmin);

  const [impersonatingAdminState, setImpersonatingAdminState] =
    React.useState<ImpersonatingAdmin | null>(null);

  const stopImpersonation = React.useCallback(async () => {
    await apiV2.impersonateUser(0).catch(error => {
      reportError(new Error('Failed impersonation stop', { cause: error }));
    });

    clearUserData();

    window.location.assign(urlResolver.getAdminPagesUrl());
  }, [apiV2, urlResolver]);

  React.useEffect(() => {
    setImpersonatingAdminState(impersonatingAdmin);
  }, [impersonatingAdmin]);

  return {
    impersonatingAdmin: impersonatingAdminState,
    onStop: stopImpersonation,
  };
};

export function Impersonation({ children }: Props) {
  const impersonationInformation = useImpersonationInformation();

  return children(impersonationInformation);
}
