export const STATE_NAME = 'ui';

export enum ScreenSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export const BreakPoints = {
  [ScreenSize.XS]: 0,
  [ScreenSize.SM]: 37.5, // 600px
  [ScreenSize.MD]: 60, // 960px
  [ScreenSize.LG]: 80, // 1280px
  [ScreenSize.XL]: 120, // 1920px
};
