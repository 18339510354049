import { MakeCall } from './createMakeCall';

export type RemoveSavedSearchCall = (
  userId: number,
  searchId: number,
) => Promise<{ data: unknown }>;

const removeSavedSearch =
  (makeCall: MakeCall) => (userId: number, searchId: number) =>
    makeCall<undefined>('DELETE', `/api/v2/user/${userId}/search/${searchId}`);
export { removeSavedSearch };
