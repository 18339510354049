import { preprocessResponse } from 'ha/utils/preprocessResponse';
import { FetchClient } from 'ha/helpers/FetchClient';

export enum MetisKind {
  PRIVATE_ROOM = 'private_room',
  ENTIRE_PLACE = 'entire_place',
  STUDIO = 'studio',
}
export interface MetisParams {
  totalSize: string;
  bedroomCount: string;
  kind: MetisKind;
  lat: number;
  lng: number;
}

interface MetisSuccesResponse {
  data?: {
    job_id: string;
    result?: {
      city: string;
      lower_prediction: string;
      prediction: string;
      upper_prediction: string;
    };
    status: 'SUCCESS';
  };
  meta: {
    status: number;
  };
}

export function makeMetisService(fetchClient: FetchClient) {
  return {
    predict: ({
      totalSize,
      bedroomCount,
      kind,
      lat,
      lng,
    }: MetisParams): Promise<MetisSuccesResponse> => {
      return fetchClient
        .fetch('/api/v1/predict', {
          method: 'POST',
          body: JSON.stringify({
            imputation: 'yes',
            lat,
            lng,
            total_size: totalSize,
            bedroom_count: bedroomCount,
            kind,
          }),
        })
        .then(preprocessResponse);
    },
  };
}
