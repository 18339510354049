import { MakeCall } from './createMakeCall';
import { Poi, PoiType } from './types/Poi';

export interface GetPoisQuery {
  type: PoiType;
  // One of countryCode/query/latlng/geonameId is required
  countryCode?: string;
  query?: string;
  latitude?: number;
  longitude?: number;
  radius?: number;
  geonameId?: number;
  singleGeoname?: boolean;
  allInCountry?: boolean;
  limit?: number;
  offset?: number;
}

export interface PoisResponse {
  pois: Poi[];
  count: number;
}

export type GetPoisCall = (
  data: GetPoisQuery,
) => Promise<{ data: PoisResponse }>;

const getPois = (makeCall: MakeCall) => (query: GetPoisQuery) =>
  makeCall<PoisResponse>('GET', `/api/v2/pois`, { query });
export { getPois };
