import { MakeCall } from '../createMakeCall';

const deleteUnitTypeVideo =
  (makeCall: MakeCall) => (unitTypeId: number, videoId: number) =>
    makeCall<undefined>(
      'DELETE',
      `/api/v2/unit-type/${unitTypeId}/video/${videoId}`,
    );

export { deleteUnitTypeVideo };
