import { MakeCall } from './createMakeCall';

export interface Payload {
  reason?: string;
  description?: string;
  reasons?: string[];
}

const reportUser = (makeCall: MakeCall) => (userId: number, data: Payload) =>
  makeCall<undefined>('POST', `/api/v2/user/${userId}/report`, { data });
export { reportUser };
