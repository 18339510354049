import { scriptjsWrapper as getScriptJs } from 'ha/helpers/scriptjsWrapper';

const INSTAGRAM_EMBEDS_SRC = 'https://platform.instagram.com/en_US/embeds.js';
const INSTAGRAM_EMBEDS_BASE_URL =
  'https://graph.facebook.com/v9.0/instagram_oembed';

interface FacebookSdkResponse {
  status: string;
  error: Error;
  data: Record<string, unknown>;
}

interface FacebookSdk {
  getLoginStatus: (callback: (response: FacebookSdkResponse) => void) => void;
  login: (callback: (response: FacebookSdkResponse) => void) => void;
  api: (
    path: string,
    params: { type: string },
    callback: (response: FacebookSdkResponse) => void,
  ) => void;
}

interface FacebookConfig {
  clientId: string;
  clientToken: string;
}
interface FacebookService {
  init: () => Promise<unknown>;
  login: () => Promise<unknown>;
  getUserAvatar: (type: string) => Promise<unknown>;
  getEmbedContend: (contentUrl: string) => Promise<string>;
}

const parseError = ({ error }: { error: Error }) => {
  if (error) {
    return new Error(error.message);
  }

  return null;
};

let initialized = false;

export function createFBService({
  clientId,
  clientToken,
}: FacebookConfig): FacebookService {
  const init = () =>
    new Promise<FacebookSdk>((resolve, reject) => {
      if (!process.browser) {
        reject(new Error('calling client side code on server'));

        return;
      }
      if (initialized) {
        // @ts-ignore
        resolve(global.FB);

        return;
      }

      global.fbAsyncInit = () => {
        global.FB?.init({
          appId: clientId,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v2.10',
        });
        global.FB?.AppEvents.logPageView();
        initialized = true;
        // @ts-ignore
        resolve(global.FB);
      };

      getScriptJs('https://connect.facebook.net/en_US/sdk.js');
    });

  const login = () =>
    init().then(
      (sdk: FacebookSdk) =>
        new Promise<FacebookSdk>((resolve, reject) => {
          sdk.getLoginStatus(response => {
            if (response.status === 'connected') {
              resolve(sdk);
            } else {
              sdk.login(loginResponse => {
                const error = parseError(loginResponse);

                if (error) return reject(error);

                if (loginResponse.status !== 'connected') {
                  return reject(new Error(`Can't connect to Facebook`));
                }

                return resolve(sdk);
              });
            }
          });
        }),
    );

  const getUserAvatar = (type = 'large') =>
    login().then(
      (sdk: FacebookSdk) =>
        new Promise((resolve, reject) => {
          sdk.api('me/picture', { type }, response => {
            const error = parseError(response);

            if (error) return reject(error);

            return resolve(response.data);
          });
        }),
    );

  const getEmbedContend = async (contentUrl: string) => {
    const url = `${INSTAGRAM_EMBEDS_BASE_URL}?url=${contentUrl}&fields=html&access_token=${clientId}|${clientToken}&omitscript=true`;

    return Promise.all([
      fetch(url).then(res => res.json()),
      getScriptJs(INSTAGRAM_EMBEDS_SRC),
    ]).then(([{ html }]) => html);
  };

  return {
    init,
    login,
    getUserAvatar,
    getEmbedContend,
  };
}
