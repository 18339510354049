import { createAction } from '@housinganywhere/safe-redux';

import { Actions } from '../constants';
import { RealtimeMessage } from '../types';

interface MessagePayload {
  type: string;
  message: RealtimeMessage;
}

export interface MessageAttributes {
  type: string;
}

interface MessageEvent {
  attributes: MessageAttributes;
  body: RealtimeMessage;
}

const receiveMessageAction = (payload: MessagePayload) =>
  createAction(Actions.RECEIVE_MESSAGE, payload);

export function receiveMessage(message: MessageEvent) {
  return receiveMessageAction({
    message: message.body,
    type: message.attributes.type,
  });
}
