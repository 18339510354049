import dayjs from 'dayjs';

import { getDateOfMonth } from './getDateOfMonth';
import { getMonth } from './getMonth';

const getAbsoluteDaysDiff = (start: string, end: string): number => {
  const startDay = getDateOfMonth(start);
  const endDay = getDateOfMonth(end);
  const startMonth = getMonth(start) + 1;
  const endMonth = getMonth(end) + 1; // the month of the day after the end date

  if (startMonth === endMonth || startDay <= endDay) {
    return endDay - startDay;
  }
  let daysDiff = 0;
  if (startDay > 1) {
    // if the start date is not the first day of that month
    const startMonthDaysCount = dayjs(start).daysInMonth();
    daysDiff += startMonthDaysCount - startDay;
  }
  if (endDay > 1) {
    // if the day after the end date is not the first day of the following month (the end date is not the last day of the month)
    daysDiff += endDay;
  } else {
    // the end date is the last day of the month
    daysDiff += 1; // to include the start date itself
  }
  return daysDiff;
};

export { getAbsoluteDaysDiff };
