import { MakeCall } from './createMakeCall';

export interface Payload {
  password: string;
  password2: string;
  token: string;
}

const resetPassword = (makeCall: MakeCall) => (data: Payload) =>
  makeCall<undefined>('POST', `/api/v2/action/reset-password`, { data });

export { resetPassword };
