import dayjs from 'dayjs';

import { diff } from './diff';

const diffDay = (firstDate: Date | string, secondDate: Date | string): number =>
  diff(
    'day',
    dayjs(firstDate).startOf('day').toISOString(),
    dayjs(secondDate).startOf('day').toISOString(),
  );

export { diffDay };
