import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';

import { Language } from '@ha/intl';

export enum ActionTypes {
  SET = 'LANGUAGE_SWITCHER/SET_LOCASISED_LOCATIONS',
}

export const Actions = {
  set: (
    localisedLocations: Array<{ code: Language; localisedLocation: string }>,
  ) => createAction(ActionTypes.SET, localisedLocations),
};

export type ActionsType = ActionsUnion<typeof Actions>;
