import { getFromIndexedDB, setInIndexedDB } from 'ha/utils/indexedDb';
import { TouchPointsList } from '@ha/api/v2/types/Affiliates';
import { STORAGE_KEY } from '../constants';

export const touchPoints = {
  get: async (): Promise<Partial<TouchPointsList>> =>
    (await getFromIndexedDB(STORAGE_KEY)) || {},

  set: (touchPointsList: Partial<TouchPointsList>) =>
    setInIndexedDB(STORAGE_KEY, touchPointsList),

  /**
   * Clear the list after sending it to the backend to prevent duplication in the database
   * and avoid increasing the size of data in the user's browser.
   */
  clear: () => touchPoints.set({}),
};
