import loadable from '@loadable/component';

import { MyReduxStore } from 'ha/myredux/types';
import { InjectAsyncReducer } from 'ha/types/routing';

export const LazyPageComponent = loadable<
  {
    pageName: string;
    store: MyReduxStore;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { injectAsyncReducer?: InjectAsyncReducer; default: any }
>(props => import(`ha/pages/${props.pageName}/index.ts`), {
  resolveComponent: (module, props) => {
    if (module.injectAsyncReducer) {
      module.injectAsyncReducer(props.store);
    }

    return module.default;
  },
});
