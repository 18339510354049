import { Language } from '../../../lib/types';

export const ContentfulSupportedLanguages: Language[] = [
  Language.en,
  Language.es,
  Language.de,
  Language.fr,
  Language.it,
  Language.nl,
];
export const AnswersSupportedLangs: Language[] = [
  Language.en,
  Language.fr,
  Language.de,
  Language.es,
  Language.it,
  Language.nl,
  Language.pl,
  Language.pt,
  Language.ru,
  Language.ro,
  Language.sv,
];

export const AllSupportedLanguages = Object.values(Language);
