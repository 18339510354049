import { MakeCall } from './createMakeCall';

export enum CodeRequestTypes {
  sms = 'sms',
  voice = 'voice',
}

export interface CodeRequest {
  type: CodeRequestTypes;
  recaptchaToken: string;
}

const requestCode =
  (makeCall: MakeCall) =>
  (userId: number, { type, recaptchaToken }: CodeRequest) =>
    makeCall<undefined>('POST', `/api/v2/user/${userId}/request-code`, {
      data: {
        type,
        recaptchaToken,
      },
    });
export { requestCode };
