import { MakeCall } from './createMakeCall';
import { QuickReply } from './types/QuickReplies';

export interface Payload {
  templateUuid: string;
  name: string;
  body: string;
  quickReplyChannel?: 'talkPageModal' | 'settingsPage' | string;
}

export type UserIdType = number | 'me';

const updateQuickReply =
  (makeCall: MakeCall) =>
  (userId: UserIdType = 'me', data: Payload) =>
    makeCall<QuickReply>(
      'PUT',
      `/api/v2/conversations/user/${userId}/quick-reply-template`,
      {
        data,
      },
    );

export { updateQuickReply };
