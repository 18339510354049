import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';

import { Thunk } from 'ha/myredux/types';
import { getFromStorage, removeFromStorage } from 'ha/utils/sessionStorage';

import { STORAGE_KEY } from '../constants';
import { isPendingListingSynced } from '../selectors';

import { addFavoriteListing } from './addFavoriteListing';

export enum ActionTypes {
  START = '[favorites] SYNC_PENDING_FAVORITE_LISTING_START',
}

export const Actions = {
  start: () => createAction(ActionTypes.START),
};
export type ActionsUnionType = ActionsUnion<typeof Actions>;

export const syncPendingFavoriteListing =
  (): Thunk => async (dispatch, getState) => {
    const isSynced = isPendingListingSynced(getState());

    if (isSynced) {
      return;
    }

    dispatch(Actions.start());

    const listingId = getFromStorage<number>(STORAGE_KEY);

    if (!listingId) {
      return;
    }

    removeFromStorage(STORAGE_KEY);

    await dispatch(addFavoriteListing(listingId));
  };
