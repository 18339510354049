import { UserSearch } from '@ha/api/v2/types/UserSearch';
import { UserSearchList } from '@ha/api/v2/types/UserSearchList';

export const STATE_NAME = 'SAVED_SEARCHES';

export enum ActionsTypes {
  LOAD_START = 'SAVED_SEARCHES/LOAD_START',
  LOAD_SUCCESS = 'SAVED_SEARCHES/LOAD_SUCCESS',
  LOAD_FAIL = 'SAVED_SEARCHES/LOAD_FAIL',

  CREATE_START = 'SAVED_SEARCHES/CREATE_START',
  CREATE_SUCCESS = 'SAVED_SEARCHES/CREATE_SUCCESS',
  CREATE_FAIL = 'SAVED_SEARCHES/CREATE_FAIL',
}

export interface LoadStartAction {
  type: ActionsTypes.LOAD_START;
}
export interface LoadSuccessAction {
  type: ActionsTypes.LOAD_SUCCESS;
  payload: UserSearchList;
}
export interface LoadFailAction {
  type: ActionsTypes.LOAD_FAIL;
  payload: Error;
}

export interface CreateStartAction {
  type: ActionsTypes.CREATE_START;
}

export interface CreateSuccessAction {
  type: ActionsTypes.CREATE_SUCCESS;
  payload: UserSearch;
}

export interface CreateFailAction {
  type: ActionsTypes.CREATE_FAIL;
  payload: Error;
}

export type SavedSearchActions =
  | LoadStartAction
  | LoadSuccessAction
  | LoadFailAction
  | CreateStartAction
  | CreateSuccessAction
  | CreateFailAction;

export const SAVED_SEARCH_EXPIRY = 60; // days
