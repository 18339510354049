import { addMinutes } from './addMinutes';

/**
 * Fix the day gaps between different timezones, adds different minutes the .
 */
const syncDayWithUTC = (date: Date) => {
  return new Date(addMinutes(date, date.getTimezoneOffset()));
};

export { syncDayWithUTC };
