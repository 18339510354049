import { ContractTypeValue } from '../../../types/SearchFilters';
import { FilterBuilder } from '../Types';

const contractTypeBuilder: FilterBuilder = ({ contractType = [] }) => {
  if (
    !contractType.length ||
    contractType.length === Object.keys(ContractTypeValue).length
  ) {
    return '';
  }

  const propertyArray = contractType.map(value => `contractType:${value}`);

  if (propertyArray.length === 1) {
    return propertyArray[0];
  }
  if (propertyArray.length > 1) {
    return `(${propertyArray.join(' OR ')})`;
  }

  return '';
};

export { contractTypeBuilder };
