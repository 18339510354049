import type { MakeCall } from '../createMakeCall';

export interface Query {
  isUnitType: boolean;
}

const deleteQualificationRequirement =
  (makeCall: MakeCall) => (requirementId: string | number, query?: Query) =>
    makeCall('DELETE', `/api/v2/qualification/requirement/${requirementId}`, {
      query,
    });
export { deleteQualificationRequirement };
